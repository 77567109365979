// Customizable Area Start
import React from "react";
import StoreProductListController, { Props, configJSON } from "./StoreProductListController.web";
import { Box, Checkbox, ClickAwayListener, Grid, InputAdornment, Typography, withStyles } from "@material-ui/core";
import { CustomMenuItemBox, CustomizeBrandInput, CustomizeInputSearchBox, SearchIconSVG } from "../CustomizedElementStyle";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { IProductData } from "./StoreMenuTabController.web";
// Customizable Area End

class StoreProductListing extends StoreProductListController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Grid container className={classes.marginOne} spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.marginOne}>
            <CustomizeBrandInput
              data-test-id="quantity"
              label={configJSON.selectQuantityLabel}
              id="outlined-start-adornment"
              value={this.state.quantityValue}
              onChange={this.handleQuantity}
              fullWidth
              inputProps={{
                maxLength: 3,
                minLength: 1
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
            {this.state.quantityErr && (
                <Typography data-test-id="quantityErr" component={"div"} className={classes.errStyle}>
                  {this.state.quantityErr}
                </Typography>
              )}
          </Grid>
          <ClickAwayListener data-test-id="clickAway" onClickAway={this.handleClickAway}>
            <Box className={classes.box}>
              <Box>
                <CustomizeInputSearchBox
                  data-test-id="selectProduct"
                  label={configJSON.selectProductLabel}
                  className={classes.selectBorder}
                  id="outlined-start-adornment"
                  autoComplete="off"
                  value={this.state.productValue}
                  onChange={this.setProductValue}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={webStyle.marginTwo}></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end" style={webStyle.paddingOne}>
                        <SearchIconSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {this.state.productValue ? (
                <Box className={classes.searchWrap}>
                  <Box className={classes.resultText}>{this.state.productValue ? `found ${this.state.productResults.length} results for ${this.state.productValue}` : ""}</Box>
                  <Box className={classes.para}>{configJSON.resultDes}</Box>
                </Box>
              ) : (
                ""
              )}

              {this.state.openProductResult && (
                <>
                <Box
                  className={classes.boxTwo}>
                  {this.state.productResults?.map((item: IProductData, index: number) => {
                    const title = item.attributes.product_title || item.attributes.product_content?.product_attributes?.product_title;
                    return (
                      <CustomMenuItemBox data-test-id="result" className={classes.resultCard} style={{ background: Boolean(this.state.selectProductId.includes(item.id)) ? "#ff7020b0" : "#fff" }} key={`${item}-${index}`} >
                        <Checkbox data-test-id={`check-${index}`} color="default" icon={<RadioButtonUncheckedIcon className={classes.radioBtn} />} checkedIcon={<RadioButtonCheckedIcon className={classes.radioBtn} />} onChange={(e) => this.handleSelectProduct(e, item)} checked={Boolean(this.state.selectProductId.includes(item.id))} />
                        <Box style={webStyle.textWrap}>
                          <Typography className={classes.headingOne}>{title}</Typography>
                          <Typography className={classes.headingTwo}>{this.getRelation(item)}</Typography>
                        </Box>
                      </CustomMenuItemBox>
                    );
                  })}
                </Box>
                {this.state.errorMessage && (
                  <Typography data-test-id="errorMessage" component={"div"} className={classes.errStyle}>
                    {this.state.errorMessage}
                  </Typography>
                )}
                </>
              )}
              
            </Box>
          </ClickAwayListener>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  marginTwo: {
    marginRight: "20px",
  },
  paddingOne: {
    paddingRight: "20px",
  },
  textWrap: {
    marginLeft: "8px",
  }
};

export const styles = {
  marginOne: {
    marginTop: "20px",
  },
  productText: {
    fontSize: "24px",
    fontWeight: 600,
    marginTop: "-30px",
    fontFamily: `"Lato", sans-serif`,
  },
  box: {
    marginTop: "20px",
    width: "70%",
    padding: "16px",
  },
  resultCard: {
    display: "flex",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
    background: "#fff",
    margin: "12px",
    borderRadius: "5px",
    padding: "16px 10px",
  },
  radioBtn: {
    color: "#666666",
    paddingLeft: "0",
    "&.Mui-checked": {
      color: "#666666",
    },
  },
  headingOne: {
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`,
    color: "#33333a",
  },
  headingTwo: {
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    color: "#697A8D",
  },
  selectBorder: {
    "& .MuiInputBase-root": {
      borderColor: "#b1b3b5",
    },
    "& .MuiInputLabel-root": {
        top: -22,
        left: 0,
        color: "#9a9a9a",
        fontWeight: 600,
        fontSize: 16,
        fontFamily: `"Lato", sans-serif`
    }
  },
  errStyle: {
    color: "red",
    fontSize: "11px",
    marginTop: "6px",
  },
  searchWrap: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row" as const,
    marginTop: 20,
  },
  resultText: {
    color: "#666666",
    fontWeight: 1000,
    fontSize: 12,
    fontFamily: `"Lato", sans-serif`,
    marginRight: 5,
  },
  para: {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 500,
    color: "#666666",
    fontSize: 12,
  },
  boxTwo: {
    position: "relative" as const,
    marginTop: "12px",
    maxHeight: "400px",
    overflowY: "auto" as const,
    scrollbarWidth: "thin" as const,
    borderRadius: "5px",
    marginLeft: "-12px",
    marginBottom: '30px'
  }
};

export { StoreProductListing };
export default withStyles(styles)(StoreProductListing);
// Customizable Area End
