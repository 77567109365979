// Customizable Area Start
import React from "react";
import { Box, Button, Container, Grid, Link, Typography, styled } from "@material-ui/core";
import FooterController, { Props, configJSON } from "./FooterController.web";
import { bitmapsImg, emailImg, fbImg, linkdinImg, originalsImg, tiktokImg, whatsappImg } from "./LandingPageController";
import { ClickableBox } from "../../otp-input-confirmation/src/CustomElementStyling";
// Customizable Area End

class Footer extends FooterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={styles.footer} data-test-id="footer" id="footer">
          <Container maxWidth={"lg"}>
            <StyledGrid container spacing={1}>
              <Grid item xs={6} sm={4} style={{ marginBottom: "20px" }}>
                <Box style={styles.categoriesContainer}>{configJSON.categoriesLabel}</Box>

                <Grid container spacing={1}>
                  {this.chunkArray(this.state.categories, 9).map((column, columnIndex) => (
                    <Grid item lg={4} sm={4} xs={12} key={columnIndex}>
                      <Box style={styles.test2}>
                        {column.map((item: any, index: number) => (
                          <Link href={`/buyer/categories/${item.id}/${item.attributes.name}`} underline="none" rel={"noopener"}>
                            <Typography key={index} style={styles.text}>
                              {item.attributes.name}
                            </Typography>
                          </Link>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={6} sm={4} style={{ marginBottom: "20px" }}>
                <Box style={styles.categoriesContainer}>{configJSON.quickLinkLabel}</Box>
                <Box style={styles.test2}>
                  <ClickableBox data-test-id="click-1" onClick={() => this.handleGoToStaticPage("About us")}>
                    <Typography style={styles.text}>{configJSON.aboutUsLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-2" onClick={() => this.handleGoToStaticPage("Testimonials")}>
                    <Typography style={styles.text}>{configJSON.testimonialLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-3" onClick={() => this.handleGoToStaticPage("Contact")}>
                    <Typography style={styles.text}>{configJSON.contactLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-4" onClick={() => this.handleGoToStaticPage("Privacy Policy")}>
                    <Typography style={styles.text}>{configJSON.privacyPolicyLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-5" onClick={() => this.handleGoToStaticPage("Support")}>
                    <Typography style={styles.text}>{configJSON.supportLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-6" onClick={() => this.handleGoToStaticPage("Terms & Conditions")}>
                    <Typography style={styles.text}>{configJSON.termsConditionsLabel}</Typography>
                  </ClickableBox>
                  <ClickableBox data-test-id="click-7" onClick={() => this.handleGoToStaticPage("Shipping & Returns")}>
                    <Typography style={styles.text}>{configJSON.shippingReturnLabel}</Typography>
                  </ClickableBox>
                  <Box sx={{ marginTop: "30px" }}>
                    <Typography style={styles.body1}>{configJSON.wantToSellWithUsText}</Typography>
                    <Button style={styles.signUpBtnWrapper} onClick={this.handleGoToSignUpPage} data-test-id="goToSignUpButton">
                      <Box style={styles.signUpBox}>{configJSON.signUpLabel}</Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} style={{ marginBottom: "20px" }}>
                <Box style={styles.categoriesContainer}>{configJSON.findOnSocialPlatformText}</Box>
                <Grid container spacing={2}>
                  <Grid item>
                    <Link data-test-id="fbIcon" href={this.setSocialMediaUrl("Facebook")} underline="none" target="_blank" rel="noopener">
                      <Box style={{...styles.socialIcons,backgroundColor: '#39579C' }}>
                        <img src={fbImg} width={"34px"} style={{ width: '24px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={this.setSocialMediaUrl("LinkedIn")} underline="none" target="_blank" rel="noopener">
                      <Box style={{...styles.socialIcons,backgroundColor: '#2581F6' }}>
                        <img src={linkdinImg} width={"34px"} style={{width: '24px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={this.setSocialMediaUrl("Instagram")} underline="none" target="_blank" rel="noopener">
                      <Box style={{...styles.socialIcons,backgroundColor: '#D40C0C' }}>
                        <img src={emailImg} width={"34px"} style={{width: '24px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={"https://www.whatsapp.com"} underline="none" target="_blank" rel="noopener">
                      <Box style={{...styles.socialIcons,backgroundColor: '#509B2E' }} >
                        <img src={whatsappImg} width={"34px"} style={{width: '24px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href={this.setSocialMediaUrl("TikTok")} underline="none" target="_blank" rel="noopener">
                      <Box style={{...styles.socialIcons,backgroundColor: '#F76330' }} >
                        <img src={tiktokImg} width={"34px"} style={{width: '27px', margin: 'auto' }} />
                      </Box>
                    </Link>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "30px", alignItems: "center" }}>
                  <Grid item >
                    <Box style={styles.originalImg}>
                      <img src={originalsImg} width={"34px"} style={{ width: '40px', margin: 'auto', marginTop: '-5px' }} />
                    </Box>
                  </Grid>
                  <Grid item style={{width:'75%'}}>
                    <Typography style={{...styles.fontTheme1, fontSize:12}}>{configJSON.guaranteeText}</Typography>
                    <Typography style={{...styles.fontTheme1, fontSize:12}}>{configJSON.forAllProductsText}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "15px", alignItems: "center" }}>
                  <Grid item>
                    <Box style={styles.originalImg}>
                      <img src={bitmapsImg} width={"34px"} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography style={styles.fontTheme1}>{configJSON.returnWithIn30DaysText}</Typography>
                    <Typography style={styles.fontTheme1}>{configJSON.receivingOrderText}</Typography>
                  </Grid>
                </Grid>

                <Box style={styles.btnWrapper} />
                <Button data-test-id="goToHome" onClick={this.handleGoToHomeContactUsPage} style={styles.contactUsWrapper}>
                  <Box style={styles.contactUsBox}>{configJSON.contactUsText}</Box>
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} style={{ marginBottom: "10px", marginTop: "20px" }}>
                <Box style={styles.copyRightText}>{configJSON.copyRightText}</Box>
              </Grid>
            </StyledGrid>
          </Container>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  fontTheme1: {
    fontSize: 14,
    color: "white",
    fontFamily: "Lato, sans-serif",
    paddingBottom: "5px"
  },
  text: {
    color: "#ffffff",
    fontFamily: `"Lato",sans-serif` as const,
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "capitalize" as const
  },
  footer: {
    width: "100%",
    background: "rgba(23,34,54,255)",
    padding: "20px 0px"
  },
  categoriesContainer: {
    fontSize: 18,
    fontWeight: "bold" as const,
    marginBottom: "30px",
    color: "white"
  },
  signUpBtnWrapper: {
    marginTop: "11px",
    background: "rgba(253, 97, 12, 1)",
    textTransform: "none" as const,
    fontSize: 14,
    fontWeight: 600 as const,
    fontFamily: `"Lato",sans-serif` as const,
    borderRadius: "5px",
    width: "fit-content"
  },
  signUpBox: {
    padding: "5px 33px",
    fontWeight: "bold" as const,
    fontSize: 12,
    color: "white",
    fontFamily: "Lato, sans-serif" as const
  },
  socialIcons: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: 'flex',
  },
  originalImg: {
    width: "45px",
    height: "40px",
    borderRadius: "50px"
  },
  btnWrapper: {
    width: "185px",
    height: "2px",
    marginTop: "30px",
    background: "white"
  },
  contactUsWrapper: {
    marginTop: "20px",
    background: "rgba(253, 97, 12, 1)",
    textTransform: "none" as const,
    fontSize: 14,
    fontWeight: 600 as const,
    fontFamily: `"Lato", sans-serif` as const,
    borderRadius: "5px",
    width: "fit-content"
  },
  contactUsBox: {
    padding: "5px 43px",
    fontWeight: "bold" as const,
    fontSize: 13,
    color: "white",
    fontFamily: "Lato, sans-serif" as const
  },
  copyRightText: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 14,
    color: "#eeedee"
  },
  test2: {
    width: "100%"
  },
  body1: {
    fontSize: 16,
    color: "white",
    fontFamily: "Lato, sans-serif" as const
  }
};

const StyledGrid = styled(Grid)({
  padding: "30px 60px 0",
  justifyContent: "center",
  '@media (max-width:600px)': {
    padding: "15px 0",
  },
})

export { Footer };

export default Footer;
// Customizable Area End
