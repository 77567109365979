import React from "react";

// Customizable Area Start
import { Box, IconButton, Input, InputAdornment, InputLabel, Modal, ThemeProvider, Typography } from "@material-ui/core";
import { errorImage } from "../../bulkuploading/src/assets";
import ClearIcon from "@material-ui/icons/Clear";
import Visibility from "@material-ui/icons/Visibility";
import { theme } from "./CustomElementStyling";
import { ClickableBox, VisibilityOffSvg, CustomSubmitButton, CustomFormControl, CustomTextField, OtpInput } from "../../mobile-account-login/src/CustomElementStyling";
import { CustomBox } from "../../otp-input-confirmation/src/CustomElementStyling";
import { emailImg, successImg } from "../../otp-input-confirmation/src/OTPInputAuthController";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Loader2 } from "../../../components/src/Loader.web";
// Customizable Area End

import BuyerSignupController, { Props, configJSON } from "./BuyerSignupController";

export default class BuyerSignup extends BuyerSignupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  returnErrorMessage = (touched: boolean | undefined, errors: string | undefined) => {
    if (touched && errors) {
      return <Typography style={webStyle.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };

  validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First name name is required")
      .matches(configJSON.nameRegex, configJSON.firstNameValidationMessage)
      .max(50, "First name must not exceed 50 characters"),
    last_name: Yup.string()
      .required("Last name name is required")
      .matches(configJSON.nameRegex, configJSON.lastNameValidationMessage)
      .max(50, "Last name name must not exceed 50 characters"),
    email: Yup.string()
      .email("Invalid email")
      .matches(configJSON.emailRegex, configJSON.invalidEmailText)
      .required("Email is required")
      .max(256, "Email must not exceed 256 characters"),

    full_phone_number: Yup.string()
      .matches(configJSON.phoneFormatRegex, configJSON.phoneNumberErrFormat)
      .required("Phone number is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,32}$/,
        "Password must have at least one lowercase letter, one uppercase letter, one digit, one special character, and no spaces. It should be between 8 and 32 characters long."
      )
      .min(8, "Password should be at least 8 characters long")
  });

  CloseModal = (testIdRef: string) => {
    return (
      <Box sx={webStyle.closeButton}>
        <IconButton onClick={this.handleCloseModal} data-test-id={testIdRef}>
          <ClearIcon style={{ color: "#3E3E3E" }} />
        </IconButton>
      </Box>
    );
  };
  ShowError = () => {
    return (
      <>
        {this.state.errMsg.map((item, index) => {
          return (
            <Box sx={webStyle.errorMsgContainer} key={index}>
              <Box sx={webStyle.rightMargin}>
                <img src={errorImage} />
              </Box>
              <Box data-test-id="error" style={webStyle.errorOne}>
                {item}
              </Box>
            </Box>
          );
        })}
      </>
    );
  };
  BuyerSignupModal = () => {
    const getPasswordType = this.state.showPassword ? configJSON.textLabel : "password";
    return (
      <Modal open={this.state.showSignUpModal} hideBackdrop={true}>
        <Box sx={webStyle.mainContainer}>
          {this.state.isFetching ? <Loader2 loading={this.state.isFetching} /> : <></>}
          <Box sx={webStyle.blurredBackground}></Box>
          <Box sx={webStyle.modalContainer}>
            {this.CloseModal("closeEmailLoginButton")}
            <Box style={fontStyle.h1}>Sign Up</Box>
            <Box sx={webStyle.subHeaderBox}>
              <Box style={{ marginRight: "5px", fontWeight: "bold", ...fontStyle.h2 }}>Already have an account?</Box>
              <ClickableBox data-test-id="goToSignUpPageRef" onClick={this.handleGoToSignInPage}>
                <Box style={fontStyle.h3}>Sign In</Box>
              </ClickableBox>
            </Box>
            <Formik
              innerRef={this.formikRef}
              initialValues={{
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                full_phone_number: this.state.full_phone_number,
                password: this.state.password
              }}
              enableReinitialize={true}
              validationSchema={this.validationSchema}
              validateOnMount={true}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={this.signUpBuyer}
              data-test-id="formik"
            >
              {({ handleChange, errors, setFieldTouched, touched, setFieldValue, handleBlur, values, setErrors }) => (
                <Form
                  translate={undefined}
                  // className={classes.formWrraper}
                >
                  <CustomTextField
                    data-test-id="first_name"
                    label="First name"
                    name="first_name"
                    value={values.first_name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(event);
                      setFieldTouched("first_name", true, false);
                    }}
                    onBlur={handleBlur}
                    fullWidth
                    style={{ marginTop: "40px" }}
                  />
                  {this.returnErrorMessage(touched.first_name, errors.first_name)}
                  <CustomTextField
                    data-test-id="last_name"
                    label="Last name"
                    name="last_name"
                    value={values.last_name}
                    onChange={event => {
                      handleChange(event);
                      setFieldTouched("last_name", true, false);
                    }}
                    onBlur={handleBlur}
                    fullWidth
                  />
                  {this.returnErrorMessage(touched.last_name, errors.last_name)}
                  <CustomTextField
                    data-test-id="email"
                    label="Email address"
                    name="email"
                    value={values.email}
                    onChange={event => {
                      handleChange(event);
                      setFieldTouched("email", true, false);
                    }}
                    onBlur={handleBlur}
                    fullWidth
                  />
                  {this.returnErrorMessage(touched.email, errors.email)}
                  <CustomTextField
                    data-test-id="phone_number"
                    name="full_phone_number"
                    value={values.full_phone_number}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      this.handleContactNumberChangeBuyer(event, setFieldValue);
                      setFieldTouched("full_phone_number", true, false);
                    }}
                    onBlur={handleBlur}
                    label={configJSON.phoneNumberLabel}
                    fullWidth
                  />
                  {this.returnErrorMessage(touched.full_phone_number, errors.full_phone_number)}

                  <CustomFormControl fullWidth={true}>
                    <InputLabel htmlFor="standard-adornment-password" style={webStyle.text}>
                      password
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      data-test-id="password"
                      type={getPasswordType}
                      name="password"
                      onChange={event => {
                        handleChange(event);
                        setFieldTouched("password", true, false);
                      }}
                      onBlur={handleBlur}
                      value={values.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton data-test-id="showPasswordRef" aria-label="toggle password visibility" onClick={this.handleShowPassword}>
                            {this.state.showPassword ? <Visibility className="rotateIcon" /> : <VisibilityOffSvg />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {this.returnErrorMessage(touched.password, errors.password)}
                  </CustomFormControl>
                  <Box>{this.ShowError()}</Box>
                  <CustomSubmitButton type="submit" disabled={this.state.isFetching} data-test-id="sign_in" style={{ marginTop: 45, fontFamily: "'Lato', sans-serif", width: "100%" }}>
                    Sign Up
                  </CustomSubmitButton>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    );
  };
  OTPModal = () => {
    return (
      <Modal open={this.state.showOTPModal} hideBackdrop={true}>
        <Box sx={webStyle.mainContainer}>
          <Box sx={webStyle.blurredBackground}></Box>
          <Box sx={webStyle.modalContainer}>
            {this.CloseModal("closeEmailVerifButton")}
            <Box style={fontStyle.h1}>OTP Verification</Box>
            <Box sx={webStyle.subHeaderBox}>
              <Box>
                <Typography style={{ ...fontStyle.codeText }}>Please enter 5 digit code sent to your phone number 
                </Typography><Typography style={{ marginTop:'4px', ...fontStyle.codeText }}>{`+${this.state.country_code}-${"XXXX"} ${"XXX"}${this.state.phone_number.slice(7)} / email - ` + this.maskBuyerEmail()}</Typography>
              </Box>
            </Box>
            <Box sx={webStyle.inputContainer}>
              <Box>
                <OtpInput
                  data-test-id="input_1"
                  placeholder="-"
                  value={this.state.inputValues[0]}
                  ref={this.inputRefs[0]}
                  inputProps={{ maxLength: 1 }}
                  onChange={event => this.handleInputOnChange(0, event)}
                  onKeyDown={event => this.handleInputOnPress(0, event)}
                />
              </Box>
              <Box>
                <OtpInput
                  data-test-id="input_2"
                  placeholder="-"
                  value={this.state.inputValues[1]}
                  ref={this.inputRefs[1]}
                  inputProps={{ maxLength: 1 }}
                  onChange={event => this.handleInputOnChange(1, event)}
                  onKeyDown={event => this.handleInputOnPress(1, event)}
                />
              </Box>
              <Box>
                <OtpInput
                  data-test-id="input_3"
                  placeholder="-"
                  value={this.state.inputValues[2]}
                  ref={this.inputRefs[2]}
                  inputProps={{ maxLength: 1 }}
                  onChange={event => this.handleInputOnChange(2, event)}
                  onKeyDown={event => this.handleInputOnPress(2, event)}
                />
              </Box>
              <Box>
                <OtpInput
                  data-test-id="input_4"
                  placeholder="-"
                  value={this.state.inputValues[3]}
                  ref={this.inputRefs[3]}
                  inputProps={{ maxLength: 1 }}
                  onChange={event => this.handleInputOnChange(3, event)}
                  onKeyDown={event => this.handleInputOnPress(3, event)}
                />
              </Box>
              <Box>
                <OtpInput
                  id="okay"
                  data-test-id="input_5"
                  placeholder="-"
                  value={this.state.inputValues[4]}
                  ref={this.inputRefs[4]}
                  inputProps={{ maxLength: 1 }}
                  onChange={event => this.handleInputOnChange(4, event)}
                  onKeyDown={event => this.handleInputOnPress(4, event)}
                />
              </Box>
            </Box>
            <Box sx={webStyle.codeVerifyTimer}>
              <Box style={fontStyle.h5}>
                {this.state.mins}:{this.state.secs < 10 ? `0${this.state.secs}` : this.state.secs} min
              </Box>
              <Box sx={webStyle.didNotGetOtpBox}>
                <Box style={{ marginRight: "5px", ...fontStyle.h6 }}>Did not get OTP ?</Box>
                <ClickableBox data-test-id="resend" onClick={() => this.handleResendOtp()}>
                  <Box style={fontStyle.h3}>Resend now</Box>
                </ClickableBox>
              </Box>
            </Box>
            <Box sx={{ height: "120px" }}>{this.ShowError()}</Box>
            <CustomSubmitButton disabled={this.state.isFetching || (this.state.mins <= 0 && this.state.secs <= 0 ? true : false)} data-test-id="submit_otp" onClick={this.handleSubmitOtp}>
              Submit
            </CustomSubmitButton>
          </Box>
        </Box>
      </Modal>
    );
  };
  SuccessModal = (modal: boolean, header: string, subheader: string, buttonRef: string) => {
    return (
      <Modal open={modal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <CustomBox data-test-id="test" sx={webStyle.modalMainWrapper}>
          <Box sx={webStyle.blurredBackground}></Box>
          <CustomBox sx={webStyle.verifiedModalContainer} style={{ background: "white", height: "350px" }}>
            {this.CloseModal(buttonRef)}
            <CustomBox sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <CustomBox sx={webStyle.verifiedImageWrapper}>
                <CustomBox sx={webStyle.verifiedImageContainer}>
                  <img src={successImg} alt="success image" />
                </CustomBox>
                <CustomBox sx={{ marginBottom: "10px", fontWeight: "bold", fontSize: "20px", color: "#565656" }}>{header}</CustomBox>
                <CustomBox sx={{ marginBottom: "30.1px", fontSize: "12px", color: "#565656" }}>{subheader}</CustomBox>
              </CustomBox>
            </CustomBox>
          </CustomBox>
        </CustomBox>
      </Modal>
    );
  };
  EmailLinkModal = () => {
    return (
      <Modal open={this.state.showEmailLink} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <CustomBox data-test-id="test" sx={webStyle.modalMainWrapper}>
          <Box sx={webStyle.blurredBackground}></Box>
          <CustomBox sx={webStyle.sendEmailVerifModal}>
            {this.CloseModal("SendEmailVerificationModal")}
            <CustomBox
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "100%",
                alignItems: "center"
              }}
            >
              <CustomBox
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  marginBottom: "20px"
                }}
              >
                <img src={emailImg} alt="email image" />
              </CustomBox>
              <CustomBox sx={{ marginBottom: "15px", fontWeight: "bold", fontSize: "20px", color: "#565656", fontFamily: `"Lato", sans-serif` }}>We need to Verify your email address</CustomBox>
              <CustomBox sx={{ marginBottom: "10px", fontSize: "12px", color: "#565656", fontWeight: "400" }}>Please check your inbox for the verification link.</CustomBox>
              <CustomBox sx={{ marginBottom: "35px", fontSize: "12px", color: "#565656" }}>Email sent to {this.state.email}</CustomBox>
              <CustomBox
                sx={{
                  display: "flex",
                  width: "fit-content",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#565656"
                }}
              >
                <CustomBox>Did not get a link ?</CustomBox>
                <ClickableBox>
                  <CustomBox
                    data-test-id="resendEmail"
                    sx={{ marginLeft: "5px", fontWeight: "bold" }}
                    style={{ color: "#FF6008", fontSize: "13px" }}
                    onClick={() => { this.resendEmailLink() }}
                  >
                    Resend now
                  </CustomBox>
                </ClickableBox>
              </CustomBox>
              <CustomBox
                sx={{
                  display: "flex",
                  height: "60px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  flexDirection: "column",
                  padding: "10px 0px",
                  color: "rgb(24 81 11)"
                }}
              >
                <CustomBox>{this.state.resendMsg}</CustomBox>
              </CustomBox>
              <CustomBox sx={{ fontSize: "10px", color: "#565656", fontWeight: "400" }}>Can't find? Please check your spam folder.</CustomBox>
            </CustomBox>
          </CustomBox>
        </CustomBox>
      </Modal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          {this.BuyerSignupModal()}
          {this.OTPModal()}
          {this.SuccessModal(this.state.showOTPSuccessModal, "OTP Verified", "You are being redirected.", "OTPVerificationModal")}
          {this.SuccessModal(this.state.showEmailSuccessModal, "Email Verified", "You are being redirected.", "EmailSuccessModal")}
          {this.EmailLinkModal()}
          {this.state.invalidToken && <Box>{this.state.invalidToken}</Box>}
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  closeButton: {
    position: "absolute",
    top: "28px",
    right: "28px",
    boxShadow: "0px 3px 6px rgba(86, 86, 86, 0.1)",
    borderRadius: "100%"
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    background: "none",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  errorMsgContainer: {
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "20px"
  },
  blurredBackground: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)",
    position: "absolute",
    zIndex: -1,
    backgroundColor: "rgba(0,0,0,0.1)"
  },
  modalContainer: {
    background: "white",
    borderRadius: "8px",
    position: "relative",
    flexDirection: "column",
    margin: "15px",
    display: "flex",
    width: "495px",
    padding: "50px"
  },
  forgotPwBox: {
    marginTop: "35px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "60px",
    marginBottom: "20px",
    flexWrap: "nowrap",
    width: "calc(100% - 90px)"
  },
  subHeaderBox: {
    marginTop: "22px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  codeVerifyTimer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "calc(100% - 92px)",
    flexWrap: "wrap"
  },
  didNotGetOtpBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center"
  },
  verifiedImageContainer: {
    width: "100px",
    height: "100px",
    marginBottom: "24px"
  },
  verifiedModalContainer: {
    width: "450px",
    maxWidth: "450px",
    position: "relative",
    padding: "40px",
    borderRadius: "10px",
    display: "flex"
  },
  sendEmailVerifModal: {
    width: "540px",
    maxWidth: "540px",
    borderRadius: "10px",
    padding: "50px 50px 32px 50px",
    position: "relative",
    height: "400px",
    backgroundColor: "white"
  },
  modalMainWrapper: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  verifiedImageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  error: {
    fontSize: 12,
    color: "#D74D4D",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 700,
    marginBottom: "12px"
  },
  text: {
    textTransform: "capitalize" as const
  },
  rightMargin: {
    marginRight: "5px"
  },
  errorOne: {
    fontSize: 12,
    color: "#D74D4D",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 700
  },
  errorStyle: {
    fontFamily: "Lato, sans-serif",
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "0px"
  }
};

const fontStyle = {
  h2: {
    color: "5E5E5E",
    fontSize: "12px",
    fontFamily: `"Lato", sans-serif`
  },
  h5: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#061B6F",
    fontFamily: `"Lato", sans-serif`
  },
  h4: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#061B6F",
    fontFamily: `"Lato", sans-serif`
  },

  h6: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#565656",
    fontFamily: `"Lato", sans-serif`
  },
  h3: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#FF6008",
    fontFamily: `"Lato", sans-serif`
  },
  h1: {
    fontSize: "20px",
    fontWeight: 750,
    color: "#3E3E3E",
    fontFamily: `"Lato", sans-serif`
  },
  codeText : {
    fontSize: "12px",
    fontFamily: `"Lato", sans-serif`,
    color: '#3e3e3e',
    marginRight: "5px", 
    fontWeight: 400
  }
};
// Customizable Area End
