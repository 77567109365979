import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("../../src/config.js");

export interface ICategory {
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      parent_id: number,
      relation: string,
      mini_categories: 
        {
          id: number,
          name: string,
          micro_categories: {
              id: number,
              name: string
            }[]
        }[]
      
    }
}

export interface ICategoryRes {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string
  }
}

export interface IBrandList {
  id: string,
  type: string,
  attributes: {
    id: number,
    brand_name: string,
    brand_image: string
  }
}
interface DocumentsData {
  id: string;
  type: string;
  attributes: {
    document_type: string;
    document_name: string;
    vat_reason: string;
    account_no: string;
    iban: string;
    bank_address: string;
    name: string
    bank_name: string;
    swift_code: string;
    approved: boolean | null;
    rejected: boolean | null;
    reason_for_rejection: string;
    account_id: number;
    document_files: string[];
  };
}
export interface SuccessResponse {
  data: DocumentsData[];
  meta: {
    message: string;
  };
  min_range:string;
  max_range:string;
  message: string;
  total_count: number
}
export interface ProductListArgs {
  category_id?: string | number,
  catagoryIds?: string[];
  subCatagoryIds?: string[];
  brandIds?: string[];
  miniCategoryIds?: string[];
  microCategoryIds?: string[];
  per_page?: number | string;
  min_price?: number | null ;
  max_price?: number | null ;
  customFieldValues?: string[];
  filter_by_rating?: number[];
  color_filter?: string[];
}

interface ProductAttribute {
  product_title: string;
  product_image: string;
  product_content: {
      product_attributes: {
          product_title: string;
      }
  }
}

interface TrendingProduct {
  data: {
      id: string;
      type: string;
      attributes: ProductAttribute;
  };
}


interface IProductData {
  id:string,
  attributes: {
    sale_ad_image: string,
    catalogues: TrendingProduct[]
  }
}

interface ProductRes {
  data: IProductData[]
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match: any;
  classes: {
    trandingTemlateImg: string;
    trendingTemplate: string;
    trendingBack2: string;
    dualSliderTxtBack: string;
    itemSliderWrraper: string;
    root: string,
    mainWrraper: string,
    brandContainer: string
    brandBtn: string
    drawer: string,
    drawerPaper: string,
    content: string,
    contentWrapper: string
    headerContainer: string,
    headerText: string,
    container: string,
    orderTextWrapper: string,
    ordersText: string,
    searchWrapper: string,
    searchOrderText: string,
    searchBoxWrapper: string,
    lAndIContainer: string,
    options: string,
    label: string,
    select: string,
    brandImage: string,
    category: string,
    shopByText: string,
    brandName: string,
    categoryOne: string,
    flex: string,
    catalogContainer: string,
    selectDisplay: string,
    displayFilter: string,
    categoryFilterContainer?: string;
    shopByBrandContainer?: string;
    productWrapper: string;
    noProductsBox: string;
    noProductsText: string;
    pagination: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  out_of_stocks: boolean;
  authToken: string;
  searchValue: string;
  productList: any
  category_id: any
  subCategoryList: ICategory[],
  brandList: IBrandList[],
  itemPerPage: any
  subCatagoryItemsIds: string[];
  brandItemsIds: string[];
  min_price: number | null;
  max_price: number | null;
  minRange: number | null;
  maxRange: number | null;
  customFieldsData: {
    [key: string]: string[]; 
  }
  customFieldValues: string[],
  miniCategoryIds: string[],
  microCategoryIds: string[],
  sortByProduct: string;
  rating: number[],
  color_filter: string[],
  productListOne: TrendingProduct[];
  productListTwo: TrendingProduct[];
  updatedRating: boolean[];
  customFields: string[];
  catagoryItemsIds: string[];
  categoryList: ICategoryRes[];
  miniCatagoryItemsIds: string[];
  miniCategoryList: ICategoryRes[];
  microCatagoryItemsIds: string[];
  microCategoryList: ICategoryRes[];
  listView: boolean,
  pageNumber: number,
  totalProdCount: number,
  sliderNumber : number | null
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ProductCatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getApiProductListCallId: string = "";
  getApiSubCategoryCallId: string = "";
  getApiBrandCallId: string = "";
  getSocialMediaInfoApiCallId: string = "";
  getCustomFieldsApiCallId: string = "";
  getSearchKeywordApiCallId: string = "";
  getProductOneApiCallId: string = "";
  getProductTwoApiCallId: string = "";
  getMiniCategoriesApiCallId: string = "";
  getMicroCategoriesApiCallId: string = "";
  getSubCategoriesApiCallId:string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      authToken: "",
      searchValue: '',
      category_id: '',
      subCatagoryItemsIds: [],
      brandItemsIds: [],
      subCategoryList: [],
      brandList: [],
      itemPerPage: 50,
      min_price: null,
      max_price: null,
      minRange: null,
      maxRange: null,
      productList: {},
      customFieldsData: {},
      customFieldValues: [],
      miniCategoryIds: [],
      microCategoryIds: [],
      sortByProduct: "recommended",
      rating: [],
      color_filter: [],
      out_of_stocks: false,
      productListOne: [],
      productListTwo: [],
      updatedRating: [],
      customFields: [],
      catagoryItemsIds: [],
      categoryList: [],
      miniCatagoryItemsIds: [],
      miniCategoryList: [],
      microCatagoryItemsIds: [],
      microCategoryList: [],
      listView: false,
      pageNumber: 1,
      totalProdCount: 0,
      sliderNumber: null
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handleSuccessResponse(responseJson, apiRequestCallId);

    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    // Check if prop or state has changed
    window.addEventListener('popstate', this.handlePopState);
    if (prevProps.match.params?.id !== this.props.match.params?.id || prevProps.match.params?.type !== this.props.match.params?.type) {
      // Call your function here
      if(this.state.customFieldsData !== prevState.customFieldsData){
        this.setState({customFieldValues: []})
      }
      if(this.state.category_id !== prevState.category_id){
        this.setState({pageNumber: 1, subCatagoryItemsIds: [], brandItemsIds:[], category_id: this.state.category_id })
      }
      this.setState({pageNumber: 1,min_price: null, max_price:null, customFieldValues: [], customFieldsData: {}, brandItemsIds: [], subCatagoryItemsIds: [], catagoryItemsIds:[], miniCatagoryItemsIds:[], microCatagoryItemsIds:[],rating: [], color_filter: [], out_of_stocks: false, sortByProduct: "recommended", itemPerPage: "50", miniCategoryList:[], microCategoryList: []  }, () => this.onMountApicall())
    }
    if(this.state.subCatagoryItemsIds !== prevState.subCatagoryItemsIds){
      this.setState({customFieldValues: [],pageNumber: 1  })
    }
    if(this.state.catagoryItemsIds !== prevState.catagoryItemsIds || this.state.miniCatagoryItemsIds !== prevState.miniCatagoryItemsIds || this.state.microCatagoryItemsIds !== prevState.microCatagoryItemsIds || this.state.color_filter !== prevState.color_filter || this.state.min_price !== prevState.min_price || this.state.max_price !== prevState.max_price || this.state.brandItemsIds !== prevState.brandItemsIds || this.state.rating !== prevState.rating || this.state.out_of_stocks !== prevState.out_of_stocks || this.state.customFieldValues !== prevState.customFieldValues ){
      this.setState({pageNumber: 1})
    }
    else return
  }

  async componentDidMount() {
    const authToken = await getStorageData("authToken");
    const sortByProduct = await getStorageData("sortFilter");
    const itemPerPage = await getStorageData("itemPerPage") 
    const colorFilter = await getStorageData("colorFilter") 
    
    const currentParams = new URLSearchParams(window.location.search);  
    const params = window.location.pathname;  
    const customFiltersFromUrl = currentParams.get("customFilters")
    const customFilter = customFiltersFromUrl ? customFiltersFromUrl.replace(/_/g, ' ').split(',') : [];

    if(itemPerPage) {
      this.setState({itemPerPage})
    }
    if(sortByProduct) {
      this.setState({sortByProduct})
    }
    this.setState({ authToken, out_of_stocks: false, color_filter: colorFilter, customFields: customFilter })
    if( params.split("/")[3] === "noResult") { 
      this.getTrendingProducts(1)
      this.getTrendingProducts(2)
    }
    this.onMountApicall();
    this.handlePopState()
  }

  handlePopState = () => {
    const query = new URLSearchParams(window.location.search)
    const sortValue = query.get('sort') || 'recommended'
    const outOfStock = query.get('outOfStock')
    const perPageValue = query.get('itemPerPage') || '50'
    const catagoryIds = query.get('catagoryIds')
    const subCategoryIds = query.get('subCatagoryIds')
    const miniCategoryIds = query.get('miniCatagoryIds')
    const microCategoryIds = query.get('microCatagoryIds')
    const brandIds = query.get('brandIds')
    const colors = query.get('color')
    const rating:any = query.get('rating')

    const decodedQuery = decodeURIComponent(rating);
    const values = decodedQuery.split(',');
    const result = new Array(5).fill(false);
    values.forEach(value => {
      const index = parseInt(value, 10) - 1; 
      if (index >= 0 && index < result.length) {
        result[index] = true;
      }
    });

    const categoryIdsArray = this.getIds(catagoryIds);
    const subCategoryIdsArray = this.getIds(subCategoryIds);
    const miniCategoryIdsArray = this.getIds(miniCategoryIds);
    const microCategoryIdsArray = this.getIds(microCategoryIds);
    const brandIdsArray = brandIds ? brandIds.split(',') : [];
    const colorsArray = colors ? colors.split(',') : [];
    const filteredColorResults = colorsArray.map(item => item.replace(/_/g, ' '))
    const reversedResult = result.reverse();
    const filteredRatingRes = reversedResult;
    const ratingResults = JSON.stringify(filteredRatingRes)
    setStorageData("subCatagoryIds", JSON.stringify(subCategoryIdsArray))
    setStorageData("miniCatagoryIds", JSON.stringify(subCategoryIdsArray))
    setStorageData("microCatagoryIds", JSON.stringify(subCategoryIdsArray))
    setStorageData("brandIds", JSON.stringify(brandIdsArray))
    setStorageData("colorFilter", JSON.stringify(filteredColorResults))
    setStorageData("outOfStock",outOfStock )
    setStorageData("rating", ratingResults)
    this.setState({ catagoryItemsIds: categoryIdsArray, miniCatagoryItemsIds: miniCategoryIdsArray, microCatagoryItemsIds: microCategoryIdsArray,rating: !rating ? [] : values as any, updatedRating: result, out_of_stocks : Boolean(outOfStock) , color_filter: filteredColorResults ,brandItemsIds: brandIdsArray, subCatagoryItemsIds: subCategoryIdsArray,sortByProduct : sortValue, itemPerPage: perPageValue},() => {
      this.handleProductlistContent({filter_by_rating: values as any })
      this.handleFiltersData(categoryIdsArray, subCategoryIdsArray, miniCategoryIdsArray)
  })
  }

  handleFiltersData = (categoryIdsArray: string[], subCategoryIdsArray: string[], miniCategoryIdsArray:string[],) => {
    if(subCategoryIdsArray.length > 0 && miniCategoryIdsArray.length === 0) {
      this.setState({microCategoryList: []})
    }
    if(subCategoryIdsArray.length > 0) {
      this.getMiniCategories()
    }
    if(miniCategoryIdsArray.length > 0) {
      this.getMicroCategories()
    }
    if(categoryIdsArray) {
      this.getSubCategories()
    }
    if(subCategoryIdsArray.length === 0){
      this.setState({miniCategoryList: []})
    }
  }

  getIds = (filterIds: any) => {
    return filterIds ? filterIds.split(',') : []
  }

  async componentWillUnmount() {
   await removeStorageData('sortFilter');
   await removeStorageData('itemPerPage');
   await removeStorageData('brandIds');
   await removeStorageData('subCatagoryIds');
   await removeStorageData('category_id');
   await removeStorageData('miniCatagoryIds');
   await removeStorageData('microCatagoryIds');
   await removeStorageData('rating');
   await removeStorageData('trending');
  }

  onMountApicall() {
    const { match } = this.props;
    const category_id = match.params?.id;

    const decodedUrl = decodeURIComponent(window.location.href);

    const subCatagoryIdsMatch = decodedUrl.match(/subCatagoryIds=([\d,]+)/);
    
    const subCatagoryIds = subCatagoryIdsMatch ? subCatagoryIdsMatch[1].split(',') : [];

    const getCategoryId = category_id === "product" ? [] : category_id
    this.setState({ category_id: getCategoryId, subCatagoryItemsIds: subCatagoryIds })
    this.handleProductlistContent({ catagoryIds : getCategoryId });
    this.subCategoryApiCall(getCategoryId);
    this.brandApiCall(getCategoryId);
  }

  getCustomFields = (category_id:string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomFieldsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/fetch_custom_field_filters?category_ids[]=${category_id}&sub_category_ids[]=${this.state.subCatagoryItemsIds}&mini_category_ids[]=${this.state.miniCatagoryItemsIds}&micro_category_ids[]=${this.state.microCatagoryItemsIds}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  handleSuccessResponse = (responseJson: any, apiRequestCallId: any) => {

    switch (apiRequestCallId) {
      case this.getApiSubCategoryCallId:
        this.handleSubCategoryApiCallResponse(responseJson);
        break;

      case this.getApiBrandCallId:
        this.handleBrandApiCallResponse(responseJson);
        break;

      case this.getApiProductListCallId:
        this.handleProductlistContentResponse(responseJson);
        break;

      case this.getCustomFieldsApiCallId: 
        this.handleCustomFieldsResponse(responseJson)  
        break;

      case this.getProductOneApiCallId:
        const productRes = responseJson as ProductRes
        this.setState({ 
          productListOne: productRes?.data[0].attributes.catalogues, 
        })
        break;

      case this.getProductTwoApiCallId:
        const productTwoRes = responseJson as ProductRes
        this.setState({ 
          productListTwo: productTwoRes.data[0].attributes.catalogues, 
        })
        break; 
      
      case this.getMiniCategoriesApiCallId : 
        this.setState({miniCategoryList: responseJson?.data})
        break;

      case this.getMicroCategoriesApiCallId : 
        this.setState({microCategoryList: responseJson?.data}) 
        break; 

      case this.getSubCategoriesApiCallId : 
        this.setState({subCategoryList: responseJson?.data}) 
        break;

      default: break;
    }
  }

  handleCustomFieldsResponse = (responseJson: any) => {
    this.setState({customFieldsData: responseJson.custom_field_values })
  }

  handleProductlistContentResponse = (resposeJson: unknown) => {
    const successResponse = resposeJson as SuccessResponse;
    if ("data" in successResponse) {
      this.setState({ productList: successResponse, totalProdCount : successResponse.total_count })
      this.getCustomFields(this.state.category_id)
    }
  };

  handleSubCategoryApiCallResponse = async(resposeJson: any) => {
    const category_id = this.props.match.params?.id
    const trendingSlider = await getStorageData('trendingSlider')
    const successResponse = resposeJson.data as ICategory[] | ICategoryRes[];
    if(category_id === "product" || trendingSlider) {
      this.setState({ categoryList:  successResponse as ICategoryRes[]})
    }
    else this.setState({ subCategoryList:  successResponse as ICategory[]})
  };

  handleBrandApiCallResponse = (resposeJson: any) => {
    const successResponse = resposeJson ;
    this.setState({ brandList: successResponse.data || [] as IBrandList[]})
  };

  handleSetColorFilter = (color: string, min_price: number, max_price: number ) => {
    this.setState((prevState) => {
      const color_filter = prevState.color_filter.includes(color)
        ? prevState.color_filter.filter((clr) => clr !== color)
        : [...prevState.color_filter, color];
  
      return { color_filter };
    }, () => {
      this.handleUpdateUrl("color", this.state.color_filter)
      this.handleProductlistContent({min_price, max_price})
  });
  }

  handleOutOfStocks = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        out_of_stocks: event.target?.checked || false
      }, () => {
        this.handleUpdateUrl('outOfStock',this.state.out_of_stocks ? "true" : '')
        this.handleProductlistContent({})
      })
  }

  handleProductlistContent = async ({ catagoryIds = this.state.catagoryItemsIds, subCatagoryIds = this.state.subCatagoryItemsIds, brandIds = this.state.brandItemsIds, miniCategoryIds = this.state.miniCategoryIds, microCategoryIds = this.state.microCategoryIds, per_page = this.state.itemPerPage, min_price , max_price, customFieldValues, filter_by_rating = this.state.rating }: ProductListArgs) => {
    const localsubCatagoryIds = await getStorageData("subCatagoryIds", true);
    if(localsubCatagoryIds && localsubCatagoryIds.length > 0) {
      subCatagoryIds = localsubCatagoryIds
      this.setState({subCatagoryItemsIds: localsubCatagoryIds})
    }
    const localbrandIds = await getStorageData("brandIds", true);
    if(localbrandIds && localbrandIds.length > 0) {
      brandIds = localbrandIds
      this.setState({ brandItemsIds : localbrandIds})
    }
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    const isTrendingProducts =  window.location.pathname.includes('trending');
    const sliderNumber = window.location.pathname.split("/")[3]
    let body;
    let trendingBody =  {
      per_page,
      category_ids: this.setIds(catagoryIds) ,
      sub_category_ids: this.setIds(subCatagoryIds),
      mini_category_ids: this.setIds(miniCategoryIds), 
      micro_category_ids: this.setIds(microCategoryIds),
      brand_ids: this.setIds(brandIds),
      min_price: this.state.min_price ,
      max_price: this.state.max_price,
      page: this.state.pageNumber,
      sort_by: this.state.sortByProduct,
      color_filter: this.setIds(this.state.color_filter),
      filter_by_rating,
      out_of_stocks: this.state.out_of_stocks,
      custom_field_values: this.state.customFields.map((value) => value.replace(/_/g, ' '))
    }
    if(isSearch) {
      body = {
        search_keyword: await getStorageData("searchQuery"),
        ...trendingBody
      }
    }else{
      body =
      { 
        category_id: this.state.category_id,
        sub_category_ids: this.setIds(subCatagoryIds),
        mini_category_ids: this.setIds(miniCategoryIds), 
        micro_category_ids: this.setIds(microCategoryIds),
        brand_ids: this.setIds(brandIds),
        min_price: this.state.minRange,
        max_price: this.state.maxRange,
        per_page,
        page: this.state.pageNumber,
        sort_by: this.state.sortByProduct,
        filter_by_rating,
        color_filter: this.setIds(this.state.color_filter),
        out_of_stocks: this.state.out_of_stocks,
        custom_field_values: this.state.customFields.map((value) => value.replace(/_/g, ' '))
      };
    }

    const endPoint = this.getProductListEndpoint(isTrendingProducts, isSearch, Number(sliderNumber));
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiProductListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), isTrendingProducts ? JSON.stringify(trendingBody) : JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProductListEndpoint = (trendingProdExists: boolean, searchExists: boolean,sliderNumber: number ) => {
    if(trendingProdExists) {
      return configJSON.getTrendingProductsEndpoint(sliderNumber)
    }
    if(searchExists) {
      return configJSON.getSearchProductAPIEndPoint
    }
    else return configJSON.postProductListApiEndPoint
  }

  setIds = (idArray: string[] | number[]) => {
    return idArray.length > 0 ? idArray : null
  }

  subCategoryApiCall = async (categoryid: any) => {
    const isTrendingProducts = window.location.pathname.split("/")[3]
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiSubCategoryCallId = requestMessage.messageId;
    const searchQuery = await getStorageData('searchQuery');
    const categoryListEndpoint = this.getCatEndPoint(searchQuery, isTrendingProducts, searchQuery,categoryid )
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),categoryListEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  getCatEndPoint = (isSearch: boolean,isTrendingProducts:string, searchQuery:string, categoryid:string ) => {
    const isTrendingPath = window.location.pathname.split("/")[2]
    if (isSearch) {
      return `${configJSON.getCategoryListSeachKeywordAPIEndPoint}?search_keyword=${searchQuery || this.props.match.params.type}` 
    }
    if (isTrendingProducts && isTrendingPath ==="trending") {
      return configJSON.getCatListTrendingEndPoint(isTrendingProducts)
    } 
    else return configJSON.getSubCategoryEndPoint(categoryid)
  } 

  brandApiCall = async (catagoryId: any) => {
    const isTrendingProducts = window.location.pathname.split("/")[3]
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    const searchQuery = await getStorageData('searchQuery');
    const brandListEndpoint = this.getBrandEndPoint(isSearch,isTrendingProducts, searchQuery, catagoryId) 
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiBrandCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),brandListEndpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBrandEndPoint = (isSearch: boolean,isTrendingProducts:string, searchQuery:string, catagoryId:string ) => {
    const trendingPath = window.location.pathname.split("/")[2]
    if (isSearch) {
      return `${configJSON.getBrandListSearchKeywordEndPoint}?search_keyword=${searchQuery || this.props.match.params.type}` 
    }
    if (isTrendingProducts && trendingPath === "trending") {
      return configJSON.getBrandListTrendingEndPoint(isTrendingProducts)
    } 
    else return configJSON.getBrandListEndPoint(catagoryId)
  } 

  handleItemPerPage: any = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({
      itemPerPage: value,
    },() => {
      setStorageData("itemPerPage", value)
      this.handleUpdateUrl("itemPerPage", JSON.stringify(value))
      this.handleProductlistContent({ per_page: value ,min_price :this.state.min_price, max_price :this.state.max_price, miniCategoryIds: this.state.miniCategoryIds, microCategoryIds: this.state.microCategoryIds})
    });
  };

  handleShopByBrandFilter = (brandId: string) => {
    setStorageData("brandIds", JSON.stringify([brandId]))
    this.handleUpdateUrl("brandIds", [brandId])
    this.handleProductlistContent({})
    this.setFilterValues({ brandIds: [brandId] })
  }

  setFilterValues = async({ catagoryIds=this.state.catagoryItemsIds, subCatagoryIds = this.state.subCatagoryItemsIds, brandIds = this.state.brandItemsIds,min_price = null, max_price = null,  customFieldValues= this.state.customFieldValues, miniCategoryIds=this.state.miniCatagoryItemsIds, microCategoryIds=this.state.microCatagoryItemsIds }:ProductListArgs) => {
    this.setState({
      subCatagoryItemsIds: subCatagoryIds,
      brandItemsIds: brandIds,
      minRange: min_price ,
      maxRange: max_price ,
      customFieldValues:[...new Set(Object.values(this.state.customFieldValues).flat())],
      microCatagoryItemsIds: microCategoryIds,
      miniCatagoryItemsIds: miniCategoryIds,
      catagoryItemsIds: catagoryIds 
    }, () => {
      if(catagoryIds.length > 0) {
        this.getSubCategories()
      }
      this.getMiniCategories()
      this.getMicroCategories()
      this.handleProductlistContent({ catagoryIds, subCatagoryIds, brandIds ,min_price, max_price, customFieldValues, miniCategoryIds, microCategoryIds}) 
    })
  }

  handleUpdateUrl = async(type: string | any, value: string | string[] | number[] | number | any) => {
    const searchQuery = await getStorageData("searchQuery")
    const { origin, pathname } = window.location;
    const { id } = this.props.match.params;
    const pathParts = pathname.split('/');
    const baseUrl = `${origin}/buyer/categories/${id}/${pathParts[4]}`;
    const isTrendingProducts = pathParts[2]
    const currentParams = new URLSearchParams(window.location.search);    

    if(value && value.length > 0) {
      if (currentParams.has(type)) {
        currentParams.set(type, Array.isArray(value) ? value.join(',') : value);
        } else {
          currentParams.append(type, Array.isArray(value) ? value.join(',') : value);
        }
    }
    else {
      currentParams.delete(type)
    }

      let newUrl;
      if (searchQuery) {
          newUrl = `${origin}/search/${id}/${pathParts[3]}?${currentParams.toString()}`.replace(/\+/g, '_');
      }
      else if (isTrendingProducts === "trending") {
        newUrl = `${origin}/buyer/trending/${pathParts[3]}/products?${currentParams.toString()}`.replace(/\+/g, '_');
      } 
      else {
          newUrl = `${baseUrl}?${currentParams.toString()}`.replace(/\+/g, '_');
      }

      window.history.pushState(null, '', newUrl);
};
  
  handleHome = () => {
    this.props.navigation.navigate('Home')
  }

  handleSortByProductOption = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    const value = event.target.value as string;
    this.setState({sortByProduct: value},() => {
      setStorageData("sortFilter", value);
      this.handleUpdateUrl("sort", value)
      this.handleProductlistContent({ per_page: this.state.itemPerPage ,min_price :this.state.min_price, max_price :this.state.max_price, miniCategoryIds: this.state.miniCategoryIds, microCategoryIds: this.state.microCategoryIds})
    })
  }

  handleCustomFilter = (value: string) => {
    let updatedValue = [...this.state.customFields]
    let valueWithoutSpace = value.replace(/ /g, '_')

    if(updatedValue.includes(valueWithoutSpace)) {
      updatedValue = updatedValue.filter((field) => field !== valueWithoutSpace)
    } else {
      updatedValue = [...updatedValue, valueWithoutSpace]
    }

    this.setState({
      customFields: [...updatedValue]
    }, () => {
      this.handleUpdateUrl("customFilters", [...updatedValue])
      this.handleProductlistContent({ min_price :this.state.min_price, max_price :this.state.max_price})
    })
  }

  handleRatingVlaue = async(value : any) => {
    const baseValue = 5;
    const rating: number[] = value.reduce((acc: number[], item: boolean, index: number) => {
      if (item) {
        acc.push(baseValue - index);
      }
      return acc;
    }, []);
    this.setState({ rating: rating }, () => this.handleUpdateUrl('rating', rating)); 
    
    this.handleProductlistContent({ filter_by_rating: rating });
}

  noProductsFound = () => {
    return this.state.productList.data && this.state.productList.data.length === 0
  }

  renderTrendingProducts = () => {
    const paramsValue = this.props.navigation.getParam("type")
    return (
      paramsValue === "noResult" && 
      this.state.productList.data && this.state.productList.data.length === 0

    )
  }

  handleTrendingProducts = (catalogId: string) => {
    this.props.navigation.navigate("ProductQuickview", { id: catalogId });
  } 

  getTrendingProducts = (sliderValue: number) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    if (sliderValue === 1) {
      this.getProductOneApiCallId = singleMessage.messageId;
    } else {
      this.getProductTwoApiCallId = singleMessage.messageId;
    }
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getTrendingProductApiEndPoint}?slider=${sliderValue}`);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };

  getMiniCategories = () => {
    const subCatQueryString = this.state.subCatagoryItemsIds.map((value: number | string) => `&sub_category_ids[]=${value}`);
    const subCatQuery = subCatQueryString.join("");
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMiniCategoriesApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/list_minicategories_by_subcategory_ids?${subCatQuery}`);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  }

  getMicroCategories = () => {
    const miniCatQueryString = this.state.miniCatagoryItemsIds.map((value: number | string) => `&mini_category_ids[]=${value}`);
    const miniCatQuery = miniCatQueryString.join("");
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getMicroCategoriesApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/list_microcategories_by_minicategory_ids?${miniCatQuery}`);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  }

  getSubCategories = () => {
    const catQueryString = this.state.catagoryItemsIds.map((value: number | string) => `&category_ids[]=${value}`);
    const catQuery = catQueryString.join("");
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSubCategoriesApiCallId = singleMessage.messageId;
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_categories/list_subcategories_by_category_ids?${catQuery}`);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  }

  handleView = () => {
    this.setState({listView: !this.state.listView})
  }

  getProdRoundedCount = () => {
    const count: number = this.state.totalProdCount / this.state.itemPerPage;
    const roundedProdCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedProdCount
  }
  // Customizable Area End
}
