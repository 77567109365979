// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { GetCookies } from "framework/src/WebUtilities";
import Cookies from "js-cookie";
export const configJSON = require("../../src/config");

export interface IOrderData {
  final_price: string | null,
  stroked_price: number | null,
  offer_percentage: number | null
  product_image: string, 
  product_title: string, 
  brand: {
    id: string,
    brand_name: string
  }
}
export interface IShippingAddress {
  attributes: {
    address_1: string;
    address_2: string;
    city: string;
    phone_number: string;
    state: string;
  };
}

export interface IOrderPriceInfo {
  total_fees: string, 
  total_tax: string,
  final_price: string,
  discount: string
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    imgWrap: string;
    overview: string;
    routes: string;
    product: string;
    headerText: string;
    productWrapper: string;
    padding: string;
    buttonWrap: string;
    heading: string;
    headingTwo: string;
    headingThree: string;
    shoppingBtn: string;
    detailsWrapper: string;
    leftContainer: string;
    itemName: string;
    itemColor: string;
    productBrand: string;
    newQuantitySec: string;
    newQuantity: string;
    priceContainer: string;
    priceText: string;
    productContainer: string;
    headerLabel: string;
    horizontalDivider: string;
    textOne: string;
    priceHeaderOne: string;
    priceSec: string;
    bottomSec: string;
    headerDesc: string;
    address: string;
    finalPriceHeader: string,
    containerWidth: string,
    linkHome: string,
    headerLinkSec :string,
    successImg: string,
    textContainer: string,
    priceWrap: string,
    finalPriceGrid: string
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orderItems: IOrderData[];
  shipAddress: IShippingAddress;
  orderPriceInfo: IOrderPriceInfo;
  orderNumber: string;
  itemCount: number | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CheckOutOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCheckOutItemsApiCallId: string = "";
  showOrderApiCallId: string = "";
  updateOrderApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      orderItems: [],
      shipAddress: {
        attributes: {
          address_1: "",
          address_2: "",
          city: "",
          phone_number: "",
          state: "",
        },
      },
      orderPriceInfo: {
        total_fees: '', 
        total_tax: '',
        final_price: '',
        discount: ''
      },
      orderNumber: "",
      itemCount: 0
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getCheckOutOrderItems();
    this.showOrder()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.handleOrderSuccessResponse(responseJson, apiRequestCallId);
      }
    }
  }

  handleOrderSuccessResponse = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (apiRequestCallId === this.getCheckOutItemsApiCallId) {
      this.setState({
        shipAddress: responseJson.data.attributes.shipping_address.data,
        orderItems: responseJson.data.attributes.order_items.data,
        orderPriceInfo: responseJson.data.attributes,
        orderNumber: responseJson.data.attributes.order_number,
        itemCount: 0
      });
    }

    if (apiRequestCallId === this.showOrderApiCallId) {
      if(responseJson.order.status.text === "Paid") {
        this.updateOrderStatus()
      }
    }
  };

  updateOrderStatus = () => { 
    const orderId = GetCookies("currentOrder");
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const httpBody = {
      "orders": {
        "status": "ordered",  
    }
    }
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateOrderApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderId}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePatch
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(message.id, message);
  };

  getCheckOutOrderItems = () => {
    const id = GetCookies("currentOrder");
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const orderMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCheckOutItemsApiCallId = orderMessage.messageId;
    orderMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${id}`
    );
    orderMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    orderMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(orderMessage.id, orderMessage);
  };

  showOrder = () => {
    const id = GetCookies("currentOrder");
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const showOrderMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showOrderApiCallId = showOrderMessage.messageId;
    showOrderMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/telr_payments/show_or_check?order_id=${id}`
    );
    showOrderMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    showOrderMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(showOrderMessage.id, showOrderMessage);
  }

  handleContinueBtn = () => {
    Cookies.remove('currentOrder')
    const msg: Message = new Message(getName(MessageEnum.NavigationHomePageMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidUpdate() {
    window.addEventListener('popstate', () => {
      this.props.navigation.navigate("Home")
  });
  }
  // Customizable Area End
}
