import React from "react";

// Customizable Area Start
import { Box, Button, Grid, IconButton, InputAdornment, Modal, Typography, withStyles, Input, TextField } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { CustomBackButton, CustomDeleteButton, BoxNoScroll, OrangeButton } from "../CustomizedElementStyle";
import { trashImage } from "../assets";
import Visibility from "@material-ui/icons/Visibility";
import { VisibilityOffSvg } from "../../../mobile-account-login/src/CustomElementStyling";
import { errorImage } from "../../../bulkuploading/src/assets";
import Cropper from "react-easy-crop";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Customizable Area End

import SellerMyProfileController, { IFormValues, Props } from "./SellerMyProfileController";
import Close from "@material-ui/icons/Close";
import { Loader2, Loader3 } from "../../../../components/src/Loader.web";

class SellerMyProfile extends SellerMyProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object().shape(
    {
      firstName: Yup.string()
        .required("Seller name is required")
        .matches(/^[a-zA-Z0-9\s]+$/, "Please enter valid seller name")
        .max(25, "First Name must not exceed 25 characters"),
      storeName: Yup.string()
        .matches(/^[a-zA-Z0-9\s&-_]+$/, "Please enter valid store name")
        .required("Store name is required")
        .min(2, "Store name must be 2 characters")
        .max(25, "Store name must not exceed 25 characters"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
        .max(256, "Email must not exceed 256 characters"),
      newPasswordForm: Yup.string().when("currentPassword", {
        is: currentPassword => currentPassword && currentPassword.length > 0,
        then: Yup.string()
          .required("New Password is required")
          .notOneOf([Yup.ref("currentPassword")], "New Password must not match Current Password")
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,32}$/, "Password must have at least one lowercase letter, one uppercase letter, one digit, one special character, and no spaces. It should be between 8 and 32 characters long."),
        otherwise: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,32}$/, "Password must have at least one lowercase letter, one uppercase letter, one digit, one special character, and no spaces. It should be between 8 and 32 characters long.")
      }),
      currentPassword: Yup.string().when("newPasswordForm", {
        is: newPasswordForm => newPasswordForm && newPasswordForm.length > 0,
        then: Yup.string().required("Current Password is required"),
        otherwise: Yup.string()
      })
    },
    [
      ["currentPassword", "newPasswordForm"],
      ["newPasswordForm", "currentPassword"]
    ]
  );

  returnError = (touched:  boolean | undefined, errors: string | undefined) => {
    if (touched && errors) {
      return <Typography data-test-id="errors" style={webStyles.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };

  DeleteVerifyModal = () => {
    return (
      <Modal data-test-id="delete-modal" open={this.state.showVerifyDeleteModal}>
        <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
          <Box sx={{ width: "300px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
            <Box sx={{ marginBottom: "20px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>Are you sure you want to delete your account?</Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <CustomBackButton data-test-id="backRef" onClick={() => this.handleOpenVerifyDeleteModal()}>
                Back
              </CustomBackButton>
              <CustomDeleteButton style={webStyles.deletBtnText} data-test-id="deleteConfirmRef" onClick={this.handleDeleteAccount}>
                Delete
              </CustomDeleteButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  profileSection() {
    const { classes } = this.props;
    const { profilePicture, isPreviewModalOpen } = this.state;

    return (
      <div className={classes.root}>
        <Avatar className={classes.avatar} src={profilePicture as string} />

        <Input
          data-test-id="fileInput"
          value=""
          type="file"
          name="image"
          style={{
            display: "none"
          }}
          inputProps={{
            "aria-label": "upload documents",
            accept: ".png, .jpg, .jpeg",
            ref: this.inputRef
          }}
          onChange={this.onFileChange}
        />
        <IconButton className={classes.editButton} onClick={this.handleEditClick} data-test-id="editButtonRef">
          <EditIcon />
        </IconButton>

        <Modal className={classes.modal} open={this.state.isEditModalOpen} onClose={this.handleEditModalClose}>
          <div className={classes.modalContent}>
            <div className={classes.modalHeader}>
              <Box sx={{ fontSize: "25px", fontWeight: 500, fontFamily: `"Lato", sans-serif` }}>Set profile picture</Box>
              <IconButton data-test-id="undoCrossRef" aria-label="delete" style={{ cursor: "pointer", padding: "7px", zIndex: 9999, marginRight: "-13px" }} onClick={() => this.handleEditModalClose()}>
                <Close style={{ cursor: "pointer" }} />
              </IconButton>
            </div>
            <React.Fragment>
              <div className={classes.cropContainer}>
                {this.state.imageLoader && (
                  <Loader3 loading={this.state.imageLoader} />
                )}
                <Cropper image={this.state.imageSrc as string} crop={this.state.crop} rotation={this.state.rotation} zoom={this.state.zoom} aspect={1} cropShape="round" showGrid={false} onCropChange={this.setCrop} onRotationChange={this.setRotation} onCropComplete={this.onCropComplete} onZoomChange={this.setZoom} />
              </div>
              <div className={classes.controls}>
                <div className={classes.sliderContainer}>
                  <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
                    Zoom
                  </Typography>
                  <Slider value={this.state.zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" classes={{ root: classes.slider }} onChange={(e, zoom) => this.setZoom(zoom as number)} data-test-id="slideZoomRef" />
                </div>
                <div className={classes.sliderContainer}>
                  <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
                    Rotation
                  </Typography>
                  <Slider value={this.state.rotation} min={0} max={360} step={1} aria-labelledby="Rotation" classes={{ root: classes.slider }} onChange={(e, rotation) => this.setRotation(rotation as number)} data-test-id="rotateRef" />
                </div>
              </div>
            </React.Fragment>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }} style={{ gap: "10px" }}>
              <CustomBackButton data-test-id="backPreviewRef" onClick={this.handlePreviewClick}>
                Preview
              </CustomBackButton>
              <OrangeButton data-test-id="saveProfileRef" onClick={this.onSaveProfilePicture}>
                Save
              </OrangeButton>
            </Box>
          </div>
        </Modal>
       
        <Modal className={classes.modal} open={isPreviewModalOpen} onClose={this.handlePreviewModalClose}>
          <div className={classes.modalContent}>
            <div className={classes.modalHeader}>
              <Box sx={{ fontSize: "25px", fontWeight: 500, fontFamily: `"Lato", sans-serif` }}>Preview image</Box>
              <IconButton data-test-id="closePreviewRef" aria-label="delete" style={{ cursor: "pointer", padding: "7px", zIndex: 9999, marginRight: "-13px" }} onClick={() => this.handlePreviewModalClose()}>
                <Close style={{ cursor: "pointer" }} />
              </IconButton>
            </div>

            <div className={classes.imgContainer}>
              <img src={this.getFileToBlob(this.state.croppedImage as File)} alt="Cropped" className={classes.img} data-test-id="imagePresentRef" />
            </div>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }} style={{ gap: "10px" }}>
              <CustomBackButton data-test-id="backCropRef" onClick={this.handlePreviewModalClose}>
                Back
              </CustomBackButton>
              <OrangeButton data-test-id="cropConfirmRef" onClick={this.onSaveProfilePicture}>
                Save
              </OrangeButton>
            </Box>
          </div>
        </Modal>
      </div>
    );
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        {this.DeleteVerifyModal()}
        <ToastContainer />
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 400, fontFamily: `"Lato", sans-serif` }}>My Profile</Box>
        <BoxNoScroll sx={{ paddingBottom: 20, overflow: "auto" }}>
          <Box sx={{ borderRadius: "8px", display: "flex", flexDirection: "column", padding: "30px", overflow: "auto" }} style={{ background: "white" }}>
            <Box sx={{ fontSize: "27px", fontWeight: 600, fontFamily: `"Lato", sans-serif` }}>My Profile</Box>
            <Box sx={{ fontSize: "16px", fontWeight: 700, fontFamily: `"Lato", sans-serif`, padding: "30px 0px 10px 0px" }}>Personal Details</Box>
            <Box sx={{ width: "115px", border: "1px solid #EFEFF4", margin: "0px 0px 30px 0px" }}></Box>
            {this.profileSection()}
            {this.state.loading ? <Loader2 loading={this.state.loading} /> : <></>}
            <Formik
              initialValues={{
                firstName: this.state.full_name,
                storeName: this.state.company_or_store_name,
                email: this.state.email,
                currentPassword: "",
                newPasswordForm: ""
              }}
              enableReinitialize={true}
              validationSchema={(values: IFormValues) => this.validationSchema}
              validateOnMount={true}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: IFormValues) => this.handleUpdateUserProfile(values)}
              data-test-id="formik"
            >
              {({ handleChange, errors, setFieldTouched, touched, handleBlur, values, setErrors }) => (
                <Form translate={undefined} ref={this.formRef}>
                  <Grid container spacing={4} style={{ marginTop: "8px" }}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <label id={"Seller name"} className={this.props.classes.labelHeader} style={{ color: "#33333A" }}>
                        {"Seller Name"}
                      </label>
                      <CustomizeInput
                        data-test-id="sellerNameRef"
                        id="outlined-start-adornment"
                        placeholder={"Enter seller name"}
                        className={this.props.classes.textField}
                        name="firstName"
                        value={values.firstName}
                        onChange={e => {
                          handleChange(e);
                          setFieldTouched("firstName", true, false);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          classes: { root: this.props.classes.textField },
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                      />
                      {this.returnError(touched.firstName, errors.firstName)}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <label id={"Store name"} className={this.props.classes.labelHeader} style={{ color: "#33333A" }}>
                        {"Store Name"}
                      </label>
                      <CustomizeInput
                        data-test-id="storeNameRef"
                        placeholder={"Enter store name"}
                        name="storeName"
                        className={this.props.classes.textField}
                        value={values.storeName}
                        onChange={e => {
                          handleChange(e);
                          setFieldTouched("storeName", true, false);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        InputProps={{
                          classes: { root: this.props.classes.textField },
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                      />
                      {this.returnError(touched.storeName, errors.storeName)}
                    </Grid>

                    <Grid item sm={12} xs={12}>
                      <label id={"Email"} className={this.props.classes.labelHeader} style={{ color: "#33333A" }}>
                        {"Email"}
                      </label>
                      <CustomizeInput
                        data-test-id="emailRef"
                        name="email"
                        value={values.email}
                        className={this.props.classes.textField}
                        fullWidth
                        placeholder={"Enter email"}
                        disabled
                        InputProps={{
                          classes: { root: this.props.classes.textField },
                          startAdornment: <InputAdornment position="start"></InputAdornment>
                        }}
                      />
                    </Grid>
                  </Grid>
                  {this.returnError(touched.email, errors.email)}
                  <Box sx={{ fontSize: "16px", fontWeight: 700, fontFamily: `"Lato", sans-serif`, padding: "30px 0px 10px 0px" }}>Change Password</Box>
                  <Box sx={{ width: "125px", border: "1px solid #EFEFF4", margin: "0px 0 25px" }}></Box>
                  <Grid container spacing={4}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <label id={"Current password"} className={this.props.classes.labelHeader} style={{ color: "#33333A" }}>
                        {"Current Password"}
                      </label>
                      <CustomizeInput
                        name="currentPassword"
                        onChange={e => {
                          handleChange(e);
                          setFieldTouched("currentPassword", true, false);
                        }}
                        onBlur={handleBlur}
                        data-test-id="currentPassRef"
                        className={this.props.classes.textField}
                        id="outlined-start-adornment"
                        autoComplete="new-password"
                        fullWidth
                        placeholder="Current Password"
                        type={this.state.showCurrentPassword ? "text" : "password"}
                        InputProps={{
                          classes: { root: this.props.classes.textField },
                          startAdornment: <InputAdornment position="start"></InputAdornment>,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton data-test-id="showCurrPasswordRef" aria-label="toggle password visibility" onClick={() => this.handleClickShowPassword("currentPassword")}>
                                {this.state.showCurrentPassword ? <Visibility className="rotateIcon" /> : <VisibilityOffSvg />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      {this.returnError(touched.currentPassword, errors.currentPassword)}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <label id={"New password"} className={this.props.classes.labelHeader} style={{ color: "#33333A" }}>
                        {"New Password"}
                      </label>
                      <CustomizeInput
                        name="newPasswordForm"
                        onChange={e => {
                          handleChange(e);
                          setFieldTouched("newPasswordForm", true, false);
                        }}
                        onBlur={handleBlur}
                        autoComplete="new-password"
                        data-test-id="newPassRef"
                        className={this.props.classes.textField}
                        id="outlined-start-adornment"
                        placeholder="New Password"
                        fullWidth
                        type={this.state.showNewPassword ? "text" : "password"}
                        InputProps={{
                          classes: { root: this.props.classes.textField },
                          startAdornment: <InputAdornment position="start"></InputAdornment>,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton data-test-id="showNewPasswordRef" aria-label="toggle password visibility" onClick={() => this.handleClickShowPassword("newPassword")}>
                                {this.state.showNewPassword ? <Visibility className="rotateIcon" /> : <VisibilityOffSvg />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      {this.returnError(touched.newPasswordForm, errors.newPasswordForm)}
                    </Grid>
                  </Grid>
                  <Box sx={{ fontSize: "16px", fontWeight: 700, fontFamily: `"Lato", sans-serif`, padding: "30px 0px 0px" }}>Delete Account</Box>
                  <Box sx={{ width: "110px", border: "1px solid #EFEFF4", marginTop: "10px" }}></Box>
                  <Box>
                    <Button
                      data-test-id="deleteRef"
                      onClick={() => this.handleOpenVerifyDeleteModal()}
                      variant="outlined" 
                      style={{
                        padding: "10px 22px",
                        color: "#EE1010",
                        textTransform: "none",
                        fontFamily: "Lato, sans-serif",
                        lineHeight: '14.4px',
                        width: '163px',
                        whiteSpace:'nowrap',
                        borderRadius: "5px",
                        border: "1.75px solid #EE1010",
                        marginTop: "30px",
                        fontSize: '12px',
                        fontWeight: 400
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                  <Box sx={{ width: "100%", minHeight: 50, display: "flex", alignItems: "center", marginBottom: "5px" }}>
                    {this.state.errorMessages.map((item, index) => {
                      return (
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "no-wrap", alignItems: "center", paddingTop: "10px" }} key={index}>
                          <Box sx={{ marginRight: "10px" }}>
                            <img src={errorImage} />
                          </Box>
                          <Box>
                            <Typography variant="body1" style={{ color: "#D74D4D" }}>
                              {item}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      data-test-id="saveRef"
                      type="submit"
                      style={{
                        textTransform: "none",
                        fontFamily: "'Lato', sans-serif",
                        fontWeight: 700,
                        fontSize: 16,
                        background: "#FF6008",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        padding: "8px 30px"
                      }}
                    >
                      Save & Continue
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area
export const webStyles = {
  errorStyle: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px",
    fontFamily: `"Lato", sans-serif`
  },
  deletBtnText: {
    backgroundColor: '#ff6008'
  }
};
export const styles = {
  cropContainer: {
    position: "relative" as const,
    background: "#333",
    height: 300,
    width: 500,
    "@media (max-width: 600px)": {
      width: "auto",
      height: 200
    }
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16
  },
  controls: {
    padding: 16,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "stretch" as const,
    "@media (min-width: 600px)": { // sm breakpoint
      flexDirection: "row" as const,
      alignItems: "center"
    }
  },
  sliderContainer: {
    display: "flex",
    flex: "1",
    alignItems: "center"
  },
  sliderLabel: {
    "@media (max-width: 600px)": { // xs breakpoint
      minWidth: 65
    }
  },
  slider: {
    padding: "22px 0px",
    marginLeft: 16,
    "@media (min-width: 600px)": { // sm breakpoint
      flexDirection: "row" as const,
      alignItems: "center",
      margin: "0 16px"
    }
  },
  appBar: {
    position: "relative"  as const
  },
  flex: {
    flex: 1
  },
  imgContainer: {
    position: "relative"  as const,
    flex: 1,
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  img: {
    height: "300px",
    borderRadius: "50%"
  },
  root: {
    display: "flex",
    alignItems: "center",
    position: "relative"  as const
  },
  avatar: {
    width: 80,
    height: 80
  },
  editButton: {
    position: "absolute"  as const,
    left: 44,
    top: "-10px",
    color: "#FF6008",
    border: `2px solid #FF6008`,
    borderRadius: "50%",
    padding: 4,
    scale: 0.6,
    background: "antiquewhite",
    "&:hover": {
      backgroundColor: "antiquewhite"
    }
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px"
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px"
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: "16px 25px",
    outline: "none",
    borderRadius: "5px"
  },
  labelHeader: {
    margin: "6px 5px 10px 0",
    width: "100%",
    color: "#666666",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    lineHeight: "14.4px",
    ".MuiInputBase-root": {
      width: "100% !important"
    }
  },
  textField: {
    margin: "6px 5px 0px 0",
    width: "100%",
    color: "#666666 !important",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "AvenirNext-Regular, sans-serif",
    lineHeight: "17px",
    ".MuiInputBase-root": {
      width: "100% !important",
      fontFamily: "AvenirNext-Regular, sans-serif"
    }
  }
};

const CustomizeInput = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      color: "#999999"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #DDE3E8"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInput-formControl": {
      marginRight: 15,
      padding: "10px 0px",
      borderRadius: "5px"
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: '15px'
    },
    "& .MuiInputLabel-root": {
      top: -20,
      left: 0,
      color: "#33333A",
      fontFamily: `"Lato", sans-serif`,
      fontWeight: 600,
      fontSize: 14
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" 
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" 
    }
  }
}))(TextField);

export { SellerMyProfile };
export default withStyles(styles)(SellerMyProfile);
// Customizable Area End
