// Customizable Area Start
import React from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { TextField, withStyles } from '@material-ui/core';
import GooglePlaceAutoCompleteController, {Props} from './GooglePlaceAutoCompleteController.web';
// Customizable Area End

export class GooglePlaceAutoComplete extends GooglePlaceAutoCompleteController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const {
      disabled,
      address,
      placeholder,
      classes
    } = this.props
    const { locationAddress } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <div id="googleMaps">
        <PlacesAutocomplete
          value={locationAddress}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
            
            <div>
              <div>
                <TextField
                  {...getInputProps({
                    placeholder
                  })}
                  className={window.location.pathname.split("/")[1] === "add-address" ? classes.placeTextfield : classes.customGooglePlaceTextfield}
                  value={address || ""}
                  disabled={disabled}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  variant={window.location.pathname.split("/")[1] !== "add-address" ? "outlined" : "standard"}
                  error={this.props.errorFlag}
                  helperText={
                    this.props.errorFlag && "Apartment name/Community " + this.props.inputFieldError["Apartment name/Community"]
                }
                />
              </div>
              {(loading || suggestions.length > 0) && (
                <>
                <div className={classes.dropDown}>
                  <div className={classes.googlemapDropdown}>
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion:{description:string}) => {
                      return (
                        <div
                        data-test-id="search"
                        className={classes.search}
                        onMouseEnter={(event) => (event.currentTarget.style.backgroundColor = '#f0f0f0')}
                        onMouseLeave={(event) => (event.currentTarget.style.backgroundColor = '')}
                          {...getSuggestionItemProps(suggestion, {
                          })}
                        >
                          <span className={this.props.classes.searchText}>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                </>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
export const styles = {
  googlemapDropdown: {
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12) !important", 
    backgroundColor: "#fff",
    position: "absolute"as const,
    top: 0,
    width: "98%",
    color: "#000 !important",
    borderRadius: "4px",
    padding: "8px 6px",
    zIndex: 2,
    overflowY: "auto" as const
  },
  search : {
    padding:'16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor:'#ff6008',
      color: '#fff'
    }
  },
  searchText:{
    fontFamily: "Lato, sans-serif !important"
  },
  dropDown: {
    backgroundColor:'#fff',
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12) !important", 
    position:'relative' as const
  },
  customGooglePlaceTextfield : {
  position:'relative' as const,
    backgroundColor: "#fff !important",
    borderRadius: "5px !important",
    fontFamily: "Lato, sans-serif !important",
    fontSize: "12px !important",
    width: '99%',

    '& input': {
      color: '#666',
      fontFamily: "Lato, sans-serif !important",
      fontSize: "16px !important",
      fontWeight: "400 !important",
    },

    '& input::placeholder': {
        color: "rgb(102, 102, 102) !important",
        fontSize: "14px !important",
        fontFamily: "Lato, sans-serif !important"
    }

  },
  placeTextfield : {
    position:'relative' as const,
      backgroundColor: "#fff !important",
      borderRadius: "5px !important",
      fontFamily: "Lato, sans-serif !important",
      fontSize: "12px !important",
      width: '99%',
  
      '& input': {
        color: '#666',
        fontFamily: "Lato, sans-serif !important",
        fontSize: "16px !important",
        fontWeight: "400 !important",
      },
  
      '& input::placeholder': {
          color: "rgb(102, 102, 102) !important",
          fontSize: "12px !important",
          fontFamily: "Lato, sans-serif !important"
      },
      "& .MuiInput-formControl": {
        border: "1px solid #b1b3b5",
        margin: 0,
        padding: "5px 0px 5px 19px",
        borderRadius: "5px"
      }
  
    }
}

const styledBox = withStyles(styles)(GooglePlaceAutoComplete) 

export default GoogleApiWrapper({
  apiKey: ('AIzaSyDTM9RzL0P4rBXptLIOgfVAQLX92vUnE4c')

})(styledBox)
// Customizable Area End

// Customizable Area End
