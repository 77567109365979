// Customizable Area Start
import React from "react";
import FBBStockMgmtStorageController, { Props, configJSON } from "./FBBStockMgmtStorageController.web";
import { withStyles } from "@material-ui/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import FBBStockMgmtTabs from "./FBBStockMgmtTabs.web";
import CreateStockIntakeRequest from "./CreateStockIntakeRequest.web";
import PrintLabel from "./PrintLabel.web";
import { ToastContainer } from "react-toastify";
// Customizable Area End

class FBBStockManagementStorage extends FBBStockMgmtStorageController {
    constructor(props: Props){
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const {classes} = this.props;
        return(
            <>
            <ToastContainer/>
                {this.state.createReq ? 
                <CreateStockIntakeRequest navigation={this.props.navigation} id={this.props.id}/> :
                <>
                {this.state.isPrintLabel ? 
                <PrintLabel navigation={this.props.navigation} id={this.props.id}/>
                :
                <Box className={classes.mainContainer}>
                    <Box className={classes.headerWrapper}>
                    <Box className={classes.headerText}>{configJSON.fulFilledByEzzyText}</Box>
                    </Box>
                    <Grid container className={classes.container}>
                        <Grid item xs={6} className={classes.orderTextWrapper}>
                            <Box className={classes.ordersText}>{configJSON.stockMgmtStorageText}</Box>
                        </Grid>
                        <Grid item xs={6} className={classes.rightSide}>
                            <Typography className={classes.text}>{configJSON.storageReqText}</Typography>
                            <Box>
                            <Button onClick={this.handleCreateStockReq} className={classes.stockBtn}>{configJSON.createStockReqBtnText}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <FBBStockMgmtTabs data-test-id="tabs" navigation={this.props.navigation} id={this.props.id} handlePrintLabel={this.handlePrintLabel} classes={{
                        storageWrapper: "",
                        tabsLabel: "",
                        storageHeader: "",
                        bckGround: "",
                        spaceWrapper: "",
                        totalStorageText: ""
                    }}   
                    />
                </Box>
                }
                </>
                }
                
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const styles = {
    mainContainer : {
        overflowY: "auto" as const,
        overflowX: "hidden" as const
    },
    headerWrapper : {
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center"
    },
    headerText : {
        padding: "20px 0px 15px 0px", 
        fontSize: "18px", 
        fontWeight: 400 as const, 
        fontFamily: `"Lato", sans-serif` as const
    },
    container : {
        borderRadius: "8px", 
        display: "flex", 
        flexDirection: "row" as const, 
        padding: "30px",  
        position: "relative" as const, 
        background: "white" 
    },
    orderTextWrapper : {
        width: "100%", 
        display: "flex",  
        justifyContent: "space-between", 
        alignItems: "left" as const, 
        marginBottom: 10
    },
    ordersText : {
        fontSize: "24px", 
        fontWeight: 700  as const, 
        fontFamily: `"Lato", sans-serif` as const,
        paddingRight: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text: {
        fontSize: "14px", 
        fontWeight: 500  as const, 
        fontFamily: `"Lato", sans-serif` as const,
        color: '#747474'
    },
    rightSide: {
        display:'flex', 
        flexDirection:'column' as const, 
        alignItems:'flex-end'
    },
    stockBtn : {
        marginTop:'10px',
        fontSize:'16px', 
        fontFamily:`"Lato", sans-serif` as const, 
        fontWeight:500, 
        textTransform:'none'as const, 
        padding:'16px 24px', 
        backgroundColor:'#0fbc9e', 
        borderRadius:'8px', 
        color:'#fff', 
        display:'flex',
        alignItems:'center', 
        justifyContent:'center',
        '&:hover': {
            backgroundColor: 'white',
            color : '#0fbc9e',
            border: '0.5px solid #0fbc9e'
        }
    }
}


export {FBBStockManagementStorage}
export default withStyles(styles)(FBBStockManagementStorage) 
// Customizable Area End