// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
// import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth.web';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading.web';
import SellerFinalUploadRegistration from '../../blocks/bulkuploading/src/SellerFinalUploadRegistration.web';
import HomePage from '../../blocks/landingpage/src/HomePage.web';
import SellerLanding from '../../blocks/landingpage/src/SellerLandingPage.web';
import UserProfile from '../../blocks/user-profile-basic/src/UserProfile.web';
import SellerAccountRegistration from '../../blocks/email-account-registration/src/SellerAccountRegistration.web';
import SellerDashboard from '../../blocks/dashboard/src/SellerDashboard.web';
import SellerSignIn from '../../blocks/mobile-account-login/src/SellerLoginPage.web';
import SellerHomeDashboard from '../../blocks/dashboard/src/SellerHomeDashboard';
import SellerForgotPassword from '../../blocks/forgot-password/src/SellerForgotPassword.web';
import ProductCatalogue from '../../blocks/catalogue/src/BuyerSearchProduct/ProductCatalogue.web';
import BuyerSignup from '../../blocks/email-account-registration/src/BuyerSignup.web';
import HomeContactUs from '../../blocks/contactus/src/HomeContactUs.web';
import StaticPage from '../../blocks/contactus/src/StaticPage.web';
import '../../blocks/dashboard/src/ProductDetails/DatePicker.css';
import { Provider } from 'react-redux';
import { connector } from '../../framework/src/WebUtilities';
import { createStore } from 'redux';
import { combineReducers } from 'redux';
import PhoneVerification from '../../blocks/languagesupport/src/PhoneVerification.web';
// import { ProductQuickview } from '../../blocks/productquickview/src/ProductQuickview.web';
import {styledProductQuickView as ProductQuickview}  from '../../blocks/productquickview/src/ProductQuickview.web';import OrderSummary from '../../blocks/catalogue/src/ShoppingCart/OrderSummary.web';
import ShippingAddress from '../../blocks/catalogue/src/ShoppingCart/ShippingAddress.web';
import CreateShippingAddress from '../../blocks/catalogue/src/ShoppingCart/CreateShippingAddress.web';
import OrderOverView from '../../blocks/catalogue/src/ShoppingCart/OrderOverView.web';
import CheckOutOrder from '../../blocks/catalogue/src/ShoppingCart/CheckOutOrder.web';
import SellerStorefront from '../../blocks/catalogue/src/SellerStorefront.web';
import  MenuBar from '../../blocks/user-profile-basic/src/MenuBar.web';
import FeaturesHeader from '../../blocks/email-account-registration/src/FeaturesHeader.web';
import SellerSidebar from '../../blocks/dashboard/src/SellerSidebar.web';
import SellerMyDocuments from '../../blocks/dashboard/src/SellerDashboardComponents/SellerMyDocumens.web';
import PrivacyNotice from '../../blocks/termsconditions/src/PrivacyNotice.web';
import TermsOfService from '../../blocks/termsconditions/src/TermsOfService.web';
import WeeklyDeals from "../../blocks/catalogue/src/WeeklyDeals.web";
import BrandStore  from '../../blocks/dashboard/src/BrandStore.web';
import Analytics from '../../blocks/analytics/src/Analytics.web';
import SellWithByEzzy from '../../blocks/email-account-registration/src/SellWithByEzzy.web';
import Features from '../../blocks/email-account-registration/src/Features.web';
import Pricing from '../../blocks/email-account-registration/src/Pricing.web';
import Whyus from '../../blocks/email-account-registration/src/Whyus.web';

const rootReducer = combineReducers({});

const store = createStore(rootReducer);

const routeMap = {
  Home: {
    component: connector(HomePage),
    path: '/',
    exact: true,
  },
  Analytics: {
    component: Analytics,
    path: '/analyticss',
    exact: true,
  },
  SellerLanding: {
    component: SellerLanding,
    path: '/seller/landing',
    exact: true,
  },
  Profile: {
    component: MenuBar,
    path: '/my-account/:path',
    exact: true,
    privateRoute: true,
  },
  ShoppingCart: {
    component: OrderSummary,
    path: '/shopping-cart',
    exact: true
  },
  ShippingAddress: {
    component: ShippingAddress,
    path: '/shipping-address',
    exact: true
  },
  AddAddress: {
    component: CreateShippingAddress,
    path: '/add-address/:id',
    exact: true
  },
  OrderOverView : {
    component: OrderOverView,
    path: '/order-summary',
    exact: true
  },
  CheckOutOrder: {
    component: CheckOutOrder,
    path: '/checkout',
    exact: true
  },
  FeaturesHeader: {
    component: FeaturesHeader,
    path: '/seller/register',
    exact: true,
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/seller/otp',
    exact: true,
  },
  SellerFinalUploadRegistration: {
    component: SellerFinalUploadRegistration,
    path: '/seller/register/final',
    exact: true,
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/seller/register/upload',
    exact: true,
  },
  SellerDashboard: {
    component: SellerDashboard,
    path: '/seller/dashboard/:path?',
    exact: true,
    privateRoute: true,
    role: "seller"
  },
  SellerLoginEmail: {
    component: SellerSignIn,
    path: '/seller/login',
    exact: true,
  },
  SellerHomeDashboard: {
    component: SellerHomeDashboard,
    path: '/seller/home/:path?',
    exact: true,
    privateRoute: true,
    role: "seller"
  },
  SellerForgotPassword: {
    component: SellerForgotPassword,
    path: '/seller/forgotpassword',
    exact: true,
  },
  BuyerSignup: {
    component: BuyerSignup,
    path: '/buyer/otp',
    exact: true,
  },
  BuyerForgortPassword: {
    component: SellerForgotPassword,
    path: '/buyer/forgotpassword',
    exact: true,
  },
  BuyerExploreProducts: {
    component: ProductCatalogue,
    path: '/buyer/categories/:id/:type',
    // exact: true,
  },
  BuyerSearchExploreProduct: {
    component: ProductCatalogue,
    path: '/search/:id/:type',
    // exact: true,
  },
  BuyerTrendingProducts: {
    component: ProductCatalogue,
    path: '/buyer/trending/:id/:type',
    // exact: true,
  },
  HomeContactUs: {
    component: HomeContactUs,
    path: '/home/contactus',
    exact: true,
  },
  StaticPage: {
    component: StaticPage,
    path: '/:path',
    exact: true,
  },
  PhoneVerification: {
    component: PhoneVerification,
    path: '/PhoneVerification',
  },
  ProductQuickview: {
    component: ProductQuickview,
    path: '/product/:id',
    exact: true,
  },
  SellerStorefront: {
    component: SellerStorefront,
    path: '/seller-info',
  },
  PrivacyNotice : {
    component: PrivacyNotice,
    path: '/seller/privacy-notice',
    exact: true
  },
  TermsOfService : {
    component: TermsOfService,
    path: '/seller/terms-of-service',
    exact: true
  },
  WeeklyDeals: {
    component: WeeklyDeals,
    path: '/deals/:id',
    exact: true
  },
  BrandProducts: {
    component: WeeklyDeals,
    path: '/brand/:navigationBarTitleText',
    exact: true
  },
  BrandStore: {
    component: BrandStore,
    path: '/store/:id/:menu?/:menuId?',
    exact: true
  },
  SingleProductQuickview: {
    component: ProductQuickview,
    path: '/singleproduct/:navigationBarTitleText',
    exact: true,
  },
  SellWithByEzzy : {
    component: SellWithByEzzy,
    path: '/seller/details/:type',
    exact: true,
  },
  Features: {
    component: Features,
    path: '/seller/features',
    exact: true,
  },
  Whyus: {
    component: Whyus,
    path: '/seller/whyus',
    exact: true,
  },
  Pricing: {
    component: Pricing,
    path: '/seller/pricing',
    exact: true,
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <Provider store={store}>
        <View style={{ height: '100vh', width: '100vw', overflowX: "hidden", overflowY: 'inherit' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          {/* <ModalContainer /> */}
        </View>
      </Provider>
    );
  }
}

export default App;
