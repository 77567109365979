import { Box, Typography, Button, Theme, withStyles, Grid, Modal, Select, Divider, InputAdornment, } from "@material-ui/core";
import React from "react";
import UserOrderHistory2Controller, { OrderData, Props, configJSON } from "./UserOrderHistory2Controller";
import {styledProductReviews as AddProductReviews} from "../../reviews/src/AddProductReviews.web";
import moment from "moment";
import { Loader2 } from "../../../components/src/Loader.web";
import Pagination from '@material-ui/lab/Pagination';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaginationItem from '@material-ui/lab/PaginationItem';
import { CustomBackButton, CustomDeleteButton, CustomizeBrandInput, FormControlTwo, MenuItemTwo, OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
 class UserOrderHistory2 extends UserOrderHistory2Controller {
    isValid: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    trackOrderModal = () => {
        const {classes} = this.props;
        return (
            <Modal data-test-id="trackOrderModal" open={this.state.trackOrderModal}>
                <Box className={classes.trackBox}>
                <Grid container className={classes.gridModal}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.flex}>
                        <Grid container className={classes.rowWrapper}>
                            <Grid item xs={6} sm={6} md={6} lg={6} style={webStyle.textWrapper}>
                                <Typography className={classes.trackingTitle}>{configJSON.courierName} </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} style={webStyle.textWrapper}>
                                <Typography className={classes.trackingNumber}>{this.state.trackOrderDetails.courierName}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.flex}>
                    <Grid container className={classes.rowWrapper}>
                            <Grid item xs={6} sm={6} md={6} lg={6} style={webStyle.textWrapper}>
                                <Typography className={classes.trackingTitle}>{configJSON.trackingNumberText}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} style={webStyle.textWrapper}>
                                <Typography className={classes.trackingNumber}>{this.state.trackOrderDetails.trackingNumber}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.okBtnWrapper}>
                        <OrangeButton data-test-id="okBtn" className={classes.okBtn} onClick={this.handleCloseTrackOrder}>Ok</OrangeButton>
                    </Grid>
                </Grid>
                </Box>
            </Modal>
        )
    }

    qcFailedModal = () => {
        const {classes} = this.props;
        return (
            <Modal data-test-id="qcFailedModal" open={this.state.showQcModal}>
                <Box className={classes.trackBox}>
                <Grid container className={classes.gridModal}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.flex}>
                        <Grid container className={classes.rowWrapperQC}>
                            <Grid item xs={6} sm={6} md={6} lg={6} style={webStyle.textWrapper}>
                                <Typography className={classes.trackingTitle}>{configJSON.rejectedReason} </Typography>
                            </Grid>
                            <Grid item style={webStyle.textWrapper}>
                                <Typography className={classes.trackingNumber}>{this.state.qcFailedReason}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.textWrapper}>
                        <Typography style={webStyle.staticMessage}>
                        In case of any query, please write us at <strong>Care@Byezzy.com</strong>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} style={webStyle.okBtnWrapper}>
                        <OrangeButton data-test-id="okBtnQCModal" className={classes.okBtn} onClick={this.closeQcModal}>Ok</OrangeButton>
                    </Grid>
                </Grid>
                </Box>
            </Modal>
        )
    }

    renderPagination = (paginationCurrentPage: number) => {
        return(
        this.state.historyList.length === 0  ?
        <Box style={{height:'300px', textAlign:'center',display:'flex'}}>
          <Typography style={webStyle.desc}>No Order History found</Typography>
        </Box>
        :

            <Box mt="16px" display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Pagination
                    variant="outlined"
                    shape="rounded"
                    onChange={this.handleOrderHistoryPageChange}
                    count={this.state.orderHistoryPagination.total_pages}
                    page={this.state.orderHistoryPagination.current_page}
                    classes={{ ul: this.props.classes.paginationUl }}
                    renderItem={(item) => {
                        if (item.type === 'previous') {
                            return <Button data-test-id="previous" className={this.props.classes.paginationButton} disabled={this.state.orderHistoryPagination.current_page === 1} onClick={() => this.handlePaginationButton(paginationCurrentPage - 1)}>Previous</Button>
                        }
                        if (item.type === 'next') {
                            return <Button data-test-id="next" className={this.props.classes.paginationButton} disabled={this.state.orderHistoryPagination.current_page === this.state.orderHistoryPagination.total_pages} onClick={() => this.handlePaginationButton(paginationCurrentPage + 1)}>Next</Button>
                        }
                        return <PaginationItem {...item} />
                    }}
                />
            </Box>
          
        ) 
    }

    renderReturnButton = (returnOrderStatus:string, orderStatus:string, itemId:string, orderNumber: string) => {
        return(
            !returnOrderStatus && orderStatus === "Delivered" &&
                <Button
                    data-test-id="returnItem"
                    style={{
                        textTransform: "none",
                        fontFamily: "Lato, sans-serif",
                        fontWeight: 500,
                        fontSize: 14,
                        background: "white",
                        color: "black",
                        alignItems: "center",
                        borderRadius: "8px",
                        border: "1px solid #E9E9E9",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        height: "35px",
                        minWidth: "160px",
                        width: "100%",
                    }}
                    onClick={() => this.openReturnOrderModal(itemId, orderNumber)}>
                    Return Item
                </Button>
        )
    }

    renderTrackOrderBtn = (shippingDetails:any) => {
        const {classes} = this.props;
        return(
        <Button
            data-test-id="saveRef"
            disabled={shippingDetails.length === 0}
            onClick={() => this.handleTrackOrder(shippingDetails)}
            className={shippingDetails.length > 0 ? classes.trackOrderBtn : classes.greyTrackOrder}>
            Track Order
        </Button>
        )
    }

     setReturnOrderStatus = (status: string, returnReason: {details: string, title:string}[]) => {
         const returnStatus = configJSON.returnStatusList
         const isReturnExists = returnStatus.includes(status)
         return (
             isReturnExists && this.state.displayOrder !== "delivered" ?
                 <Typography data-test-id="returnStatus" style={{ fontFamily: "Lato, sans-serif", color: "#FF6008", fontWeight: 600 }} >
                     RETURN STATUS
                     <span
                         id="qcFailed"
                         data-test-id="qc_failed"
                         onClick={() => status === configJSON.qcFailed && this.handleQcModal(returnReason)}
                         style={{ cursor: this.handleOrderStatusCursor(status), color: status === configJSON.qcFailed ? '#3f51b5': '#000', fontWeight: 400 }}  >{this.setReturnStatus(status)}
                     </span>
                 </Typography>
                 : ''
         )
     }
   
    personalDetails() {
        const paginationCurrentPage = this.state.orderHistoryPagination.current_page || 1
        const {classes} = this.props

        return(
            <Box>
                <Box style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", margin: "10px" }}>
                    <Box>
                        <Typography ref={this.boxRef} variant="h4" style={webStyle.headerTxt}>Order History</Typography>
                    </Box>
                    <Box className={classes.container}>
                        <FormControlTwo className={classes.formWidth}>
                            <Typography className={classes.sortText}>{configJSON.StatusText}</Typography>
                            <Select
                                className={classes.filterBackgrnd}
                                data-test-id="selectStatus"
                                defaultValue="50"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.displayOrder}
                                onChange={this.handleDisplayOrder}
                                inputProps={{
                                    classes: {
                                        select: classes.sortDisplay,
                                    },
                                }}
                                IconComponent={CustomWishlistExpandMoreIcon}
                                MenuProps={{
                                    transformOrigin: {
                                        horizontal: "left",
                                        vertical: "top"
                                    },
                                    anchorOrigin: {
                                        horizontal: "left",
                                        vertical: "bottom"
                                    },
                                    PaperProps: {
                                        style: {
                                            boxShadow: "none",
                                            borderRadius: 5,
                                            border: "1px solid #b1b3b5",
                                            padding: 0,
                                            marginTop: "5px",
                                            background: "white",
                                            overflowY: "auto",
                                            maxHeight: "400px",
                                        }
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItemTwo value="all">All</MenuItemTwo>
                                <MenuItemTwo value="delivered">Delivered</MenuItemTwo>
                                <MenuItemTwo value="returned">Returned</MenuItemTwo>
                            </Select>
                        </FormControlTwo>
                    </Box>
                </Box>
                <Divider className={classes.divider} />

            {this.state.loading ?
            (<Box sx={webStyle.loaderWrapper}>
                <Loader2 loading={this.state.loading}/>
            </Box>): <>
            
            <Grid container style={{maxHeight:1086, overflowY:'scroll', margin:'20px 10px 0 10px'}}>
                <Grid item xs={12} sm={12} md={12} lg={11} >
            {  
          this.state.historyList && this.state.historyList?.map((item:OrderData, index:number) => {
            const orderStatus =item.order_details.order_status?.data?.attributes?.name 
            const returnOrderStatus =item.order_details.order_item_details.order_status?.data?.attributes?.name ;
            const returnReason = item.order_details.return_reason_details
            const productId = item.order_details.order_item_details?.catalogue?.data.id;
            const productDetails = item.order_details.order_item_details?.catalogue?.data.attributes;
            const shippingDetails = item.order_details.shipped_order_details
            return (
                  <Box key={index} className={classes.historyBox}>
                      <Box style={{ display: "flex", justifyContent: "space-between", gap: 15 }} className={this.props.classes.historyContainer}>
                          <Box style={{ display: "flex", gap: 20,fontFamily: "Lato, sans-serif"}} className="innerSection">
                              <Box data-testId="order_product_img" className={classes.imageBox}  onClick={() => this.handleQuickProductView(productId)}>
                                  <img src={productDetails.product_image} alt="img" style={{width:'inherit', height: "100%", borderRadius: "5px", margin: "auto",fontFamily: "Lato, sans-serif" }}></img>
                              </Box>
                              <Box>
                                  <Typography variant="h5" data-testId="order_product_name" className={this.props.classes.lineEcllipse}  onClick={() => this.handleQuickProductView(productId)}>{productDetails.product_title}</Typography>
                                  {orderStatus==='Delivered' ? 
                                  <Typography data-testId="deliveredAddress" style={{fontFamily: "Lato, sans-serif", color: "#757575", fontWeight: 600, paddingTop: "10px" }}>Delivered to: <span style={{ color: "black", fontWeight: 400, wordBreak:'break-all' }}>{item.order_details?.shipping_address?.data?.attributes?.address_1 || ""}</span></Typography> :
                                  <Typography data-testId="shippedAddress" style={{fontFamily: "Lato, sans-serif", color: "#757575", fontWeight: 600, paddingTop: "10px" }}>Shipped to: <span style={{ color: "black", fontWeight: 400, wordBreak:'break-all' }}>{item.order_details?.shipping_address?.data?.attributes?.address_1 || ""}</span></Typography>
                                }
                              </Box>
                          </Box>
                          <Box style={{minWidth:'20%'}}>
                              <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", paddingBottom: "20px", fontWeight: 600 }}>ORDER # <span style={{ color: 'black', fontWeight: 400 }}>{item.order_details?.order_number}</span></Typography>
                              <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", paddingBottom: "20px", fontWeight: 600 }}>ORDER PLACED <span style={{ color: 'black', fontWeight: 400 }}>{moment(item.order_details?.order_placed_at).format("D MMMM YYYY")}</span></Typography>
                              <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", paddingBottom: "20px", fontWeight: 600 }}>TOTAL <span style={{ color: 'black', fontWeight: 400 }}>AED {item.order_details?.order_item_details?.price}</span></Typography>
                              {(!returnOrderStatus || this.state.displayOrder === "delivered") && orderStatus ==='Delivered' && item.order_details?.delivered_at && <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", fontWeight: 800 }}>DELIVERED <span style={{ color: 'black', fontWeight: 400 }}>{moment(item.order_details.delivered_at).format("D MMMM YYYY")}</span></Typography>}
                              {!returnOrderStatus && orderStatus && orderStatus!=='Delivered' && <Typography style={{fontFamily: "Lato, sans-serif", color: "#FF6008", fontWeight: 600 }}>ORDER STATUS <span style={{ color: 'black', fontWeight: 400 }} >{`${ orderStatus}`}</span></Typography>}
                              {this.setReturnOrderStatus(returnOrderStatus, returnReason)}
                          </Box>
                      </Box>
                      <Box style={{ fontFamily: "Lato, sans-serif", marginTop: "5%",  display: "flex", justifyContent: "space-between",gap:15 }} className={this.props.classes.historyContainer}>
                      <Box style={{ display: "flex", gap: 20,fontFamily: "Lato, sans-serif"}} className="innerSection"> 
                    {this.renderTrackOrderBtn(shippingDetails)}
                          
                          {this.renderReturnButton(returnOrderStatus, orderStatus,item.order_details.order_item_details.id as string, item.order_details.order_number)}
                          {orderStatus==='Delivered' && 
                         <>
                          <Button
                              id="reviews-page"
                              data-test-id="writeRef"
                              style={{
                                  textTransform: "none",
                                  fontFamily: "Lato, sans-serif",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  background: "white",
                                  color: "black",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  border: "1px solid #E9E9E9",                            
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              height: "35px",
                              minWidth: "160px",
                              width: "100%",
                          }}
                          onClick={() => this.handleWriteAReview(item.order_details?.order_item_details,item.order_details?.order_number, moment(item.order_details?.order_placed_at).format("D MMMM YYYY"),productDetails.product_title, productDetails.product_image, item.order_details?.shipping_address?.data?.attributes?.address_1)}>
                              Write a Review
                          </Button>
                          <Button
                              data-test-id="invoiceRef"
                              style={{
                                  textTransform: "none",
                                  fontFamily: "Lato, sans-serif",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  background: "white",
                                  color: "black",
                                  alignItems: "center",
                                  borderRadius: "8px",
                                  border: "1px solid #E9E9E9",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  height: "35px",
                                  minWidth: "160px",
                                  width: "100%",
                              }}
                              onClick={() => this.handleDownloadOrderInvoice(item.order_details.id,item.order_details.order_item_details.id as number)}
                              >
                              Invoice
                          </Button> 
                          </>}
                          </Box>
                          <Box style={{minWidth:'20%'}}></Box>
                      </Box>
                  </Box>
  
              )})}
              </Grid>
            </Grid>
            {this.renderPagination(paginationCurrentPage)} 
         </>}
         </Box>  
 )}

     returnOrderModal() {
        const {classes} = this.props

         return (
             <Modal open={this.state.showReturnOrderModal}>
                 <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
                     <Box sx={{ width: "440px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
                         <Box sx={{ marginBottom: "20px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>Why are you returning this?</Box>
                         <Box sx={{ width: "100%", marginBottom: "30px" }}>
                             <Box sx={{ marginBottom: "5px", fontFamily: `"Lato", sans-serif` }}>Reason</Box>
                             <Select
                                 className={classes.reasonBackground}
                                 data-test-id="selectReason"
                                 defaultValue="50"
                                 labelId="demo-simple-select-outlined-label"
                                 id="demo-simple-select-outlined"
                                 value={this.state.returnReason}
                                 onChange={this.handleReturnReason}
                                 inputProps={{
                                     classes: {
                                         select: classes.returnReasonDisplay,
                                     },
                                 }}
                                 IconComponent={CustomWishlistExpandMoreIcon}
                                 MenuProps={{
                                     transformOrigin: {
                                         horizontal: "left",
                                         vertical: "top"
                                     },
                                     anchorOrigin: {
                                         horizontal: "left",
                                         vertical: "bottom"
                                     },
                                     PaperProps: {
                                         style: {
                                             boxShadow: "none",
                                             borderRadius: 5,
                                             border: "1px solid #b1b3b5",
                                             padding: 0,
                                             marginTop: "5px",
                                             background: "white",
                                             overflowY: "auto",
                                             maxHeight: "400px",
                                         }
                                     },
                                     getContentAnchorEl: null,
                                 }}
                             >
                                 {configJSON.returnReasons.map((reason: string) => {
                                     return <MenuItemTwo value={reason} key={reason}>{reason}</MenuItemTwo>
                                 })
                                 }
                             </Select>
                         </Box>
                         {this.state.showOtherReasonField && <Box sx={{ width: "100%", marginBottom: "30px" }}>
                             <Box sx={{ marginBottom: "5px", fontFamily: `"Lato", sans-serif` }}>Comments (Optional)</Box>
                             <CustomizeBrandInput
                                 name="description"
                                 value={this.state.otherReason}
                                 onChange={this.handleOtherReason}
                                 data-test-id="descriptionRef"
                                 label={""}
                                 placeholder={configJSON.describeInDetailsText}
                                 multiline
                                 fullWidth
                                 minRows={4}
                                 InputProps={{
                                     startAdornment: <InputAdornment position="start" style={{ marginRight: 5 }}></InputAdornment>
                                 }}
                             />
                             <Typography id="maxLimit" style={{textAlign: "end", fontFamily: "Lato, sans-serif", marginTop: "2px"}}>{(this.state.otherReason).trim().length}/{configJSON.maxLimitOtherReason}</Typography>
                         </Box>}
                         <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: '15px' }}>
                             <CustomBackButton data-testid="backRef" onClick={() => this.handleOpenReturnOrderModal()}>
                                 Cancel
                             </CustomBackButton>
                             <CustomDeleteButton disabled={this.state.apiLoading || !this.state.returnReason} data-testid="deleteConfirmRef" onClick={this.handleReturnItem}>
                                 Return
                             </CustomDeleteButton>
                         </Box>
                     </Box>
                 </Box>
             </Modal>
         )
     }

    // Customizable Area End

    // Customizable Area Start
    render() {
        return (     
                this.isReview() && this.state.showAddReviews ?
                <AddProductReviews data-test-id="review" navigation={this.props.navigation} id={"fgdfg"} showReview={this.handleShowReview}></AddProductReviews>
                : 
                    <>
                    {this.trackOrderModal()}
                    {this.qcFailedModal()}
                    {this.personalDetails()}
                    {this.returnOrderModal()}
                    </>  
           );
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End

const webStyle = {

    horizontalBar: {
        width: "150px",
        height: "2px",
        marginBlock: "10px",
        fontFamily: "Lato, sans-serif"
    },
    mainWrapper: {

        background: '#eae9e9',
        padding: "20px",
        fontFamily: "Lato, sans-serif"
    },
    papperClasses: {
        background: '#fff',
        padding: "20px 15px",
        borderRadius: "15px",
        fontFamily: "Lato, sans-serif"
    },
    subHeaders: {
        color: "rgb(36, 35, 35)",
        fontWeight: 600,
        fontSize: "20px",
        fontFamily: "Lato, sans-serif"
    },
    desc: {
        color: "#565656",
        marginBlock: "15px",
        fontWeight: 700,
        fontFamily: "Lato, sans-serif",
        margin: 'auto',
        fontSize: "20px"
    },
       headerTxt: {
        color: "rgb(36, 35, 35)",
        fontWeight: 600,
        fontSize: "2rem",
        fontFamily: "Lato, sans-serif"
    },
    loaderWrapper: {
        position: 'relative',
        width: '50px',
        left: '40%',
        top: '80%',
        marginTop: '60px'
    },
    flex: {
        display:'flex', 
        alignItems:'center'
    },
    textWrapper: { 
        display: "flex",
        justifyContent:'flex-start'
    },
    okBtnWrapper: {
        display:'flex', 
        alignItems:'center', 
        justifyContent:'center'
    },
    staticMessage: {
        fontFamily: "Lato, sans-serif",
        fontSize: "14px",
        color: "#999",
        marginTop: "10px",
        textAlign: "center" as const,
      },  
}

const styles = (theme: Theme) =>({
    paginationUl: {
        [theme.breakpoints.down('xs')] :{
            gap: '12px'
        },
        '& .MuiPaginationItem-root': {
          borderRadius: '4px',
          fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, Courier New,monospace',
          border: '1px solid #000',
          fontSize: 18,
          color: '#000',
          '&.Mui-selected': {
            backgroundColor: '#ff5722',
            color: '#fff',
            borderColor: '#ff5722',
          }
        },
        '& .Mui-disabled': {
            border: '1px solid #d9d9d9'
        }
      },
      paginationButton: {
        minWidth: '120px',
        height: '33px',
        margin: '0 14px',
        border: '1px solid gray',
        textTransform: 'none' as const,
        padding: 14,
        fontSize: 16
      },
    historyContainer: {
        flexWrap: 'nowrap',
        [theme.breakpoints.down("md")]: {
          flexWrap: 'wrap',
        },
        [theme.breakpoints.down("xs")]: {
          flexWrap: 'wrap',
        },
        [theme.breakpoints.down("sm")]: {
            flexWrap: 'wrap',
      },
      '& .innerSection':{
        maxWidth: '70%' ,
        [theme.breakpoints.down("md")]: {
            maxWidth: '65%',
            flexDirection: "column" as const
          },
        [theme.breakpoints.down("xs")]: {
            width : '100%',
            flexDirection: "column" as const
          }
      }
    } as any
    ,
    lineEcllipse:{
        maxHeight: 'calc(3 * 1.3em)',
        cursor: "pointer" as const,
        overflow: 'hidden' as const,
        textOverflow: 'ellipsis' as const,
        whiteSpace: 'normal' as const,
        fontFamily: "Lato, sans-serif", 
        fontWeight: 700,
        display: '-webkit-box' as const,
        WebkitLineClamp: 3 as const, 
        wordBreak: "break-all" as const,
        WebkitBoxOrient: 'vertical' as const,
    },
    historyBox: { 
        border: "2px solid rgb(234, 233, 233)", 
        borderRadius: "5px", 
        height: "unset", 
        marginBlock: "10px", 
        padding: "30px", 
        marginTop: "40px",
        fontFamily: "Lato, sans-serif" 
    },
    imageBox: { 
        display: "flex",
        cursor: "pointer", 
        background: "#E7E7E7", 
        width: "140px",
        borderRadius: "5px", 
        height: "140px", 
        padding: "10px",
        fontFamily: "Lato, sans-serif"
    },
    greyTrackOrder : {
        textTransform: "none" as const,
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        fontSize: 14,
        whiteSpace: "nowrap" as const,
        overflow: "hidden" as const,
        textOverflow: "ellipsis" as const,
        cursor:'default' as const,
        background: "grey",
        color: "white !important",
        alignItems: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        height: "35px",
        minWidth: "160px",
        width: "100%",
        '&:hover': {
            background: "grey",
            color: "white !important",
        }
    },
    trackOrderBtn : {
        textTransform: "none" as const,
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        fontSize: 14,
        background: "white",
        color: "black",
        alignItems: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        whiteSpace: "nowrap" as const,
        overflow: "hidden" as const,
        textOverflow: "ellipsis" as const,
        height: "35px",
        minWidth: "160px",
        width: "100%",
        '&:hover': {
            background: "white",
            color: "black",
        }
    },
    trackingTitle: {
        fontFamily: "Lato, sans-serif", 
        fontWeight: 600,
        fontSize:'16px'
    },
    trackingNumber: {
        fontFamily: "Lato, sans-serif", 
        fontWeight: 400,
        fontSize:'16px',
        wordBreak:'break-all' as const
    },
    rowWrapper: {
        display:'flex',
        alignItems:'baseline',
        justifyContent: 'space-between'
    },
    rowWrapperQC: {
        display:'flex',
        flexDirection: 'column' as const,
        alignItems:'baseline',
    },
    okBtn: {
        padding:'4px 20px',
        marginTop:'16px',
        fontFamily: "Lato, sans-serif", 
        fontWeight: 600,
        borderRadius:'6px'
    },
    gridModal : {
        width:'350px',
        display: "flex", 
        flexDirection: "column" as const, 
        justifyContent: "center", 
        borderRadius: "8px", 
        padding:'30px', 
        backgroundColor: "rgb(255,255,255)",
        '@media (max-width: 600px)': {
            width: '320px',
            padding:'20px'
        }
    },
    trackBox: {
        width: "100%", 
        height: "100vh", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center " ,
        background: "rgba(0,0,0,0.1)" 
    },
    container: { 
        fontFamily: "Lato, sans-serif", 
        display: "flex", 
        gap: "5px", 
        flexDirection: "column" as const ,
        width:'200px',
        maxWidth: '200px'
    },
    sortText: { 
        fontFamily: "Lato, sans-serif", 
        color: "#4a4a4a" ,
        marginBottom:'6px',
        fontWeight:600
    },
    formWidth:{
        width:'100%'
    },
    filterBackgrnd: {
        background:"#eee"
    },
    reasonBackground: {
        width: "100%",
        height: "40px",
        padding: "10px",
        border: "1px solid #b1b3b5",
        borderRadius: "5px",
        fontSize: 12,
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 700,
    },
    sortDisplay: {
        fontSize: "16px !important",
        fontFamily: `"Avenir-Medium", sans-serif !important` as const,
        color: "#657A8E !important",
        fontWeight: 400,
    },
    returnReasonDisplay: {
        fontSize: "14px !important",
        fontFamily: `"Lato", sans-serif`,
        fontWeight: 400,
    },
    divider: { 
        fontFamily: "Lato, sans-serif", 
        height: "1px", 
        margin: "10px", 
        marginBottom: '20px' 
    },   
});

const CustomWishlistExpandMoreIcon = withStyles({
    root: {
      fill: "#8a919a", 
      marginRight: 3
    }
  })(ExpandMoreIcon);

export { UserOrderHistory2 };
export default withStyles(styles)(UserOrderHistory2) as any;