import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, Select, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, styled, withStyles, FormControl } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "../../catalogue/src/calendar.css";
import { styles } from "../../catalogue/src/SellerPartnerOrderManagement.web";
import { CustomAnalyticsExpandMoreIcon, MenuItemTwo } from "../../dashboard/src/CustomizedElementStyle";
import { greyCalendar, salesIcon } from "./assets";
import ReportsTruncatedTextwithTooltip from "../../../components/src/CommonComponents/ReportsTruncatedTextwithTooltip.web";
// Customizable Area End

// Customizable Area Start
import AnalyticsController, {
  IReturnReport,
  ISales,
  ISalesTraffic,
  Props,
  configJSON
} from "./AnalyticsController.web";
import { CustomOrangeButton } from "../../../components/src/CommonButton";
import { Pagination } from "@material-ui/lab";
// Customizable Area End

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderPagination = (reports: ISales[] | ISalesTraffic[] | IReturnReport[]) => {
    const setCount = this.getReportsRoundedCount()
    return(
      reports.length > 0 &&
        <Pagination
          style={webStyle.pagination}
          page={this.state.pageNumber}
          data-test-id="reportPagination"
          count={setCount}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            this.setState({ pageNumber: value }, () => this.getReports());
          }}
          shape="rounded"
          siblingCount={0}
          boundaryCount={1}
        />
    )
  }

  renderSalesList =() => {
    return(
      this.state.reportType === configJSON.salesReportText && this.state.salesList.length > 0 &&
      <>
        <TableContainer style={webStyle.tableContainer}>
        <Table component={Paper}>
          <TableHead>
            <TableRow style={webStyle.salesReportTableRow}>
              <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.dateText}</Typography>
                <img style={{...webStyle.salesIcon}} data-test-id="salesDate" onClick={() => this.handleSort("date")} src={this.getIconSrc("date")} alt="salesIcon" />
                </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.orderedProdText}</Typography>
                <img style={webStyle.salesIcon} data-test-id="salesorderedProdSales" src={this.getIconSrc("ordered_product_sales")} onClick={() => this.handleSort("ordered_product_sales")} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.unitsOrdered}</Typography>
                <img style={webStyle.salesIcon} data-test-id="salesUnitsOrdered" src={this.getIconSrc("units_ordered")} onClick={() => this.handleSort("units_ordered")} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.totalOrderItem}</Typography>
                <img style={webStyle.salesIcon} data-test-id="salesTotalOrderItem" src={this.getIconSrc("total_order_item")} onClick={() => this.handleSort("total_order_item")} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={{...webStyle.salesHeader}}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.averageSalesPerOrderItem}</Typography>
                <img style={webStyle.salesIcon} data-test-id="salesAvgSalePerOrderItem" src={this.getIconSrc("average_sales_per_order_item")} onClick={() => this.handleSort("average_sales_per_order_item")} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={{...webStyle.salesHeader}}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.averageUnitsPerOrderItem}</Typography>
                <img style={webStyle.salesIcon} data-test-id="salesAvgUnitPerOrderItem" src={this.getIconSrc("average_units_per_order_item")} onClick={() => this.handleSort("average_units_per_order_item")} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.averageSellingPrice}</Typography>
                <img style={webStyle.salesIcon} data-test-id="salesAvgSellingPrice" src={this.getIconSrc("average_selling_price")} onClick={() => this.handleSort("average_selling_price")} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.sessionsTotal}</Typography>
                <img style={webStyle.salesIcon} data-test-id="salesOrderItemSess" src={this.getIconSrc("order_item_sessions_total")} onClick={() => this.handleSort("order_item_sessions_total")} alt="salesIcon" />
                </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={{...webStyle.salesHeaderText, ...webStyle.orderItemSessionPer}}>{configJSON.saleReportHeaders.orderItemSessionPer}</Typography>
                <img style={webStyle.salesIcon} data-test-id="salesOrderItemSesPer" src={this.getIconSrc("order_item_session_percentage")} alt="salesIcon" onClick={() => this.handleSort("order_item_session_percentage")} />
              </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={webStyle.bottomMargin}>
              {this.state.salesList.length > 0 &&
                this.state.salesList.map((item:ISales, index: number) => {
                  const { date, ordered_product_sales, units_ordered, total_order_item, average_sales_per_order_item, average_units_per_order_item, average_selling_price, order_item_sessions_total, order_item_session_percentage } = item
                  return (
                    <>
                    <TableRow data-test-id="saleslist" key={index} style={{...webStyle.salesReportTableRow,backgroundColor: '#fff',padding:'10px 0', borderBottom: '1px solid rgb(224, 224, 224)'}}>
                      <TableCell align="left" style={{...webStyle.salesTableCell, paddingLeft:'16px'}}>{this.formatDate(date)}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell, ...webStyle.prodSales}}>AED {ordered_product_sales}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{units_ordered}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{total_order_item}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {average_sales_per_order_item}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{average_units_per_order_item}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {average_selling_price}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{order_item_sessions_total}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{order_item_session_percentage}%</TableCell>
                    </TableRow>
                    </>
                  );
                })
              }
            </TableBody>
          </Table>
          </TableContainer>
          {this.renderPagination(this.state.salesList)}
        </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
        <Box style={styles.contentWrapper}>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography style={webStyle.tabName}>
              {configJSON.analyticsReportsTabText}
            </Typography>
          </Grid>
          <Grid container style={webStyle.reportContainer}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={styles.orderTextWrapper}>
              <Box style={webStyle.reportsHeading}>
                {configJSON.reportsText}
              </Box>
            </Grid>
            <Grid container style={webStyle.fieldContainer} spacing={2}>
              <Grid item xs={12} sm={12} md={2} lg={2} style={{marginTop:'-2px'}}>
              <CustomFormControlTwo fullWidth={true}>
                  <Select
                    data-test-id="typeOfReport"
                    name="selectedReport"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    style={{padding:'6px 0 6px 0', border: '1px solid #9a9a9a', ...webStyle.textOutline}}
                    inputProps={{
                      'aria-label': 'Without label',
                      classes: {
                        select: webStyle.select 
                      }
                    }}
                    value={this.state.reportType}
                    onChange={this.handleChangeReportType}
                    IconComponent={CustomAnalyticsExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: webStyle.selectField,
                      },
                    }}
                    >
                    <MenuItemTwo value={"Sales report"} style={webStyle.menuStyle}>{"Sales report"}</MenuItemTwo>
                    <MenuItemTwo value={"Sales & traffic report"} style={webStyle.menuStyle}>{"Sales & traffic report"}</MenuItemTwo>
                    <MenuItemTwo value={"Return report"} style={webStyle.menuStyle}>{"Return report"}</MenuItemTwo>
                    <MenuItemTwo value={"Compare Sales"} style={webStyle.menuStyle}>{"Compare Sales"}</MenuItemTwo>
                  </Select>
                </CustomFormControlTwo>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} style={{marginTop:'-8px'}}>
              <div className="DateContainers" style={{borderRadius:'8px'}}>
                <div className="dateInput" style={{padding:'0px'}}>
                  {" "}
                  <DatePicker
                    data-test-id="fromDate"
                    dateFormat="dd/MM/yyyy"
                    id="endDate"
                    placeholderText={"From date"}
                    toggleCalendarOnIconClick={true}
                    popperPlacement="auto"
                    popperModifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          padding: 16,
                        },
                      },
                    ]}
                    showIcon
                    onChange={this.fromDate}
                    maxDate={new Date()}
                    popperClassName="example-datepicker-class"
                    selected={this.state.fromDate}
                    icon={
                      <span className="icon" style={{right:'15px', padding:'0'}}>
                      <img src={greyCalendar} alt={"calendar"} />
                    </span>
                    }
                  />
                </div>
                
              </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} style={{marginTop:'-8px'}}>
              <div className="DateContainers">
                  <div className="dateInput" style={{padding:'0px'}}>
                    {" "}
                    <DatePicker
                    data-test-id="toDate"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                      id="dateStart"
                      placeholderText={"To date"}
                      showIcon
                      toggleCalendarOnIconClick={true}
                      popperPlacement="auto"
                      popperModifiers={[
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                        {
                          name: "flip",
                          enabled: false,
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            padding: 16,
                          },
                        },
                      ]}
                      onChange={this.toDate}
                      popperClassName="example-datepicker-class"
                      selected={this.state.toDate}
                      icon={
                        <span className="icon" style={{right:'15px', padding:'0'}}>
                        <img src={greyCalendar} alt={"calendar"} />
                      </span>
                      }
                    />
                  </div>
                </div>
              </Grid>
              <StyledGrid item xs={12} sm={12} md={6} lg={6}>
                <CustomOrangeButton style={{marginTop:'-2px'}} onClick={this.handleCSV}>
                  Export (.csv)
                </CustomOrangeButton>
              </StyledGrid>
            </Grid>

          <Grid container style={{marginTop:'20px'}}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{display:'flex', flexDirection:'column'}}>
              <Typography style={webStyle.commonErr}>{this.state.commonError}</Typography>
              <Typography style={webStyle.commonErr}>{this.state.dateError}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography data-test-id="resultsErr" style={webStyle.resultsErr}>{this.state.resultsErr}</Typography>
            </Grid>
          </Grid>
          </Grid>
          {this.state.reportType === configJSON.compareSalesText &&
          <TableContainer style={{overflowX: 'auto'}}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow style={{backgroundColor:'#ececec', display:'flex', alignItems:'center'}}>
                  <TableCell align="center" colSpan={1} style={webStyle.headingOne}>{""}</TableCell>
                  <TableCell align="left" style={webStyle.headingTwo}>
                    <Typography style={webStyle.salesHeaderText}>{"TOTAL"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{"ORDER ITEM"}</Typography>
                  </TableCell>
                  <TableCell style={{...webStyle.heading, width:'140px'}}>
                    <Typography style={webStyle.salesHeaderText}>{"UNITS"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" ORDERED"}</Typography>
                  </TableCell>
                  <TableCell style={{...webStyle.headingTwo, width:'140px'}}>
                    <Typography style={webStyle.salesHeaderText}>{"ORDERED"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" PRODUCT SALES"}</Typography>
                    </TableCell>
                  <TableCell style={{...webStyle.heading,width:'160px'}}>
                    <Typography style={webStyle.salesHeaderText}>{"AVERAGE"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" UNITS/ORDER"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" ITEM"}</Typography>
                  </TableCell>
                  <TableCell style={webStyle.heading}>
                    <Typography style={webStyle.salesHeaderText}>{"AVERAGE"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" SALES/ORDER"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" ITEM"}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{marginBottom:'20px'}}>
                {this.state.compareSalesList &&
                  Object.entries(this.state.compareSalesList).map(([key,value]) => (
                    <>
                      <TableRow data-test-id="comparelist" style={{backgroundColor: '#fff',display:'flex'}}>
                        <TableCell style={webStyle.rowHeader}>
                          {this.setCompareSalesHeader(key)}
                        </TableCell>
                        <TableCell align="left" style={webStyle.columnOne}>{value.total_order_item}</TableCell>
                        <TableCell align="left" style={webStyle.columnOne}>{value.units_ordered}</TableCell>
                        <TableCell align="left" style={webStyle.columnOne}>AED {value.ordered_product_sales}</TableCell>
                        <TableCell align="left" style={webStyle.columnThree}>{value.average_units_or_order_item}</TableCell>
                        <TableCell align="left" style={webStyle.columnOne}>AED {value.average_sales_or_order_item}</TableCell>
                      </TableRow>
                      </>
                    ))
                }</TableBody></Table>
          </TableContainer>
          }
          {this.renderSalesList()}
          {this.state.reportType === configJSON.returnReportText && this.state.returnsList.length > 0 && 
          <>
          <TableContainer style={webStyle.tableContainer}>
          <Table component={Paper}>
            <TableHead>
              <TableRow style={webStyle.salesReportTableRow}>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.dateText}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="returnDate" src={this.getIconSrc("date")} onClick={() => this.handleSort("date")} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.orderedProdText}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="returnOrderProdSales" src={this.getIconSrc("ordered_product_sales")} alt="salesIcon" onClick={() => this.handleSort("ordered_product_sales")} />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.unitsOrdered}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="returnUnitsOrder" src={this.getIconSrc("units_ordered")} alt="salesIcon" onClick={() => this.handleSort("units_ordered")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.totalOrderItem}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="returnTotalOrderItem" src={this.getIconSrc("total_order_item")} alt="salesIcon" onClick={() => this.handleSort("total_order_item")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.unitsRefunded}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="returnUnitsRefunded" src={this.getIconSrc("units_refunded")} alt="salesIcon" onClick={() => this.handleSort("units_refunded")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.refundRate}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="returnRefundRate" src={this.getIconSrc("refund_rate")} alt="salesIcon" onClick={() => this.handleSort("refund_rate")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.feedbackReceived}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="returnFeedbackReceived" src={this.getIconSrc("feedback_received")} alt="salesIcon" onClick={() => this.handleSort("feedback_received")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.negativeFeedbackReceived}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="negFeedback" src={this.getIconSrc("negative_feedback_received")} alt="salesIcon" onClick={() => this.handleSort("negative_feedback_received")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={{...webStyle.salesHeaderText, ...webStyle.orderItemSessionPer}}>{configJSON.returnReportsHeaders.receivedNegativeFeedbackRate}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="negFeedbackRate" src={this.getIconSrc("received_negative_feedback_rate")} alt="salesIcon" onClick={() => this.handleSort("received_negative_feedback_rate")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.azClaimsGranted}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="azClaims" src={this.getIconSrc("a_z_claims_granted")} alt="salesIcon" onClick={() => this.handleSort("a_z_claims_granted")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.claimsAmount}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="claimsAmount" src={this.getIconSrc("claims_amount")} alt="salesIcon" onClick={() => this.handleSort("claims_amount")}/>
                </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={webStyle.bottomMargin}>
                {this.state.returnsList.length > 0 &&
                  this.state.returnsList.map((item:IReturnReport, index: number) => {
                    const { date, ordered_product_sales, units_ordered, total_order_item, units_refunded, refund_rate, feedback_received, negative_feedback_received, received_negative_feedback_rate, a_z_claims_granted, claims_amount } = item 
                    return (
                      <TableRow data-test-id="returnlist" key={index} style={webStyle.returnReportTableBodyRow}>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{this.formatDate(date)}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {ordered_product_sales}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{units_ordered}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{total_order_item}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{units_refunded}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{refund_rate}%</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{feedback_received}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{negative_feedback_received}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{received_negative_feedback_rate}%</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{a_z_claims_granted}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {claims_amount}</TableCell>
                      </TableRow>)})
                }
              </TableBody>
            </Table>
            </TableContainer>
            {this.renderPagination(this.state.returnsList)}
            </>
          }
          {this.state.reportType === configJSON.salesTrafficReportText && this.state.salesTrafficList.length > 0 && 
          <>
          <TableContainer style={webStyle.tableContainer}>
          <Table component={Paper}>
            <TableHead>
              <TableRow style={webStyle.salesReportTableRow}>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.parentBesku}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="parentBesku" src={this.getIconSrc("parent_besku")} alt="salesIcon" onClick={() => this.handleSort("parent_besku")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.childBesku}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="childSku" src={this.getIconSrc("child_besku")} alt="salesIcon" onClick={() => this.handleSort("child_besku")} />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.title}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="title" src={this.getIconSrc("title")} alt="salesIcon" onClick={() => this.handleSort("title")} />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.skuText}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="sku" src={this.getIconSrc("sku")} alt="salesIcon" onClick={() => this.handleSort("sku")} />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.sessionsTotal}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="salesTrafficsessionsTotal" src={this.getIconSrc("sessions_total")} alt="salesIcon" onClick={() => this.handleSort("sessions_total")} />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.sessionsPer}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="salesTrafficsessionsPer" src={this.getIconSrc("sessions_percentage")} alt="salesIcon" onClick={() => this.handleSort("sessions_percentage")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.pageViewsTotal}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="salesTrafficPageViewTotal" src={this.getIconSrc("page_views_total")} alt="salesIcon" onClick={() => this.handleSort("page_views_total")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.pageViewsPerTotal}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="salesTrafficPageViewPerTotal" src={this.getIconSrc("page_views_percentage_total")} alt="salesIcon" onClick={() => this.handleSort("page_views_percentage_total")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={{...webStyle.salesHeaderText, ...webStyle.orderItemSessionPer}}>{configJSON.saleTrafficHeaders.featuredOffer}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="featuredOfferBuy" src={this.getIconSrc("featured_offer_buy_box_per")} alt="salesIcon" onClick={() => this.handleSort("featured_offer_buy_box_per")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.unitsOrdered}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="salesTrafficUnitsOrdered" src={this.getIconSrc("units_ordered")} alt="salesIcon" onClick={() => this.handleSort("units_ordered")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.unitSessionPer}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="salesTrafficUnitsOrderPer" src={this.getIconSrc("units_ordered_percentage")} alt="salesIcon" onClick={() => this.handleSort("units_ordered_percentage")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.orderedProductSales}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="salesTrafficOrderProdSales" src={this.getIconSrc("ordered_product_sales")} alt="salesIcon" onClick={() => this.handleSort("ordered_product_sales")}/>
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.totalOrderItems}</Typography>
                  <img style={webStyle.salesIcon} data-test-id="salesTrafficTotalOrderItem" src={this.getIconSrc("total_order_item")} alt="salesIcon" onClick={() => this.handleSort("total_order_item")}/>
                </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={webStyle.bottomMargin}>
                {this.state.salesTrafficList.length > 0 &&
                  this.state.salesTrafficList.map((item:ISalesTraffic, index: number) => {
                    const { parent_besku, child_besku, title, sku, sessions_total, sessions_percentage, page_views_total, page_views_percentage_total, featured_offer_buy_box_per, units_ordered, units_ordered_percentage, ordered_product_sales, total_order_item } = item
                    return (
                      <>
                      <TableRow data-test-id="salesTrafficlist" key={index} style={{backgroundColor: '#fff', borderBottom: '1px solid rgba(224, 224, 224, 1)', padding:'10px 0'}}>
                        <TableCell align="left" style={{...webStyle.salesTableCell, paddingLeft:'16px'}}>{parent_besku}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{child_besku}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>
                        <Box
                          style={{wordBreak: 'break-all'}}
                        >
                          <ReportsTruncatedTextwithTooltip text={title} maxChar={30}/>
                        </Box></TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{sku}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{sessions_total}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{sessions_percentage}%</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{page_views_total}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{page_views_percentage_total}%</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{featured_offer_buy_box_per}%</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{units_ordered}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{units_ordered_percentage}%</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {ordered_product_sales}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{total_order_item}</TableCell>
                      </TableRow>
                      </>
                    );
                  })
                }
              </TableBody>
            </Table>
            </TableContainer>
          {this.renderPagination(this.state.salesTrafficList)}
          </>
          }
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  tabName: {
    color: '#000',
    fontFamily: 'Lato, sans-serif',
    fontSize: '18px',
    fontWeight: 400,
    padding: '20px 0 15px'
  },
  reportsHeading: {
    color: '#000',
    fontFamily: "Lato, sans-serif",
    fontSize: '27px',
    fontWeight: 600
  },
  reportContainer: {
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    position: "relative" as const,
    background: "white"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Lato, sans-serif",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  selectField: {
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    borderRadius: 5,
    marginTop: "5px",
    padding: 0,
    maxHeight: "400px",
    overflowY: "auto" as const,
  },
  fieldContainer: {
    marginTop:'20px'
  },
  headingOne: {
    width:'300px',
    backgroundColor: '#ececec',
    fontSize:'16px',
    fontWeight: 400,
    fontFamily: 'Lato, sans-serif',
    textAlign:'left' as const,
    borderBottom:'none'
  },
  heading: {
    backgroundColor: '#ececec',
    fontSize:'14px',
    fontWeight: 400,
    fontFamily: 'Lato, sans-serif',
    textAlign:'left' as const,
    borderBottom:'none'
  },
  headingTwo: {
    display:'flex',
    flexDirection:'column' as const,
    width:'140px',
    backgroundColor: '#ececec',
    borderBottom:'none'
  },
  columnOne: {
    fontSize:'12px',
    fontWeight: 500,
    fontFamily: 'Lato, sans-serif',
    width:'140px',
    color: '#000'
  },
  columnThree: {
    width:'160px',
    fontSize:'12px',
    fontWeight: 500,
    fontFamily: 'Lato, sans-serif',
    color: '#000'
  },
  rowHeader: {
    width:'300px',
    backgroundColor: '#fff',
    fontSize:'14px',
    fontWeight: 500,
    fontFamily: 'Lato, sans-serif',
    color: '#000'
  },
  salesHeader : {
    backgroundColor: '#ececec',
    borderBottom: 'none'
  },
  salesHeaderText : {
    backgroundColor: '#ececec',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Lato, sans-serif',
    color: 'rgb(102,102,102)',
    borderBottom: 'none'
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px"
  },
  footerItem: {
    padding: "30px 0px 20px 20px",
    fontWeight: 600
  },
  salesIcon: {
    width:'16px',
    height:'16px',
    marginLeft:'6px',
    cursor:'pointer'
  },
  orderItemSessionPer : {
    width:'88px'
  },
  salesTableCell: {
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Lato, sans-serif',
    color: '#000',
    borderBottom:'none'
  },
  tableContainer: {
    overflowX: 'auto' as const, 
    maxWidth:'100%'
  },
  salesReportTableRow: {
    backgroundColor:'#ececec', 
    textAlign:'center' as const
  },
  bottomMargin: {
    marginBottom:'20px'
  },
  returnReportTableBodyRow: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(224, 224, 224, 1)', 
    padding:'10px 0'
  },
  headerBox: {
    display:'flex',
    alignItems:'center'
  },
  textOutline: {
    borderRadius: 8,
    '&.MuiFormControl-root': {
      borderRadius: 8
    }
  },
  select: {
    fontFamily: `"Lato", sans-serif !important` as const,
    color: '#333333 !important',
    fontSize:'14px !important',
    opacity: 'initial',
    borderRadius: 8,
    '&.MuiFormControl-root': {
      borderRadius: 8
    }
  },
  menuStyle: {
    fontFamily: `"Lato", sans-serif !important` as const,
    color: '#33333a !important',
    fontSize:'14px !important',
  },
  dateContainer: {
    marginTop: '6px',
    position: 'relative' as const
  },
  date: {
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    input: {
      borderRadius:'8px',
      padding: '6px 35 5 15px !important',
      fontFamily:"Lato,sans-serif !important"
    }
  },
  prodSales: {
    marginLeft:'-30px'
  },
  commonErr: {
    fontWeight:400, 
    fontFamily:"Lato,sans-serif",
    color:'rgb(215,77,77)', 
    fontSize:'12'
  },
  resultsErr: {
    color:'#000', 
    fontSize:'14', 
    fontWeight:400, 
    fontFamily:"Lato,sans-serif"
  },
  pagination: {
    justifyContent: "center",
    marginTop: "15px",
    display: "flex"
  }
};

const StyledGrid = styled(Grid)({
  display:'flex', 
  alignItems:'center', 
  justifyContent:'flex-end',
  '@media (max-width: 600px)': {
    justifyContent:'center'
  }
})

const CustomFormControlTwo = withStyles(() => ({
  root: {
    "& .MuiInput-formControl": {
      border: "1px solid #b1b3b5",
      margin: 0,
      padding: "5px 0px 5px 0px",
      borderRadius: "5px"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" 
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" 
    },
    "& .MuiSelect-select:focus": {
      background: "none"
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: 10,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: `"Lato", sans-serif`
    }
  }
}))(FormControl);
// Customizable Area End
