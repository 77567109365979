// Customizable Area Start
import React from "react";
import CreateStockIntakeReqController, { Props } from "./CreateStockIntakeReqController.web";
import { Box, Grid, InputAdornment, Select, Typography } from "@material-ui/core";
import { CustomExpandMoreIcon, CustomFormControl, CustomMenuItem, CustomizeBrandInput, OrangeButton } from "../../dashboard/src/CustomizedElementStyle";
import { configJSON } from "./CatalogueController";
import { errorImg } from "../../otp-input-confirmation/src/OTPInputAuthController";
import { ToastContainer } from "react-toastify";
// Customizable Area End

class CreateStockIntakeRequest extends CreateStockIntakeReqController {
    constructor(props:Props){
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <>
            <ToastContainer/>
            <Box style={webStyle.mainWrapper}>
                <Box sx={webStyle.headerContainer}>
                    <Box sx={webStyle.headerText}>{configJSON.fulFilledByEzzyText} / <span data-test-id="stockMg" style={{cursor:'pointer'}} onClick={this.handleFBBStockMgmt}>{configJSON.fbbStockMgmtText}</span> / <span style={webStyle.stockReqText}>{configJSON.stockPath}</span></Box>
                </Box>
            <Box style={webStyle.bckGround}>
                <Box style={webStyle.formWrapper}>
                <Box data-test-id="formHeader" sx={webStyle.formHeader}>{configJSON.stockPath}</Box>
                </Box>
            
                <Box sx={webStyle.formWidth}></Box>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFormControl style={{ width:'90%' }} disabled={this.state.prodErr ? true : false}>
                        <Box sx={webStyle.formLabel}>{configJSON.selectproductLabel}</Box>
                        <Select
                            data-test-id="selectproduct"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            name="productName"
                            value={this.state.selectedProduct}
                            onChange={this.handleSelectProduct}
                            defaultValue=""
                            IconComponent={CustomExpandMoreIcon}
                            MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: webStyle.selectProduct,
                            }
                            }}>
                            {this.state.data.map((item) => {
                                const prodTitle = item.attributes.product_content && item.attributes.product_content.product_attributes?.product_title;
                            return (
                                <CustomMenuItem value={item.attributes.id} key={item.attributes.name}>
                                {prodTitle}
                                </CustomMenuItem>
                            );
                            })}
                        </Select>
                        <Typography style={webStyle.errMsg}>{this.state.prodErr ? this.state.prodErr : ''}</Typography>
                        </CustomFormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFormControl style={{ width:'90%', paddingTop:'26px', wordWrap: 'break-word',   overflowWrap: 'break-word', whiteSpace: 'normal'}}>
                        <CustomizeBrandInput
                            data-test-id="stockQuantity"
                            label={configJSON.stockQuantityLabel}
                            id="outlined-start-adornment"
                            value={this.state.quantity}
                            onChange={this.handleQuantity}
                            onKeyDown={(event:React.KeyboardEvent<HTMLDivElement>) => {
                                if (!/^\d$/.test(event.key) && event.key !== 'Backspace') {
                                  event.preventDefault();
                                }
                              }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                            }}
                            />
                        </CustomFormControl>
                        <Typography style={webStyle.errMsg}>{this.state.quantityErr ? this.state.quantityErr : ''}</Typography>
                    </Grid>
                </Grid>
                {this.state.commonErr &&
                    <Box style={webStyle.errBox}>
                    <img style={webStyle.image} src={errorImg} alt="errorImage" />
                    <Typography data-test-id="stockCommonErr" style={{...webStyle.errMsg, marginTop:'0', marginLeft:'5px'}}>{this.state.commonErr}</Typography>
                    </Box> 
                }
                <Box sx={webStyle.viewBtnContainer}>
                <OrangeButton style={webStyle.viewBtn} onClick={this.initiateStockReq}>
                    {configJSON.submitRequestBtn}
                </OrangeButton>
            </Box>
            </Box>  
            
            </Box>  
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
const webStyle = {
    mainWrapper : {
        overflowY: "auto" as const,
        overflowX: "hidden" as const,
        marginBottom:'30px'
    },
    headerContainer: {
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center",
        padding:'20px 0px 15px 0'
    },
    headerText : {
        fontSize:'18px',
        fontWeight: 400 , 
        fontFamily: `"Lato", sans-serif` ,
        color: '#000'
    },
    stockReqText : {
        color: 'rgb(255, 96, 8)'
    },
    bckGround : {
        backgroundColor: 'white',
        padding:'20px',
        borderRadius: '8px',
    },
    description: {
        fontSize: '14px',
        fontFamily:  `"Lato", sans-serif`,
        color : '#747474',
        fontWeight: 500
    },
    viewBtnContainer : {
        alignItems:'flex-end',
        marginTop:'80px'
    },
    viewBtn: {
        padding:'8px 30px',
        fontFamily: 'Lato,sans-serif'
    },
    formWrapper : {
        width: "100%", 
        display: "flex", 
        flexDirection: "row" as const, 
        flexWrap: "wrap" as const, 
        justifyContent: "space-between", 
        alignItems: "center", 
        marginBottom: 10 
    },
    formHeader : {
        fontSize: "28px", 
        fontWeight: 700, 
        fontFamily: `"Lato", sans-serif`
    },
    formWidth: {
        width: "100%", 
        height: 50 
    },
    formLabel : {
        color: "#9a9a9a", 
        fontFamily: `"Lato", sans-serif`, 
        fontWeight: 600, 
        fontSize: 12, 
        marginBottom: 10
    },
    selectProduct : {
        background: "#F6F6F6",
        border: "1px solid rgba(151, 151, 151, 0.147084)",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0468298)",
        marginTop: "8px",
        maxHeight: "400px",  
        overflowY: "auto" as const, 
    },
    fieldWrapper : {
        width: "100%", 
        height: 50 
    },
    fieldContainer: {
        width:'90%', 
        paddingTop:'10px'
    },
    errMsg: {
        fontFamily: `"Lato", sans-serif`,
        fontSize: "12px",
        marginTop: "5px",
        color: "red"
    },
    errBox: {
        marginTop:'20px',
        display:'flex',
        alignItems:'center'
    },
    image: {
        height: '16px', 
        width: '16px'
    }
}
export {CreateStockIntakeRequest}
export default CreateStockIntakeRequest
// Customizable Area End