import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
export const configJSON = require("../src/config.js")
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start

    // Customizable Area End
  }
  interface S {
    // Customizable Area Start
    authToken: string;
    data: any[],
    selectedProduct: string
    stockValue: string,
    quantity: string,
    productLength: string,
    productWidth: string,
    productHeight: string,
    productWeight: string,
    authorizationToken: string,
    prodErr: string,
    commonErr: string,
    quantityErr: string
    // Customizable Area End
  }
  interface SS {
    id: any;
  }
  
  export default class CreateStockIntakeReqController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    createStockIntakeAPICallId: string = "";
    getAuthTokenApiCallId: string = "";
    getByezzyProductListApiCallId: string = "";
    // Customizable Area End
  
    // Customizable Area Start
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];
  
      this.state = {
        authToken: "",
        data: [],
        selectedProduct: '',
        stockValue : '',
        quantity: '',
        productLength: '',
        productWidth: '',
        productHeight: '',
        productWeight: '',
        authorizationToken: '',
        prodErr: '',
        commonErr: '',
        quantityErr: ''
      };
  
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area End
    }
  
    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
          if (apiRequestCallId === this.getAuthTokenApiCallId) {
            this.handleAuthTokenRes(responseJson);
          }
          if (apiRequestCallId === this.createStockIntakeAPICallId) {
            this.handleStockIntakeRes(responseJson)
          }
          if (apiRequestCallId === this.getByezzyProductListApiCallId) {
            this.handleProductListRes(responseJson)
          }
        }
    }

    handleProductListRes = (responseJson:any) => {
        if(responseJson.data.length > 0) {
            this.setState({data: responseJson.data})
        }
        else if(responseJson.data.length === 0) {
            this.setState({prodErr: 'No products found'})
        }
    }

    handleAuthTokenRes = (responseJson: any) => {
        this.setState({authorizationToken: responseJson.token}, () => this.createStockIntakeRequest())
    }

    handleStockIntakeRes = (responseJson: any) => {
        if(responseJson.response === "success") {
            toast.success("Stock Intake Request created successfully!", {
                progress: undefined,
                autoClose: 3000, 
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                pauseOnHover: true
              });
              setTimeout(() => {
                this.props.navigation.navigate(configJSON.sellerHomeText, { path: 'storage' });
              }, 2000);
        }
    }

    handleSelectProduct = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        this.setState({ selectedProduct : event.target?.value })
    }

    handleQuantity = (event:React.ChangeEvent<{name?:string,value: any}>) => {
        const quantity =  event.target?.value
        if(quantity) {
            this.setState( {quantity : quantity})
        }
        else this.setState({quantityErr: "This field is required"})
    }
  
    handleGetToken = async () => {
      const authToken = await getStorageData("authToken");
      const authorizationToken = await getStorageData("authorizationToken");
      this.setState({ authToken, authorizationToken }, () => this.getByEzzyProductList());
    };
  
    async componentDidMount(): Promise<void> {
      this.handleGetToken();
    }

    initiateStockReq = () => {
        const {selectedProduct, quantity} = this.state;
        if(selectedProduct && quantity) {
            this.setState({commonErr: ''})
            if(this.state.authorizationToken) {
                this.createStockIntakeRequest()
            }
            else {
                this.getAuthToken()
            }
        }
        else {
            this.setState({commonErr: "Please fill all the fields"})
        }
    }

    getAuthToken = async() => {
        const headers = { 
        };
        const httpBody = {
            "email": "hari_test@yopmail.com", 
            "password": configJSON.authTokenPassword,  
            "warehouse_code": "BEZY"  
        }
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAuthTokenApiCallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createAuthTokenEndpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    createStockIntakeRequest = async() => {
        const headers = { 
            'Content-Type': 'application/json',
            token: await getStorageData("authToken") 
        };
        const httpBody = {
            "catalogue_id": this.state.selectedProduct.toString(),
            "authorization": this.state.authorizationToken,
            "quantity": this.state.quantity,
            "warehouse_code": "BEZY"
        }
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createStockIntakeAPICallId = reqMessage.messageId;
        reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createConsignmentApiEndpoint);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
        reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    getByEzzyProductList = () => {
        const headers = {
            token: this.state.authToken
        }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getByezzyProductListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues?fulfillment[]=byezzy&&content_status[]=accepted&live_status[]=true`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleFBBStockMgmt = () => {
        this.props.navigation.navigate(configJSON.sellerHomeText, { path: 'storage' })
    }
    // Customizable Area End
  }