import React from "react";

// Customizable Area Start
import { Box, Button, Grid, IconButton, Input, InputAdornment, Modal, Paper, Theme, Typography, withStyles } from "@material-ui/core";
import { BoxNoScroll, FormControlTwo, SearchIconSVG } from "../CustomizedElementStyle";
import { file } from "../assets";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import { CustomOrangeButton, CustomizeInputSearchBox } from "../../../../components/src/CommonButton"
// Customizable Area End

import SearchBrandController, { Props, successIcon, initialValues, configJSON } from "./SearchBrandController";
import { ToastContainer } from "react-toastify";

export default class SearchBrand extends SearchBrandController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object().shape({
    pdfFile: Yup.mixed()
      .required("Please upload a PDF file.")
      .test("fileType", "Invalid file type. Only PDF files are allowed.", value => {
        if (!value) return true; // Allow undefined values (not selected file)
        return value && value.type === "application/pdf";
      })
      .test("fileSize", "Please upload a PDF file less than 5MB.", value => !value || value.size / (1024 * 1024) <= 5)
  });

  returnError = (touched: any, errors: any) => {
    if (touched && errors) {
      return <Typography style={webStyles.errorStyle}>{errors}</Typography>;
    } else {
      return null;
    }
  };
  SuccessModal = () => {
    return (
      <Modal open={this.state.successRequestModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <Box sx={webStyles.modalMainWrapper}>
          <Box sx={webStyles.blurredBackground}></Box>
          <Box sx={webStyles.verifiedModalContainerMain} style={{ background: "white", height: "320px", border: "1px solid black" }}>
            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
              <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                <IconButton data-test-id="successConfirmRef" aria-label="toggle password visibility" onClick={() => this.handleCloseSucces()} edge="end" style={{ marginRight: "-4px" }}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box sx={webStyles.verifiedImageWrapperMain} style={{ height: "100%" }}>
                <Box sx={webStyles.verifiedImageContainer}>
                  <img src={successIcon} alt="success image" />
                </Box>
                <Box sx={{ fontFamily: `"Lato", sans-serif`, marginBottom: "10px", fontWeight: "bold", fontSize: "20px", color: "#565656" }}>{"Request is submitted"}</Box>
                <Box sx={{ fontFamily: `"Lato", sans-serif`, marginBottom: "10px", fontSize: "12px", color: "#565656" }}>{"Your request is submitted for review"}</Box>
                <Box sx={{ fontFamily: `"Lato", sans-serif`, marginBottom: "70px", fontSize: "12px", color: "#565656" }}>{"You are being redirect"}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  // Customizable Area End

  // Customizable Area Start
  render() {
    const { classes } = this.props;
    return (
      <>
        <ToastContainer />
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 400, fontFamily: `"Lato", sans-serif` }}>{this.props.id === "seller-home-bulk-upload-product-1" ? "Product Catalog" : "Partner Catalog"}</Box>
        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto" }}>
          <Box style={{ borderRadius: "8px", background: "white", display: "flex", flexDirection: "column", padding: "30px", position: "relative", overflowY: "auto", overflowX: "hidden" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
              <Box className={classes.brandHeader}>{this.state.requestAlreadySent || this.state.restrictedRequestForm ? "Brand" : "Tell us about your product’s brand"}</Box>
            </Box>
            {this.SuccessModal()}
            <Box style={{ height: 1, background: "#EEEEEE" }}></Box>
            <Box sx={{ width: "100%", height: 60 }}></Box>
            <Grid container spacing={4} className={classes.createBrandButtonContainer}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Box sx={{ position: "relative", justifyContent:'flex-start', display:'flex' }}>
                  <FormControlTwo fullWidth={true}>
                    <Box sx={{ color: "rgb(102, 102, 102)", fontFamily: `"Lato", sans-serif`, fontWeight: 600, fontSize: 12, marginBottom: 10 }}>{this.state.restrictedRequestForm ? "Brand" : "Enter Brand Name"}</Box>
                    <Autocomplete
                      data-test-id="autocompleteRef"
                      loading={this.state.loadingBrand}
                      loadingText={"Searching brand...."}
                      options={this.state.brandOptions.length > 0 ? this.state.brandOptions : []}
                      getOptionLabel={(option: any) => option.brand_name || ""}
                      getOptionSelected={(option, value) => option.brand_name === value.brand_name}
                      value={this.state.selectedOption}
                      onChange={(_, newValue: any | null) => {
                        this.handleOptionChange(newValue);
                      }}
                      noOptionsText={"No brand found"}
                      open={this.state.openSearchResultMenu}
                      inputValue={this.state.keyword}
                      onInputChange={this.handleSearchOnChange}
                      renderInput={(params: any) => (
                        <CustomizeInputSearchBox
                          {...params}
                          placeholder="Search brand"
                          data-test-id="autocompletInputeRef"
                          label=""
                          variant="outlined"
                          className={classes.serch}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                            endAdornment: (
                              <InputAdornment position="end" style={{ paddingRight: 10 }}>
                                <SearchIconSVG />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                      classes={{ root: this.props.classes.autoComplete, paper: this.props.classes.autoCompletePopover }}
                      PaperComponent={Paper}
                    />
                    {this.state.brandErr && (
                      <Typography data-test-id="brandErr" className={classes.brandErr}>
                        {this.state.brandErr}
                      </Typography>
                    )}
                  </FormControlTwo>
                </Box>
              </Grid>
              {!(this.state.restrictedRequestForm || this.state.requestAlreadySent) && (
                <Grid item lg={2} md={2} sm={2} xs={2}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <CustomOrangeButton
                  onClick={this.handleOnNext}
                  data-test-id="nextRef"
                  style={{
                    marginTop: "25px",
                  }}
                >
                  Next
                </CustomOrangeButton>
              </Box>
              </Grid>
            )}
            </Grid>
              {!(this.state.restrictedRequestForm || this.state.requestAlreadySent) && (
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "center", height: "100%", alignItems: "center", flexDirection: "column" }}>
                    <Box style={{ fontSize: 12, fontFamily: `"Lato", sans-serif`, marginBottom: 10, fontWeight: 1000, display: "flex", flexDirection: "row" }}>
                      <Box sx={{ color: "#9a9a9a" }}>Brand doesn’t exist.</Box>
                      <ClickableBox data-test-id="clickHereRef" sx={{ color: "#FF6008", marginLeft: 5 }} onClick={this.handleGoToCreateBrand}>
                      Click here 
                      </ClickableBox>
                      <Box sx={{ color: "#9a9a9a", marginLeft: 5 }}>to create a new Brand.</Box>
                    </Box>
                    <CustomOrangeButton data-test-id="createBrand" className={classes.brandBtn} onClick={this.handleGoToCreateBrand}>
                      Create Brand
                    </CustomOrangeButton>
                  </Box>
                </Grid>
              )}
            

            {this.state.requestAlreadySent && (
              <Box className={this.props.classes.message}>
                <span>{configJSON.requestAlreadySubmitedMessage}</span>
              </Box>
            )}
            {this.state.restrictedRequestForm && (
              <Box>
                <Box className={this.props.classes.message}>
                  <span>{configJSON.restrictionMessage}</span>
                </Box>
                <Formik initialValues={initialValues} data-form-key={"form-brand"} validationSchema={this.validationSchema} validateOnMount={true} validateOnChange={true} validateOnBlur={true} onSubmit={values => this.handleSubmitRequest(values)} data-test-id="formik">
                  {({ handleChange, errors, setFieldTouched, touched, setFieldValue, values, handleBlur }) => (
                    <Form
                      translate={undefined}
                      style={{
                        margin: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                      }}
                    >
                      <Grid item lg={12} sm={12} xs={12}>
                        <Box sx={webStyles.label}>Document</Box>
                        <Input
                          data-test-id="fileInput"
                          value=""
                          type="file"
                          name="pdfFile"
                          style={{
                            display: "none"
                          }}
                          inputProps={{
                            "aria-label": "upload documents",
                            accept: ".pdf",
                            ref: this.inputRef
                          }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("pdfFile", event.currentTarget?.files?.[0]);
                            setFieldTouched("pdfFile", true, false);
                          }}
                        />
                        <label htmlFor="upload-button">
                          <Button
                            fullWidth
                            data-test-id="fileButtonRef"
                            variant="outlined"
                            onClick={() => this.handleFileOpenInput()}
                            style={{
                              borderRadius: "5px",
                              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FBBC98FF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                              width: "100%",
                              height: 167,
                              padding: "20px 0px",
                              border: "none",
                              margin: "10px 0px",
                              textTransform: "none",
                              fontFamily: `"Lato", sans-serif`
                            }}
                          >
                            <Box>
                              <img src={file} />
                              <Box sx={{ fontWeight: 400, fontSize: "27px", fontFamily: `"Lato", sans-serif` }}>Upload Document</Box>
                              <Box sx={{ fontFamily: `"Lato", sans-serif`, fontSize: 16, color: "#9a9a9a" }}>{"Only PDF file to be uploaded here(Maximum size- 5mb)"}</Box>
                            </Box>
                          </Button>
                        </label>
                        {this.returnError(touched.pdfFile, errors.pdfFile)}
                      </Grid>
                      <Grid item lg={6} sm={6} xs={12}>
                        <Grid container direction="column" spacing={2}>
                          {values?.pdfFile && (
                            <Grid item sm={12} xs={12}>
                              <Box style={{ justifyContent: "space-between", display: "flex", alignItems: "center", background: "#f5f5f5", borderRadius: 5, flexWrap: "nowrap", padding: "0px 0px 0px 20px" }}>
                                <Box style={{ fontFamily: `"Lato", sans-serif` }}>{values?.pdfFile.name}</Box>
                                <IconButton onClick={() => setFieldValue("pdfFile", null)} data-test-id="removeButtonRef">
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <CustomOrangeButton
                          type="submit"
                          data-test-id="nextRef"
                          style={{
                            marginTop: "35px",
                            padding: "10px 50px",
                            fontFamily: `"Lato", sans-serif`
                          }}
                        >
                          Submit a request
                        </CustomOrangeButton>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            )}
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  item: {
    padding: "50px 0px 10px 20px",
    fontWeight: 600,
    fontFamily: `"Lato", sans-serif`
  },
  label: {
    color: "rgb(111, 111, 111)",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600,
    fontSize: 13,
    marginBottom: 8
  },
  errorStyle: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "5px",
    fontFamily: `"Lato", sans-serif`
  },
  mainWrappers: {
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    background: "#fff",
    display: "flex",
    fontFamily: `"Lato", sans-serif`,
    width: "100%"
  },
  modalMainWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    position: "relative",
    fontFamily: `"Lato", sans-serif`
  },
  blurredBackground: {
    width: "100%",
    zIndex: -1,
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    position: "absolute",
    backdropFilter: "blur(8px)",
    fontFamily: `"Lato", sans-serif`
  },
  modalContainer: {
    position: "relative",
    width: "450px",
    padding: "50px",
    borderRadius: "10px",
    maxWidth: "450px",
    fontFamily: `"Lato", sans-serif`
  },
  sendEmailVerifModal: {
    maxWidth: "540px",
    padding: "50px 50px 32px 50px",
    borderRadius: "10px",
    backgroundColor: "white",
    position: "relative",
    width: "540px",
    height: "400px",
    fontFamily: `"Lato", sans-serif`
  },
  verifiedModalContainerMain: {
    borderRadius: "10px",
    maxWidth: "450px",
    position: "relative",
    width: "350px",
    display: "flex",
    padding: "20px",
    fontFamily: `"Lato", sans-serif`
  },
  verifiedImageWrapperMain: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    fontFamily: `"Lato", sans-serif`
  },
  verifiedImageContainer: {
    marginBottom: "24px",
    height: "100px",
    width: "100px",
    fontFamily: `"Lato", sans-serif`
  }
};

const styles = (theme: Theme) => ({
  brandBtn: {
    '@media (max-width: 740px)': {
      padding: "10px 20px",
      fontSize:'14px'
    }
  },
  brandErr: {
    color: "red",
    textAlign: "left" as const,
    fontSize: "12px",
    marginTop: "12px"
  },
  serch: {
    "& .MuiInputBase-root": {
      border: "none",
      "&:focus": {
        borderColor: "#c8c8c8",
        outline: "none" // Change the color for the focus state
      },
      "&.Mui-focused": {
        borderColor: "#c8c8c8",
        outline: "none"
      }
    },
    "& .MuiInputLabel-root": {
      color: "rgb(154,154,154)",
      opacity: 0.6,
      "&:focus": {
        borderColor: "#c8c8c8",
        outline: "none"
      },
      "&.Mui-focused": {
        borderColor: "#c8c8c8",
        outline: "none"
      }
    }
  },
  message: {
    border: "2px dashed #c8c8c8",
    padding: "16px",
    paddingRight: "30px",
    fontFamily: `"Lato", sans-serif`,
    color: "rgb(102, 102, 102)",
    fontSize: 15,
    borderRadius: 5,
    marginBottom: "30px",
    width: "53%"
  },
  autoComplete: {
    width: "100%",
    maxWidth: "100%",
    height: "40px",
    fontFamily: `"Lato", sans-serif`,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "100%"
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "5px !important",
      fontFamily: `"Lato", sans-serif`,
      "& .MuiAutocomplete-tag": {
        background: "#ffa06a",
        fontSize: "1rem",
        borderRadius: "4px",
        fontFamily: `"Lato", sans-serif`
      }
    },
    "& .Mui-Focused": {
      outline: "none"
    }
  },
  submitButton: {
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  },
  autoCompletePopover: {
    background: "#F6F6F6",
    color: "#727272",
    fontSize: "25px",
    top: "10px",
    padding: "0",
    fontFamily: `"Lato", sans-serif`,
    "& .MuiAutocomplete-option": {
      color: "#2e2d2d",
      padding: "15px 14px",
      fontSize: 14,
      fontFamily: `"Lato", sans-serif`,
      "&:hover": {
        backgroundColor: "#eb6b2e",
        color: "white"
      },
      "& .Mui-selected": {
        backgroundColor: "#eb6b2e",
        color: "white"
      },
      "&:selection": {
        backgroundColor: "#eb6b2e",
        color: "white"
      },
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: "#eb6b2e",
        color: "white",
        fontFamily: `"Lato", sans-serif`
      }
    },
    "& .MuiAutocomplete-noOptions": {
      padding: "6px 16px",
      fontSize: "20px",
      fontFamily: `"Lato", sans-serif`
    },
    "& .MuiAutocomplete-loading": {
      padding: "6px 16px",
      fontSize: "20px",
      fontFamily: `"Lato", sans-serif`
    }
  },
  createBrandButtonContainer: {
    height: '200px',
    display:'flex',
    [theme.breakpoints.down('xs')]:{
      height: "200px",
      justifyContent:'space-between',
    }
  },
  brandHeader: {
    fontSize: "27px", 
    fontWeight: 700, 
    fontFamily: `"Lato", sans-serif` ,
    '@media (max-width: 600px)': {
      fontSize:'18px'
    }
  }
});

const styledSearchBrand = withStyles(styles)(SearchBrand);
export { styledSearchBrand };
// Customizable Area End
