import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import { IProductProfileData, IProductProfileError } from "../SellerHomeDashboardComponents/Interfaces";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  variant:boolean
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  sku: string;
  besku: string;
  product_image: File | "" | any;
  brand_id: string | number;
  sub_category_id: string | number;
  mini_category_id: string | number;
  micro_category_id: string | number;
  category_id: string | number;
  errorMsg: string[];
  isFetching: boolean;
  productInfo: any;
  prodImage: string;
  prodId: string;
  bibcNumber: string,
  bibcInfo: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ProductProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputRef: React.RefObject<InputProps & { click: Function }>;
  apiPostProductProfileCallId: string = "";
  getProductInfoApiCallId: string = "";
  updateProductProfileCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      sku: "",
      besku: "",
      mini_category_id: "",
      micro_category_id: "",
      product_image: "",
      brand_id: "",
      sub_category_id: "",
      category_id: "",
      errorMsg: [],
      isFetching: false,
      productInfo: [],
      prodImage: "",
      prodId: "",
      bibcNumber: '',
      bibcInfo: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.inputRef = createRef();
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ isFetching: false });
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiPostProductProfileCallId) {
        this.handleCreateBrandResponse(responseJson);
      }
      if (apiRequestCallId === this.getProductInfoApiCallId) {
        this.handleProductInfo(responseJson);
      }

      if (apiRequestCallId === this.updateProductProfileCallId) {
        this.handleCreateBrandResponse(responseJson);
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  handleProductInfo = async (responseJson: any) => {
    if (responseJson) {
      const isVariant = responseJson.data.attributes.is_variant
      const msg: Message = new Message(getName(MessageEnum.SendMessage));
      const  {bibc,brand, product_image, category, sub_category, micro_category, mini_category} = responseJson.data.attributes
      if(isVariant) {
        const bibcNumber = responseJson.data.attributes.variant_product_group.product_bibc
        msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "productProfile", activeScreen: "product-content", isVariant:true});
        this.setState({sku: responseJson.data.attributes.variant_product_group.product_sku, besku: responseJson.data.attributes.variant_product_group.product_besku, prodImage: responseJson.data?.attributes?.product_image})
        this.setBibcNumber(bibcNumber)
        setStorageData("isVariantProduct",true)
        setStorageData("variant_sku",responseJson.data.attributes.variant_product_group.product_sku);
      }
      else{
        msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "productProfile", activeScreen: "product-content", isVariant:false});
        setStorageData("sku",responseJson.data.attributes.sku)
        this.setState({
          sku: responseJson.data.attributes.sku,
          besku: responseJson.data.attributes.besku,
          productInfo: responseJson.data,
          prodImage: product_image,
          prodId: responseJson.data.id,
          category_id: category.id,
          sub_category_id: sub_category.id,
          mini_category_id: mini_category.id,
          micro_category_id: micro_category.id,
          brand_id: brand.id
        })
        this.setBibcNumber(bibc)
      }
      this.send(msg)
    }
  };

  setBibcNumber = (bibc: string | null) => {
    this.setState({
      bibcInfo: bibc !== null ? '' : configJSON.bibcInfoText, 
      bibcNumber: bibc !== null ? bibc : ''
    })
  }

  handleCreateBrandResponse = async (responseJson: unknown) => {
    const successData = responseJson as IProductProfileData;
    const errorData = responseJson as IProductProfileError;
    if ("data" in successData) {
      setStorageData("sku", successData.data.attributes.sku)
      setStorageData("catalog_id", successData.data.id);
      setStorageData("micro_category_id", successData.data.attributes?.micro_category?.id);
      setStorageData("brand_name", successData.data.attributes.brand.brand_name);
      if(successData.data.attributes.is_variant) {
        const msg: Message = new Message(getName(MessageEnum.SendMessage));
        msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "product-content", activeScreen: "product-content",isVariant:true});
        this.send(msg)
      }
      else  {
        const msg: Message = new Message(getName(MessageEnum.SendMessage));
        msg.addData(getName(MessageEnum.ChangeActivePanelProductDetails), { activePanel: "product-content", activeScreen: "product-content", isVariant:false});
        this.send(msg)
      }
    }
    if ("errors" in errorData) {
      return this.setState({ errorMsg: errorData.errors });
    }
  };

  handleOpenFileInput = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.click();
    }
  };

  handleDrop = (event:any) => {
    event.preventDefault();
    event.stopPropagation();
    const uploadedFile = (event.dataTransfer.files as FileList)[0];
    if (!uploadedFile) return;
  if (
    uploadedFile.type !== "image/png" &&
    uploadedFile.type !== "image/jpg" &&
    uploadedFile.type !== "image/jpeg"
  ) {
    return this.setState({ errorMsg: ["Please upload an image in PNG or JPG format."] });
  }
  const fileSize = uploadedFile.size / (1024 * 1024); 
  if (fileSize > 5) {
    return this.setState({ errorMsg: ["Please upload an image less than 5MB."] });
  }
      this.setState({prodImage:'',product_image: uploadedFile})
  };

  handleDragOver = (event:any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleUploadFile = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (!file) return;
    if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
      return this.setState({ errorMsg: ["Please upload an image in PNG or JPG format."] });
    }
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > 5) {
      return this.setState({ errorMsg: ["Please upload an image less than 5MB."] });
    }
    this.setState({ product_image: file, prodImage: "", errorMsg: [] });
  };

  handleRemoveFile = () => {
    this.setState({ product_image: "", prodImage: "" });
  };

  handleNext = () => {
    const { sku, besku, product_image, brand_id, sub_category_id, category_id, mini_category_id, micro_category_id, prodImage } = this.state;
    const image = product_image || prodImage;
    if (!this.state.prodId) {
      if (!sku || !besku || !image || !brand_id || !sub_category_id || !category_id || !mini_category_id || !micro_category_id) {
        return this.setState({ errorMsg: [configJSON.infoRequiredText] });
      } else this.createEditProduct();
    } else {
      if (!image) {
        return this.setState({ errorMsg: [configJSON.infoRequiredText] });
      } else this.createEditProduct();
    }
  };

  createEditProduct = async () => {
    const productExists = await getStorageData("catalog_id");
    if (!productExists) {
      this.postProductProfile();
    } else {
      this.updateProduct();
    }
  };

  handleGetIds = async () => {
    const category_id = (await getStorageData("category_id")) || "";
    const sub_category_id = (await getStorageData("sub_category_id")) || "";
    const brand_id = (await getStorageData("brand_id")) || "";
    const mini_category_id = (await getStorageData("mini_category_id")) || "";
    const micro_category_id = (await getStorageData("micro_category_id")) || "";

    this.setState({ category_id, sub_category_id, brand_id, mini_category_id, micro_category_id });
  };

  postProductProfile = async () => {
    try {
      const headers = { token: await getStorageData("authToken") };
      this.setState({ isFetching: true });
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apiPostProductProfileCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiPostProductProfileEndpoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      const formData = new FormData();
      formData.append("sku", this.state.sku);
      formData.append("besku", this.state.besku);
      formData.append("category_id", String(this.state.category_id));
      formData.append("sub_category_id", String(this.state.sub_category_id));
      formData.append("mini_category_id", String(this.state.mini_category_id));
      formData.append("micro_category_id", String(this.state.micro_category_id));
      formData.append("brand_id", String(this.state.brand_id));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
      const response = await fetch(this.state.prodImage);
      let blob = await response.blob();
      formData.append("product_image", this.state.prodImage ? blob : this.state.product_image);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } catch {}
  };

  updateProduct = async () => {
    const catalogId = await getStorageData("catalog_id");
    this.setState({ isFetching: true });
    const headers = { token: await getStorageData("authToken") };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateProductProfileCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${catalogId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    const formData = new FormData();
    this.state.product_image && formData.append("product_image", this.state.product_image);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSetSkuAndBESKU = async() => {
    const sku = await getStorageData("sku");
    const besku = await getStorageData("besku");
    this.setState({ sku: sku, besku: besku });
  };

  async componentDidMount(): Promise<void> {
    const newProductId = await getStorageData("newProductId");
    const catalogId = await await getStorageData("catalog_id");
    if (catalogId) {
      this.setState({ prodId: catalogId });
    } else this.setState({ prodId: newProductId });
    this.handleSetSkuAndBESKU();
    this.handleGetIds();
    if (this.state.prodId) {
      this.getProductInfo(this.state.prodId);
    }
  }

  getProductInfo = async (id: string) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData("authToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/catalogues/${id}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
