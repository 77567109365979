// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "../../../../framework/src/Message";
export const configJSON = require("../../src/config.js");

export interface ISection {
  data: {
    attributes: {
      grid_name:string,
      grid_image: string
    }
  }
}
interface ICategory {
  attributes: {
    name: string
  }
}
export interface Product {
  data: ProductData;
}

export interface ProductData {
  attributes: {
    product_image: string;
    final_price: string,
    stroked_price: number | null,
    offer_percentage: number | null,
    product_rating: {
      average_rating: number;
      total_reviews: number;
    };
    product_content: {
      product_attributes: {
        product_title: string;
        retail_price: number;
        mrp: number;
      };
    };
  };
}
interface StoreData {
  data: {
    attributes: {
      cover_image: string;
      logo: string;
      title:string,
      catalogues: Product[]; 
    };
  }[];
}

interface SectionData {
  attributes: {
    section_name: string;
    banner_name?: string;
    banner_image?: string;
    store_section_grids?: {
      data: {
        attributes: {
          grid_name: string,
          grid_image: string
        }
      }
    }[] ;
  };
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    modal: string,
    modalWrapper: string,
    cross: string,
    storeSection: string,
    position: string,
    storeBox: string,
    logo: string,
    logoName: string,
    storeCover: string,
    logoContainer: string,
    navBar: string,
    menu: string,
    menuContainer: string,
    activeMenu: string,
    bannerName: string,
    bannerSec: string,
    bannerImg: string,
    card: string,
    sectionOneImg: string ,
    cardText: string,
    sectionOneContainer: string,
    sectionTwoContainer: string,
    gridImage: string,
    cardTwo: string,
    name: string,
    exploreBtn: string,
    linkText: string,
    linkBox: string,
    buy: string,
    icon: string,
    sliderSec: string,
    newButton: string,
    desc: string,
    itemName: string,
    priceBox: string,
    cardPriceText: string,
    price: string,
    discountText: string,
    rating: string,
    starSec: string,
    bottom: string,
    offerPrice: string,
    sliderImg: string,
    sliderWrap: string,
    categoryName: string,
    arrow: string,
    expressImg: string,
    productCard: string,
    sectionThreeImg: string,
    rightSide: string,
    right: string,
    gridName: string,
    shopBtn: string,
    searchBox: string,
    headerContainer: string,
    logoImg: string,
    rightSideHeader: string,
    language: string,
    headerText: string,
    wishlistContainer: string,
    heartIcon: string,
    wishlistBox: string,
    userIcon: string,
    userImg: string,
    shopIcon: string,
    count: string,
    priceWrapper: string,
    flex: string,
    cart: string,
    sectionTwoText: string,
    priceMargin: string
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  categoryList : ICategory[],
  coverImg: string,
  logo: string,
  menuList:(string | undefined)[],
  storeId: string,
  bannerName: string | undefined,
  bannerImage: string | undefined,
  sectionOneInfo : ISection[] | undefined,
  sectionTwoInfo : ISection[] | undefined,
  sectionThreeInfo: ISection[] | undefined,
  productList: Product[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class TemplatePreviewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCategoryApiCallId: string = "";
  getStoreDataApiCallId: string = "";
  getSectionDetailsApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      categoryList : [],
      coverImg: '',
      logo: '',
      menuList: [],
      storeId: '',
      bannerName: '',
      bannerImage: '',
      sectionOneInfo: [],
      sectionTwoInfo:  [],
      sectionThreeInfo:  [],
      productList: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getCategoryApiCallId) {
        this.setState({ categoryList: responseJson?.data });
      }
      if (apiRequestCallId === this.getStoreDataApiCallId) {
        this.handleStoreResponse(responseJson)
      }
      if (apiRequestCallId === this.getSectionDetailsApiCallId) {
        this.handleSectionDetailsResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  handleSectionDetailsResponse = (responseJson: { data?: SectionData[] }): void => {
    if(responseJson.data) {
      const bannerData: SectionData[]  = responseJson.data.filter((item: SectionData) => {
        return item.attributes.section_name === "banner";
      });
      if(bannerData[0]) {
        const {banner_name, banner_image} = bannerData[0].attributes
        this.setState({bannerName: banner_name, bannerImage: banner_image})
      }
      const sectionOneData: SectionData[]  = responseJson.data.filter((item: SectionData) => {
        return item.attributes.section_name === "section_1";
      });
      if(sectionOneData) {
        this.setState({
          sectionOneInfo: sectionOneData[0]?.attributes.store_section_grids
        });
      }
      const sectionTwoData: SectionData[]  = responseJson.data.filter((item: SectionData) => {
        return item.attributes.section_name === "section_2";
      });
      if(sectionTwoData[0]) {
        this.setState({
          sectionTwoInfo: sectionTwoData[0]?.attributes.store_section_grids
        });
      }
      const sectionThreeData: SectionData[]  = responseJson.data.filter((item: SectionData) => {
        return item.attributes.section_name === "section_3";
      });
      if(sectionThreeData[0]) {
        this.setState({
          sectionThreeInfo: sectionThreeData[0]?.attributes.store_section_grids
        });
      }
    }
  }

  handleStoreResponse = (responseJson : StoreData) => {
    if(responseJson.data) {
      if(responseJson.data[0]) {
        const {cover_image, logo, catalogues} = responseJson.data[0].attributes
        this.setState({coverImg : cover_image, logo: logo})
        if(catalogues?.length > 0) {
          this.setState({productList : catalogues})
        }
      }
      const menu = responseJson.data?.slice(1).map((item:{attributes: {title?:string}}) => {
        return item.attributes.title
      })
      if(menu) {
        this.setState({menuList: menu})
      }
      this.getSectionDetails(this.state.storeId)
    }
  }

  async componentDidMount(): Promise<void> {
    const storeId = await getStorageData('store_id')
    this.setState({storeId: storeId})
    if(storeId) {
      this.getStoreDetails(storeId)
    }
    this.getProductCategories()
  }

  getProductCategories = async () => {
    const buyer_token = await getStorageData("authToken");

    const headers = {
      "Content-Type": configJSON.contentType,
      token: buyer_token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/categories");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStoreDetails = async(id: string) => {
    const storeId = await getStorageData("store_id")
    const headers = { token: await getStorageData("authToken")  };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoreDataApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + "/store_menus");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  getSectionDetails = async(id: string) => {
    const headers = { token: await getStorageData("authToken")  };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSectionDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + id + "/store_dashboard_sections");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }
  // Customizable Area End
}