// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { createRef } from "react";
import { getStorageData } from "../../../../framework/src/Utilities";
import { IGridData, IResponseJson, ISection } from "./StoreSectionThreeTabController.web";
export const configJSON = require("../../src/config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    marginEnd: string,
    deleteBannerBtn: string,
    spaceTop: string,
    bigHeader: string,
    errorMessage: string,
    gridLabel: string,
    boxButton: string,
    inlineErrorBox: string,
    hideGridImageInput: string,
    endJustifyBtn: string,
    dynamicWidthContainer: string,
    secondaryHeader: string,
    resizableImage: string,
    firstPadding: string,
    errorText: string,
    flexibleUploadBtn: string,
    buttonInformation: string,
    endFlexContainer: string,
    firstMargin: string,
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  sectionTwoType: string,
  gridOneNameInput: string,
  gridTwoNameInput: string,
  gridOneImgPreview: string | null,
  gridTwoImgPreview: string | null,
  gridOneLinkInput: string,
  gridTwoLinkInput: string,
  gridOneErr: string,
  gridTwoErr: string,
  gridOneImageInput: string,
  gridTwoImageInput: string,
  gridOneLinkErr: string,
  gridTwoLinkErr: string,
  showSection2Grid1: boolean,
  showSection2Grid2: boolean,
  showNextErrMsg: string,
  sectionTwoId: string,
  idOne: string,
  nameError: string,
  idTwo: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StoreSectionTwoTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  secTwoGridOneImgRef: React.RefObject<HTMLInputElement>;
  secTwoGridTwoImgRef: React.RefObject<HTMLInputElement>;
  createSecTwoGridOneApiCallId: string = "";
  createSecTwoGridTwoApiCallId: string = "";
  getSectionTwoDetailsApiCallId: string = ""
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
        sectionTwoType: "2 grid layout",
        gridOneNameInput: "",
        gridOneImgPreview: "",
        gridOneLinkInput: "",
        gridOneErr: "",
        gridOneImageInput: "",
        gridTwoNameInput: "",
        gridTwoImgPreview: "",
        gridTwoLinkInput: "",
        gridTwoErr: "",
        gridTwoImageInput: "",
        gridOneLinkErr: "",
        gridTwoLinkErr: "",
        showSection2Grid1: true,
        showSection2Grid2: false,
        showNextErrMsg: "",
        idOne: "",
        sectionTwoId: "",
        nameError: "",
        idTwo: ""
    };

    this.secTwoGridOneImgRef = createRef();
    this.secTwoGridTwoImgRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.createSecTwoGridOneApiCallId) {
        this.handleFirstGridResponse(responseJson);
      }
      if (apiRequestCallId === this.createSecTwoGridTwoApiCallId) {
        this.handleSecondGridResponse(responseJson);
      }
      if (apiRequestCallId === this.getSectionTwoDetailsApiCallId) {
        this.handleSectionResponse(responseJson);
      }
    }
  }

  handleSecondGridResponse = (responseJson:IGridData) => {
    if(responseJson.data) {
      this.setState({showSection2Grid2: false, showSection2Grid1: false, showNextErrMsg: ""})
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.ChangeActiveTabStore), { activeTab: "productListing", activeScreen: "productListing" });
      this.send(message)
    }
  }

  handleFirstGridResponse = (responseJson: IGridData) => {
    if (responseJson.data) {
      this.setState({ showSection2Grid2: true, showSection2Grid1: false, showNextErrMsg: "", sectionTwoId: responseJson.data.id });
      this.getSectionTwoDetails(responseJson.data.attributes.store_id);
    }
  }

  handleSectionResponse = (responseJson:IResponseJson) => {
    if (responseJson.data) {
      const sectionData = responseJson.data.filter((item: ISection) => {
        return item.attributes.section_name === "section_2";
      });
      if (sectionData[0]) {
        const { id, grid_name, grid_image, grid_url } = sectionData[0].attributes.store_section_grids[0].data.attributes;
        this.setState({
          sectionTwoId: sectionData[0].id,
          gridOneNameInput: grid_name,
          gridOneImgPreview: grid_image,
          gridOneLinkInput: grid_url,
          idOne: id,
        });
        if (sectionData[0].attributes?.store_section_grids[1]?.data.attributes) {
          const { id, grid_name, grid_image, grid_url } = sectionData[0].attributes.store_section_grids[1].data.attributes;
          this.setState({
            sectionTwoId: sectionData[0].id,
            gridTwoNameInput: grid_name,
            gridTwoImgPreview: grid_image,
            gridTwoLinkInput: grid_url,
            idTwo: id,
          });
        }
      }
    }
  }

  handleGridNameChange = (input: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    if(input === "gridOneNameInput") {
      this.setState({ gridOneNameInput : event.target.value})
      if(!configJSON.gridNameRegex.test(name)) {
      this.setState({ nameError: configJSON.nameValidateText})
      }
      else this.setState({ nameError: ''})
    }
    if(input === "gridTwoNameInput") {
      this.setState({ gridTwoNameInput : event.target.value})
      if(!configJSON.gridNameRegex.test(name)) {
        this.setState({ nameError: configJSON.nameValidateText})
      }
      else this.setState({ nameError: ''})
    }
  }

  handleUploadSection2GridImage = (input:string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const targetValue = event.target as HTMLInputElement;
    const file: File = (targetValue.files as FileList)[0];
    if (!file) return;
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > 5) {
        if(input === "gridOneImageInput") {
            return this.setState({ gridOneErr: configJSON.imageSizeErrorText });
        }
        if(input === "gridTwoImageInput") {
            return this.setState({ gridTwoErr: configJSON.imageSizeErrorText });
        }
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if(input === "gridOneImageInput") {
        return this.setState({
            gridOneImgPreview: reader.result as string,
            gridOneErr: ""
          });
      } 
      if(input === "gridTwoImageInput") {
        return this.setState({
            gridTwoImgPreview: reader.result as string,
            gridTwoErr: ""
          });
      }  
    };
    reader.readAsDataURL(file);

    this.setState(({ [input]: file } as unknown) as Pick<S, keyof S>);
  };

  handleOpenGridImage = (input: string) => {
    if(input === "gridOneImageInput") {
        if (this.secTwoGridOneImgRef && this.secTwoGridOneImgRef.current) {
            return this.secTwoGridOneImgRef.current.click();
        }
    }
    if(input ==="gridTwoImageInput") {
        if (this.secTwoGridTwoImgRef && this.secTwoGridTwoImgRef.current) {
            return this.secTwoGridTwoImgRef.current.click();
        }
    }
  }

  handleRemoveGridImage = (input: string) => {
    if(input === "gridOneImageInput") {
        this.setState({gridOneImgPreview: null, gridOneImageInput: "" })
    }
    else this.setState({gridTwoImgPreview: null, gridTwoImageInput: "" })
  }

  handleGridLink = (input: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const gridLink = event.target.value
    this.setState({ [input]: gridLink } as unknown as Pick<S, keyof S>);
    const urlPattern = /^(https:\/\/|www.)/;

    if (gridLink === '' || !urlPattern.test(gridLink)) {
        if(input === "gridOneLinkInput") {
            this.setState({ gridOneLinkErr: configJSON.validUrlErrorText });
        }
        if(input === "gridTwoLinkInput") {
            this.setState({ gridTwoLinkErr: configJSON.validUrlErrorText });
        }
      
    } else {
        if(input === "gridOneLinkInput") {
            this.setState({ gridOneLinkErr: "" });
        } 
        else this.setState({ gridTwoLinkErr: "" });
    }
  }

  handleSec2Grid1Next = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const {gridOneNameInput, gridOneImgPreview, gridOneLinkInput} = this.state;
    if(!gridOneNameInput || !gridOneImgPreview || !gridOneLinkInput) {
        this.setState({showNextErrMsg : configJSON.fillAllFieldsText})
    }
    else {
      this.createSectionTwoGridOne()
    }
  }

  handleSec2Grid2Next = () => {
    const {gridTwoNameInput, gridTwoImgPreview, gridTwoLinkInput} = this.state;
    if(!gridTwoNameInput || !gridTwoImgPreview || !gridTwoLinkInput) {
        this.setState({showNextErrMsg : configJSON.fillAllFieldsText})
    }
    else {
        this.createSectionTwoGridTwo()
    }
   }

   async componentDidMount(): Promise<void> {
    const storeId = await getStorageData("store_id");
    if (storeId) {
      this.getSectionTwoDetails(storeId);
    }
  }

  getSectionTwoDetails = async (id: string) => {
    const headers = { token: await getStorageData("authToken") };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSectionTwoDetailsApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + id + "/store_dashboard_sections");
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(message.id, message);
  };

   createSectionTwoGridOne = async() => {
    const storeId = await getStorageData("store_id")
    const endpoint = this.state.sectionTwoId ? `/store_dashboard_sections/${this.state.sectionTwoId}` : "/store_dashboard_sections"
    const apiMethod = this.state.sectionTwoId ? configJSON.patchMethod : configJSON.postMethod
    const headers = { token: await getStorageData("authToken")  };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createSecTwoGridOneApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint );
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name","section_2");
    formData.append("section_type",'2_grids_layout');
    if(this.state.idOne) {
      formData.append("store_section_grids_attributes[0][id]", this.state.idOne);
    }
    formData.append("store_section_grids_attributes[0][grid_no]", 'grid_1');
    formData.append("store_section_grids_attributes[0][grid_name]", this.state.gridOneNameInput)
    if(this.state.gridOneImageInput) {
      formData.append("store_section_grids_attributes[0][grid_image]", this.state.gridOneImageInput)
    }
    formData.append("store_section_grids_attributes[0][grid_url]", this.state.gridOneLinkInput)
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(reqMessage.id, reqMessage); 
   }

   createSectionTwoGridTwo = async () => {
    const storeId = await getStorageData("store_id");
    const endpoint = this.state.idTwo ? `/store_dashboard_sections/${this.state.sectionTwoId}/store_section_grids/${this.state.idTwo}` : `/store_dashboard_sections/${this.state.sectionTwoId}/store_section_grids`;
    const apiMethod = this.state.idTwo ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const gridTwoMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createSecTwoGridTwoApiCallId = gridTwoMessage.messageId;
    gridTwoMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint);
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name", "section_2");
    formData.append("section_type", "2_grids_layout");
    formData.append("grid_no", "grid_2");
    formData.append("grid_name", this.state.gridTwoNameInput);
    if (this.state.gridTwoImageInput) {
      formData.append("grid_image", this.state.gridTwoImageInput);
    }
    formData.append("grid_url", this.state.gridTwoLinkInput);
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(gridTwoMessage.id, gridTwoMessage);
  };
  //Customizable Area End
}