// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { createRef } from "react";
import { getStorageData } from "../../../../framework/src/Utilities";
import { IGridData, IResponseJson, ISection } from "./StoreSectionThreeTabController.web";
export const configJSON = require("../../src/config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    topMargin: string;
    gridImageLabel: string;
    gridImageInput: string;
    gridPreviewContainer: string;
    gridPreviewImage: string;
    removeBannerButton: string;
    uploadButton: string;
    buttonBox: string;
    buttonInfo: string;
    flexEndContainer: string;
    btnPosition: string;
    error: string;
    header: string;
    headerTwo: string;
    margin: string;
    padding: string;
    errorOne: string;
    commonErrorBox: string;
    errMargin: string;
  };
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  sectionType: string;
  gridOneName: string;
  gridTwoName: string;
  gridThreeName: string;
  gridOnePreview: string | null;
  gridTwoPreview: string | null;
  gridThreePreview: string | null;
  gridOneLink: string;
  gridTwoLink: string;
  gridThreeLink: string;
  gridOneError: string;
  gridTwoError: string;
  gridThreeError: string;
  gridOneImage: string;
  gridTwoImage: string;
  gridThreeImage: string;
  gridOneLinkError: string;
  gridTwoLinkError: string;
  gridThreeLinkError: string;
  showGrid1: boolean;
  showGrid2: boolean;
  showGrid3: boolean;
  showErrMsg: string;
  gridNameError: string;
  sectionId: string;
  gridOneId: string;
  gridTwoId: string;
  gridThreeId: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StoreSectionOneTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  gridOneImgRef: React.RefObject<HTMLInputElement>;
  gridTwoImgRef: React.RefObject<HTMLInputElement>;
  gridThreeImgRef: React.RefObject<HTMLInputElement>;
  createGridOneApiCallId: string = "";
  createGridTwoApiCallId: string = "";
  createGridThreeApiCallId: string = "";
  getSectionDetailsApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      sectionType: "3 grids layout",
      gridOneName: "",
      gridOnePreview: "",
      gridOneLink: "",
      gridOneError: "",
      gridOneImage: "",
      gridTwoName: "",
      gridTwoPreview: "",
      gridTwoLink: "",
      gridTwoError: "",
      gridTwoImage: "",
      gridThreeName: "",
      gridThreePreview: "",
      gridThreeLink: "",
      gridThreeError: "",
      gridThreeImage: "",
      gridOneLinkError: "",
      gridTwoLinkError: "",
      gridThreeLinkError: "",
      showGrid1: true,
      showGrid2: false,
      showGrid3: false,
      showErrMsg: "",
      gridNameError: "",
      sectionId: "",
      gridOneId: "",
      gridTwoId: "",
      gridThreeId: "",
    };

    this.gridOneImgRef = createRef();
    this.gridTwoImgRef = createRef();
    this.gridThreeImgRef = createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.createGridOneApiCallId) {
        this.handleGridOneResponse(responseJson);
      }
      if (apiRequestCallId === this.createGridTwoApiCallId) {
        this.handleGridTwoResponse(responseJson);
      }
      if (apiRequestCallId === this.createGridThreeApiCallId) {
        this.handleGridThreeResponse(responseJson);
      }
      if (apiRequestCallId === this.getSectionDetailsApiCallId) {
        this.handleSectionDetailsInfo(responseJson);
      }
    }
  }

  handleSectionDetailsInfo = (responseJson: IResponseJson) => {
    if (responseJson.data) {
      const data = responseJson.data.filter((item: ISection) => {
        return item.attributes.section_name === "section_1";
      });
      if (data[0]) {
        const { id, grid_name, grid_image, grid_url } = data[0].attributes.store_section_grids[0].data.attributes;
        this.setState({
          sectionId: data[0].id,
          gridOneName: grid_name,
          gridOnePreview: grid_image,
          gridOneLink: grid_url,
          gridOneId: id,
        });
        if (data[0].attributes?.store_section_grids[1]?.data.attributes) {
          const { id, grid_name, grid_image, grid_url } = data[0].attributes.store_section_grids[1].data.attributes;
          this.setState({
            sectionId: data[0].id,
            gridTwoName: grid_name,
            gridTwoPreview: grid_image,
            gridTwoLink: grid_url,
            gridTwoId: id,
          });
        }
        if (data[0].attributes?.store_section_grids[2]?.data.attributes) {
          const { id, grid_name, grid_image, grid_url } = data[0].attributes.store_section_grids[2].data.attributes;
          this.setState({
            sectionId: data[0].id,
            gridThreeName: grid_name,
            gridThreePreview: grid_image,
            gridThreeLink: grid_url,
            gridThreeId: id,
          });
        }
      }
    }
  };

  handleGridOneResponse = (responseJson: IGridData) => {
    if (responseJson.data) {
      this.setState({ showGrid2: true, showGrid1: false, showGrid3: false, showErrMsg: "", sectionId: responseJson.data.id });
      this.getSectionDetails(responseJson.data.attributes.store_id);
    }
  };

  handleGridTwoResponse = async (responseJson: IGridData) => {
    if (responseJson.data) {
      const storeId = await getStorageData("store_id");
      this.setState({ showGrid2: false, showGrid1: false, showGrid3: true, showErrMsg: "" });
      this.getSectionDetails(storeId);
    }
  };

  handleGridThreeResponse = (responseJson: IGridData) => {
    if (responseJson.data) {
      this.setState({ showGrid2: false, showGrid1: false, showGrid3: false, showErrMsg: "" });
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.ChangeActiveTabStore), { activeTab: "sectionTwo", activeScreen: "sectionTwo" });
      this.send(message);
    }
  };

  handleGridNameChange = (input: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (input === "gridOneName") {
      this.setState({ gridOneName: event.target.value });
      this.isNameValid(value);
    }
    if (input === "gridTwoName") {
      this.setState({ gridTwoName: event.target.value });
      this.isNameValid(value);
    }
    if (input === "gridThreeName") {
      this.setState({ gridThreeName: event.target.value });
    }
  };

  isNameValid = (value: string) => {
    const gridNameRegex = /^(?!\s)[a-zA-Z0-9&.,%()| -]{3,15}(?<!\s)$/;
    if (!gridNameRegex.test(value)) {
      this.setState({ gridNameError: configJSON.nameValidateText });
    } else this.setState({ gridNameError: "" });
  };

  handleUploadSectionOneGridImage = (input: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const imageValue = event.target as HTMLInputElement;
    const imageFile: File = (imageValue.files as FileList)[0];
    if (!imageFile) return;
    const sizeOfFile = imageFile.size / (1024 * 1024);
    if (sizeOfFile > 5) {
      this.setFileSizeErrors(input);
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if (input === "gridOneImage") {
        return this.setState({
          gridOnePreview: reader.result as string,
          gridOneError: "",
        });
      }
      if (input === "gridTwoImage") {
        return this.setState({
          gridTwoPreview: reader.result as string,
          gridTwoError: "",
        });
      } else
        this.setState({
          gridThreePreview: reader.result as string,
          gridThreeError: "",
        });
    };
    reader.readAsDataURL(imageFile);

    this.setState({ [input]: imageFile } as unknown as Pick<S, keyof S>);
  };

  setFileSizeErrors = (value: string) => {
    if (value === "gridOneImage") {
      return this.setState({ gridOneError: configJSON.imageSizeErrorText });
    }
    if (value === "gridTwoImage") {
      return this.setState({ gridTwoError: configJSON.imageSizeErrorText });
    } else return this.setState({ gridThreeError: configJSON.imageSizeErrorText });
  };

  handleOpenGridImage = (input: string) => {
    if (input === "gridOneImage") {
      if (this.gridOneImgRef && this.gridOneImgRef.current) {
        return this.gridOneImgRef.current.click();
      }
    }
    if (input === "gridTwoImage") {
      if (this.gridTwoImgRef && this.gridTwoImgRef.current) {
        return this.gridTwoImgRef.current.click();
      }
    } else if (this.gridThreeImgRef && this.gridThreeImgRef.current) {
      return this.gridThreeImgRef.current.click();
    }
  };

  handleRemoveGridImage = (input: string) => {
    if (input === "gridOneImage") {
      this.setState({ gridOnePreview: null, gridOneImage: "" });
    }
    if (input === "gridTwoImage") {
      this.setState({ gridTwoPreview: null, gridTwoImage: "" });
    } else this.setState({ gridThreePreview: null, gridThreeImage: "" });
  };

  handleGridLink = (input: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const gridLink = event.target.value;
    this.setState({ [input]: gridLink } as unknown as Pick<S, keyof S>);
    const urlPattern = /^(https:\/\/|www.)/;

    if (gridLink === "" || !urlPattern.test(gridLink)) {
      if (input === "gridOneLink") {
        this.setState({ gridOneLinkError: configJSON.validUrlErrorText });
      }
      if (input === "gridTwoLink") {
        this.setState({ gridTwoLinkError: configJSON.validUrlErrorText });
      }
      if (input === "gridThreeLink") {
        this.setState({ gridThreeLinkError: configJSON.validUrlErrorText });
      }
    } else {
      if (input === "gridOneLink") {
        this.setState({ gridOneLinkError: "" });
      }
      if (input === "gridTwoLink") {
        this.setState({ gridTwoLinkError: "" });
      } else this.setState({ gridThreeLinkError: "" });
    }
  };

  handleGrid1Next = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const { gridOneName, gridOnePreview, gridOneLink, gridNameError } = this.state;
    if (!gridOneName || !gridOnePreview || !gridOneLink || gridNameError) {
      this.setState({ showErrMsg: configJSON.fillAllFieldsText });
    } else {
      this.createSectionOneGridOne();
    }
  };

  handleGrid2Next = () => {
    const { gridTwoName, gridTwoPreview, gridTwoLink, gridNameError } = this.state;
    if (!gridTwoName || !gridTwoPreview || !gridTwoLink || gridNameError) {
      this.setState({ showErrMsg: configJSON.fillAllFieldsText });
    } else {
      this.createSectionOneGridTwo();
    }
  };

  handleGrid3Next = () => {
    const { gridThreeName, gridThreePreview, gridThreeLink, gridNameError } = this.state;
    if (!gridThreeName || !gridThreePreview || !gridThreeLink || gridNameError) {
      this.setState({ showErrMsg: configJSON.fillAllFieldsText });
    } else {
      this.createSectionOneGridThree();
    }
  };

  createSectionOneGridOne = async () => {
    const storeId = await getStorageData("store_id");
    const endpoint = this.state.sectionId ? `/store_dashboard_sections/${this.state.sectionId}` : "/store_dashboard_sections";
    const apiMethod = this.state.sectionId ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createGridOneApiCallId = reqMessage.messageId;
    reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name", "section_1");
    formData.append("section_type", "3_grids_layout");
    if (this.state.gridOneId) {
      formData.append("store_section_grids_attributes[0][id]", this.state.gridOneId);
    }
    formData.append("store_section_grids_attributes[0][grid_no]", "grid_1");
    formData.append("store_section_grids_attributes[0][grid_name]", this.state.gridOneName);
    if (this.state.gridOneImage) {
      formData.append("store_section_grids_attributes[0][grid_image]", this.state.gridOneImage);
    }
    formData.append("store_section_grids_attributes[0][grid_url]", this.state.gridOneLink);
    reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  createSectionOneGridTwo = async () => {
    const storeId = await getStorageData("store_id");
    const endpoint = this.state.gridTwoId ? `/store_dashboard_sections/${this.state.sectionId}/store_section_grids/${this.state.gridTwoId}` : `/store_dashboard_sections/${this.state.sectionId}/store_section_grids`;
    const apiMethod = this.state.gridTwoId ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const gridTwoMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createGridTwoApiCallId = gridTwoMessage.messageId;
    gridTwoMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint);
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name", "section_1");
    formData.append("section_type", "3_grids_layout");
    formData.append("grid_no", "grid_2");
    formData.append("grid_name", this.state.gridTwoName);
    if (this.state.gridTwoImage) {
      formData.append("grid_image", this.state.gridTwoImage);
    }
    formData.append("grid_url", this.state.gridTwoLink);
    gridTwoMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(gridTwoMessage.id, gridTwoMessage);
  };

  createSectionOneGridThree = async () => {
    const storeId = await getStorageData("store_id");
    const endpoint = this.state.gridThreeId ? `/store_dashboard_sections/${this.state.sectionId}/store_section_grids/${this.state.gridThreeId}` : `/store_dashboard_sections/${this.state.sectionId}/store_section_grids`;
    const apiMethod = this.state.gridThreeId ? configJSON.patchMethod : configJSON.postMethod;
    const headers = { token: await getStorageData("authToken") };
    const gridThreeMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createGridThreeApiCallId = gridThreeMessage.messageId;
    gridThreeMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + endpoint);
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), apiMethod);
    const formData = new FormData();
    formData.append("section_name", "section_1");
    formData.append("section_type", "3_grids_layout");
    formData.append("grid_no", "grid_3");
    formData.append("grid_name", this.state.gridThreeName);
    if (this.state.gridThreeImage) {
      formData.append("grid_image", this.state.gridThreeImage);
    }
    formData.append("grid_url", this.state.gridThreeLink);
    gridThreeMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    runEngine.sendMessage(gridThreeMessage.id, gridThreeMessage);
  };

  async componentDidMount(): Promise<void> {
    const storeId = await getStorageData("store_id");
    if (storeId) {
      this.getSectionDetails(storeId);
    }
  }

  getSectionDetails = async (id: string) => {
    const headers = { token: await getStorageData("authToken") };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSectionDetailsApiCallId = message.messageId;
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + id + "/store_dashboard_sections");
    message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(message.id, message);
  };
  //Customizable Area End
}
