import React from "react";

// Customizable Area Start
import { Box, Modal, IconButton, Input, InputAdornment, InputLabel } from "@material-ui/core";
import { CustomFPWFormControl, CustomFPWTextField, CustomFWPBox, CustomFWPSubmitButton, CustomModalFWPBox, NoDesignButton, VisibilityOffSvg } from "./CustomElementStyling";
import ClearIcon from "@material-ui/icons/Clear";
import { success, email, background } from "./assets";
import Visibility from "@material-ui/icons/Visibility";
import { errorImage } from "../../bulkuploading/src/assets";
// Customizable Area End

import SellerForgotPasswordController, { Props } from "./SellerForgotPasswordController";

export default class SearchBrand extends SellerForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ShowError = () => {
    return (
      <>
        {this.state.errMsg && (
          <Box sx={webStyle.errorMsgContainer}>
            <Box sx={{ marginRight: "5px" }}>
              <img src={errorImage} />
            </Box>
            <Box sx={{ fontSize: 14, color: "#D74D4D", fontFamily: `"Lato", sans-serif`, fontWeight: 700 }}>{this.state.errMsg}</Box>
          </Box>
        )}
      </>
    );
  };
  CloseButton = (ref: string) => {
    return (
      <Box sx={webStyle.closeButton}>
        <IconButton data-test-id={ref} onClick={this.handleCloseFWPModal}>
          <ClearIcon style={{ color: "#3E3E3E" }} />
        </IconButton>
      </Box>
    );
  };
  ForgotPwModal = () => {
    return (
      <Modal data-test-id="forgotPassModal" open={this.state.showForgotPwModal} hideBackdrop>
        <Box sx={webStyle.mainContainer}>
          <Box sx={webStyle.blurredBackground}></Box>
          <Box sx={webStyle.modalContainer}>
            {this.CloseButton("forgotPasswordRef")}
            <Box sx={fontStyles.h1}>Forgot your password?</Box>
            <Box sx={webStyle.subHeaderBox}>
              <Box style={{ marginRight: "5px",fontWeight:"bold",fontFamily: `"Lato", sans-serif` }} sx={fontStyles.h2}>
                {"Enter your email address and we'll send you a link to reset your password"}
              </Box>
            </Box>
            <CustomFPWTextField id="forgotPass" onKeyDown={this.handleKeyDown} data-test-id="emailRef" label="Email" fullWidth style={{ marginTop: "40px" }} value={this.state.email} onChange={event => this.handleOnInputChange("email", event.target.value)} />
            <Box sx={{ height: "65px", paddingTop: "30px" }}>{this.ShowError()}</Box>
            <Box sx={{ position: "absolute", width: "calc(100% - 100px)", bottom: 50 }}>
              <CustomFWPSubmitButton data-test-id="submitRef" onClick={this.handleOnSubmit} disabled={this.state.isFetching}>
                Submit
              </CustomFWPSubmitButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  VerifyYourEmail = () => {
    return (
      <Modal open={this.state.showVerifyEmailModal} hideBackdrop>
        <CustomFWPBox sx={webStyle.mainContainer}>
          <Box sx={webStyle.blurredBackground}></Box>
          <CustomModalFWPBox>
            {this.CloseButton("verifyEmailRef")}
            <CustomFWPBox
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                height: "100%",
                alignItems: "center"
              }}
            >
              <CustomFWPBox
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  marginBottom: "20px"
                }}
              >
                <img src={email} alt="email image" />
              </CustomFWPBox>
              <CustomFWPBox sx={{ marginBottom: "15px", fontWeight: "bold", fontSize: "20px", color: "#565656" }}>Password Reset link sent !</CustomFWPBox>
              <CustomFWPBox sx={{ marginBottom: "10px", fontSize: "12px", color: "#565656", fontWeight: "400" }}>Please check your inbox for the Password Reset link</CustomFWPBox>
              <CustomFWPBox sx={{ marginBottom: "35px", fontSize: "12px", color: "#565656" }}>Email sent to {this.state.email}</CustomFWPBox>
              <CustomFWPBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "fit-content",
                  fontSize: "12px",
                  color: "#565656",
                  fontWeight: "400",
                  alignItems: "center"
                }}
              >
                <CustomFWPBox>Did not get a link ?</CustomFWPBox>
                <CustomFWPBox>
                  <NoDesignButton data-test-id="resendNowEmailRef" style={{ marginLeft: "5px", fontWeight: "bold", color: "#FF6008", fontSize: "13px" }} onClick={() => this.resendFWPEmailLink()} disabled={this.state.isFetching}>
                    Resend now
                  </NoDesignButton>
                </CustomFWPBox>
              </CustomFWPBox>
              <CustomFWPBox sx={{ height: "60px" }}>
                {this.ShowError()}
                {this.state.successMsg && this.state.isResendPw && <Box sx={{ fontSize: 14, color: "#44cf5b", fontFamily: `"Lato", sans-serif`, fontWeight: 700, textAlign:'center' }}>{this.state.successMsg}</Box>}
              </CustomFWPBox>
              <CustomFWPBox sx={{ fontSize: "10px", color: "#565656", fontWeight: "400" }}>{"Can't find? Please check your spam folder."}</CustomFWPBox>
            </CustomFWPBox>
          </CustomModalFWPBox>
        </CustomFWPBox>
      </Modal>
    );
  };
  SetNewPasswordModal = () => {
    return (
      <Modal open={this.state.showChangePwModal} hideBackdrop>
        <Box sx={webStyle.mainContainer} style={{ background: `url('${background}')` }}>
          <Box sx={webStyle.modalContainer} style={{ height: 340 }}>
            {this.CloseButton("setNewPwModalRef")}
            <Box sx={fontStyles.h1}>Set new password</Box>
            <Box sx={webStyle.subHeaderBox}>
              <Box style={{ margin: "0px 5px 15px 0px" ,color:"#575757",fontFamily: `"Lato", sans-serif`}} sx={fontStyles.h2}>
                {"Password should be at least eight characters and include both numbers and letters in both upper- and lowercase."}
              </Box>
            </Box>
            <CustomFPWFormControl>
              <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
              <Input
              onKeyDown={this.handleKeySetDown}
                id="standard-adornment-password"
                data-test-id="passwordInputRef"
                type={this.state.showNewPass ? "text" : "password"}
                onChange={event => this.handleOnInputChange("new_password", event.target.value)}
                value={this.state.new_password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton data-test-id="showPasswordRef" aria-label="toggle password visibility" onClick={() => this.handleShowNewPass()}>
                      {this.state.showNewPass ? <Visibility className="rotateIcon" /> : <VisibilityOffSvg />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </CustomFPWFormControl>
            <CustomFPWFormControl>
              <InputLabel htmlFor="standard-adornment-password">Repeat Password</InputLabel>
              <Input
              onKeyDown={this.handleKeySetDown}
                id="standard-adornment-password"
                data-test-id="confirmPasswordInputRef"
                type={this.state.showConfirmPass ? "text" : "password"}
                onChange={event => this.handleOnInputChange("confirm_password", event.target.value)}
                value={this.state.confirm_password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton data-test-id="showPasswordRef" aria-label="toggle password visibility" onClick={() => this.handleShowConfirmPass()}>
                      {this.state.showConfirmPass ? <Visibility className="rotateIcon" /> : <VisibilityOffSvg />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </CustomFPWFormControl>
            <Box sx={{ height: "65px" }}>{this.ShowError()}</Box>
            <Box sx={{ position: "absolute", width: "calc(100% - 100px)", bottom: 50 }}>
              <CustomFWPSubmitButton data-test-id="saveRef" onClick={this.handleOnSave} disabled={this.state.isFetching}>
                Save
              </CustomFWPSubmitButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  SuccessModal = () => {
    return (
      <Modal open={this.state.showChangePwSuccessModal} hideBackdrop>
        <Box sx={webStyle.mainContainer} style={{ background: `url('${background}')` }}>
          <Box sx={webStyle.blurredBackground}></Box>
          <Box sx={webStyle.modalContainer}>
            {this.CloseButton("successModalRef")}
            <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <Box sx={webStyle.verifiedImageWrapper}>
                <Box sx={webStyle.verifiedImageContainer}>
                  <img src={success} alt="success image" />
                </Box>
                <CustomFWPBox sx={{ margin: "10px 0px 20px 0px", fontWeight: "bold", fontSize: "20px", color: "#565656" }}>Password changed successfully</CustomFWPBox>
                <CustomFWPBox sx={{ marginBottom: "30px", fontSize: "12px", color: "#565656" }}>You are being redirected.</CustomFWPBox>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        {this.ForgotPwModal()}
        {this.VerifyYourEmail()}
        {this.SuccessModal()}
        {this.SetNewPasswordModal()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  blurredBackground: {
    position: "absolute",
    zIndex: -1,
    backgroundColor: "rgba(0,0,0,0.1)",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)"
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "none",
    width: "100%",
    height: "100vh",
    overflow: "hidden"
  },
  verifiedImageContainer: {
    width: "100px",
    height: "100px",
    marginBottom: "24px"
  },
  closeButton: {
    position: "absolute",
    top: "28px",
    right: "28px",
    boxShadow: "0px 3px 6px rgba(86, 86, 86, 0.1)",
    borderRadius: "100%"
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "none"
  },
  subHeaderBox: {
    marginTop: "22px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  modalContainer: {
    display: "flex",
    width: "495px",
    padding: "50px",
    height: 400,
    background: "white",
    borderRadius: "8px",
    flexDirection: "column",
    margin: "15px",
    position: "relative"
  },
  verifiedImageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  errorMsgContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    alignItems: "center",
    marginTop: "5px"
  },
  forgotPwBox: {
    marginTop: "35px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row"
  }
};

const fontStyles = {
  h1: {
    fontSize: "20px",
    fontWeight: "700",
    color: "#3E3E3E",
    fontFamily: `"Lato", sans-serif`
  },
  h2: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#3E3E3E",
    fontFamily: `"Lato", sans-serif`
  },
  h3: {
    fontSize: "13px",
    fontWeight: "700",
    color: "#FF6008",
    fontFamily: `"Lato", sans-serif`
  },
  h4: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#061B6F",
    fontFamily: `"Lato", sans-serif`
  },
  h5: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#061B6F",
    fontFamily: `"Lato", sans-serif`
  },
  h6: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#565656",
    fontFamily: `"Lato", sans-serif`
  }
};
// Customizable Area End
