// Customizable Area Start
import React from "react";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import TemplatePreviewController, { ISection, Product, Props } from "./TemplatePreviewController.web";
import { CustomWhiteButton, OrangeButton } from "../CustomizedElementStyle";
import { logoImage, userImg } from "../assets";
import KeyboardArrowRightIcon from "@material-ui/icons//KeyboardArrowRight";
import { starIcon } from "../../../catalogue/src/assets";
import styled from "@material-ui/styles/styled";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { ExpressLogo } from "../../../productquickview/src/assets";
import TemplateFooterWeb from "../../../../components/src/TemplateFooter.web";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { byezzylogo } from "../../../contactus/src/assets";

export const CustomProductSliderTwo = styled(Slider)({
  "& .slick-slide > div > div": {
    borderRadius: 10,
  },
  "& .slick-slide > div": {
    display: "flex",
    margin: "0 10px",
    gap: 20,
  },
  "& .slick-slide": {
    height: "unset",
  },
  "& .slick-prev": {
    top: -25,
    left: "unset",
    right: 50,
    color: "#000",
    backgroundColor: "transparent",
    padding: "5px",
    fontSize: "14px",
    borderRadius: "50%",
  },
  "& .slick-next": {
    top: -25,
    left: "unset",
    right: 0,
    color: "#000",
    backgroundColor: "transparent",
    padding: "5px",
    fontSize: "14px",
    borderRadius: "50%",
    "& >path": {},
  },
  "& .slick-prev::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)",
  },
  "& .slick-next::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)",
  },
});
// Customizable Area End

class TemplatePreview extends TemplatePreviewController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  sliderSettings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "200px",
    slidesToShow: 2.5,
    speed: 500,
    rows: 1,
    slidesPerRow: 2,
    padding: "10px 0px",
    prevArrow: <ArrowBackIos className={this.props.classes.arrow} />,
    nextArrow: <ArrowForwardIos className={this.props.classes.arrow} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 0.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={classes.modal} data-test-id="component">
          <Box className={classes.modalWrapper}>
              
            <Grid container className={classes.headerContainer}>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <img className={classes.logoImg} src={byezzylogo} alt="byezzy_logo" />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Box className={classes.searchBox}>https://byezzy.com/newstore_apple</Box>
              </Grid>
              <Grid item xs={12} sm={7} md={7} lg={7} className={classes.rightSideHeader}>
                <Box className={classes.language}>
                  <Typography className={classes.headerText}>Language</Typography>
                  <Typography className={classes.headerText}>English</Typography>
                </Box>
                <Box className={classes.wishlistContainer}>
                  <FavoriteBorderIcon className={classes.heartIcon} />
                  <Box className={classes.wishlistBox}>
                    <Typography className={classes.headerText}>Wishlist</Typography>
                    <Typography className={classes.headerText}>My Items</Typography>
                  </Box>
                </Box>
                <Box className={classes.wishlistContainer}>
                  <Box className={classes.userIcon}>
                    <img src={userImg} className={classes.userImg} />
                  </Box>
                  <Box className={classes.wishlistBox}>
                    <Typography className={classes.headerText}>Sign In</Typography>
                    <Typography className={classes.headerText}>Account</Typography>
                  </Box>
                </Box>
                <Box className={classes.cart}>
                  <ShoppingCartOutlinedIcon className={classes.shopIcon} />
                  <Box className={classes.count}>2</Box>
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.storeSection}>
              <Box className={classes.position}>
                {this.state.logo && 
                <Box className={classes.storeBox}>
                  <Box className={classes.logoContainer}>
                    <img src={this.state.logo} alt="img" className={classes.logo} />
                    <Typography variant="h6" className={classes.logoName}>
                      Apple
                    </Typography>
                  </Box>
                </Box>
                }
                {this.state.coverImg && 
                  <img src={this.state.coverImg} alt="cover_img" className={classes.storeCover} />
                }
              </Box>
            </Box>
            <Box className={classes.navBar}>
              <Box className={classes.menuContainer}>
                <Typography className={`${classes.menu} ${classes.activeMenu}`}>Home</Typography>
                {this.state.menuList.map((item: any | undefined) => {
                  return (
                    <>
                      <Typography data-test-id="header-home" className={classes.menu}>{item?.length > 20 ? item.slice(0, 30) + '...' : item}</Typography>
                    </>
                  );
                })}
              </Box>
            </Box>
            {this.state.bannerImage && (
              <Box className={classes.bannerSec}>
                <img className={classes.bannerImg} src={this.state.bannerImage} alt="banner_img" />
                <Typography className={classes.bannerName}>{this.state.bannerName}</Typography>
              </Box>
            )}
            {this.state.sectionOneInfo && (
              <Grid container className={classes.sectionOneContainer}>
                {this.state.sectionOneInfo.map((item: ISection) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} spacing={2} className={classes.card}>
                        <img src={item.data.attributes.grid_image} alt="img" className={classes.sectionOneImg} />
                        <Typography gutterBottom className={classes.cardText} variant="h5" component="h2" align="center">
                          {item.data.attributes.grid_name}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            )}
            {this.state.sectionTwoInfo && (
              <Grid container className={classes.sectionTwoContainer}>
                {this.state.sectionTwoInfo.map((item: ISection) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} spacing={2} className={classes.cardTwo}>
                      <img className={classes.gridImage} src={item.data.attributes.grid_image} alt="img" />
                      <Box className={classes.sectionTwoText}>
                        <Typography className={classes.name}>{item.data.attributes.grid_name}</Typography>
                        <Box className={classes.linkBox}>
                          <Typography className={classes.linkText}>
                            Learn more <KeyboardArrowRightIcon className={classes.icon} />{" "}
                            <span className={classes.buy}>
                              Buy{" "}
                              <Typography>
                                <KeyboardArrowRightIcon className={classes.icon} />
                              </Typography>
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <CustomWhiteButton className={classes.exploreBtn}>Explore</CustomWhiteButton>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <CustomProductSliderTwo {...this.sliderSettings} className={classes.sliderWrap}>
              {this.state.productList && this.state.productList.map((item: Product, index: number) => {
                const  {product_image, product_rating, final_price, stroked_price, offer_percentage} = item.data?.attributes
                const {product_title } = item.data?.attributes?.product_content?.product_attributes
                return(
                <div key={index}>
                  <div className={classes.sliderSec}>
                    <img src={product_image} alt={"image"} className={classes.sliderImg} />
                    <OrangeButton className={classes.newButton}>NEW</OrangeButton>
                  </div>
                  <Box className={classes.desc}>
                    <Typography className={classes.itemName}>{product_title}</Typography>
                    <Typography className={classes.priceBox}>
                      EAD <span className={classes.cardPriceText}>{final_price}</span>
                    </Typography>
                    {Number(stroked_price) > 0 ?
                    <Box className={classes.priceWrapper}>
                      <Typography className={classes.price}>{stroked_price}</Typography>
                      <Typography className={classes.discountText}>{Number(offer_percentage) > 0 ? `${offer_percentage}% OFF` : ''}</Typography>
                    </Box>
                    : <Box className={classes.priceMargin}>
                    <Typography className={classes.price}>{""}</Typography>
                    <Typography className={classes.discountText}>{""}</Typography>
                  </Box>
              }
                    <Box className={classes.bottom}>
                      <img className={classes.expressImg} src={ExpressLogo} alt="express_img" />
                      <Box className={classes.flex}>
                        <Typography className={classes.rating}>
                          {product_rating?.average_rating || 4.8}{" "}
                          <span className={classes.starSec}>
                            <img src={starIcon} alt="start" />
                          </span>
                        </Typography>
                        <Typography className={classes.offerPrice}>{product_rating?.total_reviews || 26}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                )
              })}
            </CustomProductSliderTwo>
            {this.state.sectionThreeInfo && 
              <Grid container spacing={3}>
                {this.state.sectionThreeInfo.map((item: ISection) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box className={classes.productCard}>
                        <img className={classes.sectionThreeImg} src={item.data.attributes.grid_image} alt={"img"} />
                        <Box className={classes.rightSide}>
                          <Box className={classes.right}>
                            <Typography className={classes.gridName}>{item.data.attributes.grid_name}</Typography>
                            <CustomWhiteButton className={classes.shopBtn}>Shop Now</CustomWhiteButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            }
            <TemplateFooterWeb categoryList={this.state.categoryList} />
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = {
  modal: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center ",
    background: "rgba(0,0,0,0.1)",
  },
  modalWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    background: "white",
    overflow: "hidden",
  },
  cross: {
    marginBottom: "21px",
    fontFamily: `"Lato", sans-serif`,
    textAlign: "right" as const,
  },
  storeSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as const,
    marginTop: "50px",
  },
  position: {
    position: "relative" as const,
  },
  storeBox: {
    display: "inline-block",
    position: "absolute" as const,
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    top: "-60px",
    left: "100px",
    color: "#fff",
    textAlign: "center" as const,
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  logo: {
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    objectFit: "cover" as const,
    borderRadius: "50%",
    padding: "10px",
  },
  logoName: {
    marginTop: "10px",
    color: "#fff",
    fontSize: "28px",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
  },
  storeCover: {
    width: "100%",
    height: "225px",
    objectFit: "cover" as const,
    borderRadius: "6px",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
  },
  navBar: {
    backgroundColor: "#ff6008",
    borderRadius: "8px",
    margin: "30px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  menuContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  menu: {
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 500,
    color: "#fff",
    textTransform: "uppercase" as const,
    margin: "0 40px",
  },
  activeMenu: {
    fontWeight: 700,
    textDecoration: "underline",
  },
  bannerName: {
    fontSize: "30px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    color: "#fff",
    position: "absolute" as const,
    top: "6%",
    left: "45%",
  },
  bannerSec: {
    width: "100%",
    borderRadius: "6px",
    height: "500px",
    position: "relative" as const,
  },
  bannerImg: {
    height: "500px",
    width: "100%",
    borderRadius: "6px",
    objectFit: "fill" as const,
  },
  card: {
    maxHeight: 180,
    position: "relative" as const,
  },
  sectionOneImg: {
    width: "100%",
    borderRadius: "6px",
    maxHeight: 180,
    objectFit: "fill" as const,
  },
  cardText: {
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    position: "absolute" as const,
    top: "42%",
    right: "10%",
  },
  sectionOneContainer: {
    margin: "30px 0",
    gridGap: "12px",
    flexWrap: "nowrap" as const,
  },
  sectionTwoContainer: {
    gridGap: "20px",
    flexWrap: "nowrap" as const,
  },
  gridImage: {
    height: "400px",
    width: "100%",
    borderRadius: "6px",
    objectFit: "fill" as const,
  },
  cardTwo: {
    maxHeight: 400,
    position: "relative" as const,
  },
  name: {
    fontSize: "30px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    color: "#fff",
    position: "absolute" as const,
  },
  exploreBtn: {
    position: "absolute" as const,
    bottom: 0,
    right: 0,
    margin: "0 22px 22px 0",
    cursor: 'none'
  },
  linkText: {
    color: "#3794DB",
    fontSize: "13px",
    fontWeight: 600,
    fontFamily: "Lato, sans-serif",
    display: "flex",
    alignItems: "center",
  },
  linkBox: {
    position: "absolute" as const,
    top: "15%",
  },
  buy: {
    marginLeft: "16px",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: "#3794DB",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "3px",
  },
  sliderSec: {
    position: "relative" as const,
    height: "370px",
    padding: "0px",
    borderRadius: "6px",
    backgroundColor: "#F7F7F7",
  },
  newButton: {
    position: "absolute" as const,
    top: "15px",
    padding: "4px",
    color: "#ffffff",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "10px",
    fontWeight: 700 as const,
    right: "20px",
    cursor: 'none'
  },
  desc: {
    margin: "16px 0",
    textAlign: "left" as const,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "left",
  },
  itemName: {
    color: "#33333a",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "16px",
    fontWeight: 600 as const,
  },
  priceBox: {
    margin: "10px 0px 0px",
    color: "#ff6008",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
  },
  cardPriceText: {
    fontSize: "22px",
    fontWeight: 500 as const,
  },
  price: {
    color: "#666",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
    textDecoration: "line-through" as const,
  },
  discountText: {
    fontWeight: 500 as const,
    textDecoration: "none" as const,
    marginLeft: "10px",
  },
  rating: {
    padding: "2px 6px",
    display: "flex",
    alignItems: "center",
    borderRadius: "12px",
    backgroundColor: "#364789",
    color: "#ffffff",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
  },
  starSec: {
    marginLeft: "5px",
  },
  bottom: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  offerPrice: {
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    color: "#ff6008",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
  },
  sliderImg: {
    height: "80%",
    width: "80%",
    maxWidth:'80%',
    maxHeight: '80%',
    padding: "50px 20px",
    objectFit: "fill" as const,
  },
  sliderWrap: {
    margin: "60px -6px 30px",
  },
  categoryName: {
    background: "#fff",
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    display: "block",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    color: "#33333a",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap" as const,
    padding: "0 10px 20px 10px",
  },
  arrow: {
    color: "black",
    backgroundColor: "#fff",
    fontSize: "16px",
  },
  expressImg: {
    width: "70px",
  },
  productCard: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    borderRadius: "6px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease",
    position: "relative" as const,
    height: "400px",
  },
  sectionThreeImg: {
    width: "100%",
    height: "100%",
    borderRadius: "6px",
    objectFit: "fill" as const,
  },
  rightSide: {
    position: "absolute" as const,
    background: "rgba(0, 0, 0, 0.2)",
    color: "#f1f1f1",
    padding: "20px",
    top: 0,
    width: "50%",
    left: "50%",
    boxSizing: "border-box" as const,
    height: "100%",
    textAlign: "center" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
  },
  gridName: {
    fontSize: "32px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    color: "#fff",
    textAlign: "center" as const,
    margin: "8px 0",
  },
  right: {
    display: "flex",
    flexDirection: "column" as const,
  },
  shopBtn: {
    margin: "8px 0",
    textTransform: "uppercase" as const,
    padding: "8px 22px",
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    cursor: 'none'
  },
  searchBox: {
    backgroundColor: "#e9e9e9",
    padding: "8px 60px 8px 20px",
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: "Lato, sans-serif",
    color: "#33333a",
    textAlign: "left" as const,
    marginLeft: '-40px'
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  logoImg: {
    height: "85px",
  },
  rightSideHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  language: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    padding: "0 10px",
  },
  headerText: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000",
    fontFamily: `"Lato", sans-serif`,
    lineHeight: 1.1,
  },
  wishlistContainer: {
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const,
    padding: "0px 10px",
    alignItems: "center",
  },
  heartIcon: {
    color: "#666",
    paddingRight: 10,
    fontSize: 18,
  },
  wishlistBox: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
  },
  userIcon: {
    padding: 1,
    paddingRight: 10,
  },
  userImg: {
    height: "20px",
    width: "20px",
    objectFit: "contain" as const,
  },
  shopIcon: {
    color: "#666",
    fontSize: 25,
    cursor: "none",
  },
  count: {
    borderRadius: "50%",
    backgroundColor: "#ff6008",
    padding: "3px",
    fontSize: "10px",
    color: "#fff",
    width: "12px",
    textAlign: "center" as const,
    position: "absolute" as const,
    top: "92px",
    right: "28px",
  },
  cart: {
    padding: "0px 0px 0px 10px",
  },
  priceWrapper: {
    display: "flex",
    alignItems: "center",
  },
  flex: {
    display: "flex",
  },
  sectionTwoText: {
    position: "absolute" as const,
    background: "transparent",
    color: "#f1f1f1",
    padding: "12px",
    top: 0,
    width: "100%",
    boxSizing: "border-box" as const,
    height: "100%",
    textAlign: "center" as const,
    display: "flex",
    justifyContent: "center",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
  },
  priceMargin: {
    marginTop: '24px'
  }
};

export { TemplatePreview };
export default withStyles(styles)(TemplatePreview);
// Customizable Area End
