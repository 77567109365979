// Customizable Area Start
import React from "react";
import StoreMenuTabController, { IProductData, Props, configJSON } from "./StoreMenuTabController.web";
import { Box, Checkbox, ClickAwayListener, Grid, InputAdornment, TextField, Typography, styled, withStyles } from "@material-ui/core";
import { ClickableBox } from "../../../mobile-account-login/src/CustomElementStyling";
import { AddNewProductButton, CustomMenuItemBox, CustomizeBrandInput, SearchIconSVG } from "../CustomizedElementStyle";
import AddIcon from "@material-ui/icons/Add";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";
// Customizable Area End

class StoreMenuTab extends StoreMenuTabController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Grid container>
        <Typography style={styles.headerText}>{configJSON.selectPageToCreateHeaderText}</Typography>
        </Grid>
        <Grid container style={styles.flex}>
          {this.state.tabList.map((item: {id:string,title: string}, index: number) => {
            return (
              <ClickableBox data-test-id="menulist" title={item.title} onClick={this.handleMenuTab.bind(this, index, item.id)} style={this.state.activeMenuTab === index ? styles.activeMenuTab : styles.inactiveMenuTab}>
                <Typography style={styles.menuText}>{item.title}</Typography>
              </ClickableBox>
            );
          })}
        </Grid>
        <Grid container style={styles.topMargin} spacing={4}>
          <Grid item lg={10} md={10} sm={10} xs={12} style={webStyle.bottom}>
            <CustomizeBrandInput
              data-test-id="tabPosition"
              id="outlined-start-adornment"
              value={this.state.tabPosition}
              label={configJSON.tabPositionLabel}
              onChange={this.handleTabPosition}
              fullWidth
              inputProps={{
                maxLength : 1
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
            {this.state.positionErr && (
                <Typography data-test-id="positionErr" component={"div"} style={styles.prodErr}>
                  {this.state.positionErr}
                </Typography>
            )}
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <CustomizeBrandInput
              data-test-id="tabName"
              id="outlined-start-adornment"
              value={this.state.tabName}
              label={configJSON.menuTabLabel}
              onChange={this.handleTabName}
              fullWidth
              inputProps={{
                maxLength : 25,
                minLength : 3
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
            {this.state.tabNameErr && (
                <Typography data-test-id="tabNameErr" component={"div"} style={styles.prodErr}>
                  {this.state.tabNameErr}
                </Typography>
            )}
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={12}>
            <AddNewProductButton style={styles.addBtnBox} data-test-id="addNewProductBtn" onClick={this.addMenu}>
              <AddIcon style={styles.addBtn} />
              {configJSON.addText}
            </AddNewProductButton>
          </Grid>
          <ClickAwayListener data-test-id="clickAway" onClickAway={this.handleClick}>
            <Grid item lg={10} md={10} sm={10} xs={12} style={styles.section}>
              <Box>
                <CustomizeSearchBox
                  data-test-id="searchProduct"
                  label={configJSON.selectProductLabel}
                  id="outlined-start-adornment"
                  autoComplete="off"
                  value={this.state.searchProductValue}
                  onChange={this.handleSearchProductValue}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={webStyle.prodMargin}></InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end" style={webStyle.prodPadding}>
                        <SearchIconSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {this.state.searchProductValue ? (
                <Box style={styles.searchBox}>
                  <Box style={styles.resText}>{this.state.searchProductValue ? `found ${this.state.searchProdRes.length} results for ${this.state.searchProductValue}` : ""}</Box>
                  <Box style={styles.paraText}>{configJSON.resultDes}</Box>
                </Box>
              ) : (
                ""
              )}

              {this.state.openTabProdResult && (
                <Box
                  style={styles.prodBox}>
                  {this.state.searchProdRes?.map((item: IProductData, index: number) => {
                    const title = item.attributes.product_title || item.attributes.product_content.product_attributes.product_title;
                    return (
                      <CustomMenuItemBox data-test-id="productSearch" style={{...styles.prodCard, background: Boolean(this.state.selectProdId.includes(item.id)) ? "#ff7020b0" : "#fff" }} key={`${item}-${index}`} >
                        <Checkbox data-test-id={`radio-${index}`} color="default" icon={<RadioButtonUncheckedIcon style={styles.prodRadio} />} checkedIcon={<RadioButtonCheckedIcon style={styles.prodRadio} />} onChange={(e) => this.handleSelectTabProduct(e, item)} checked={Boolean(this.state.selectProdId.includes(item.id))} />
                        <Box style={webStyle.textWrapOne}>
                          <Typography style={styles.titleOne}>{title}</Typography>
                          <StyledCategory style={{color: Boolean(this.state.selectProdId.includes(item.id)) ? '#fff': "#697A8D"}}>{this.getProductRelation(item)}</StyledCategory>
                        </Box>
                      </CustomMenuItemBox>
                    );
                  })}
                </Box>
              )}
            </Grid>
          </ClickAwayListener>
        </Grid>
        {this.state.menuError ? 
        <Typography data-test-id="menuErr" style={styles.prodErr}>{this.state.menuError}</Typography> : ''}
        <Box style={styles.menuNext}>
          <Grid container spacing={3} style={styles.menuGrid}>
            <Grid item>
              <CustomOrangeButton data-test-id="nextRef" onClick={this.handleNextBtn}>
                {configJSON.nextBtn}
              </CustomOrangeButton>
            </Grid>
          </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  bottom: {
    marginBottom: "12px",
  },
  prodMargin: {
    marginRight: "20px",
  },
  prodPadding: {
    paddingRight: "20px",
  },
  textWrapOne: {
    marginLeft: "8px",
  }
};

const styles = {
  flex: {
    display: "flex",
    margin: "16px 10px 16px -10px ",
  },
  activeMenuTab: {
    width: "min-content",
    backgroundColor: "#ff6008",
    borderRadius: 4,
    border: "1px solid #ff6008",
    fontSize: 14,
    fontFamily: `"Lato", sans-serif`,
    color: "#fff",
    fontWeight: 500,
    padding: "10px 16px",
    marginLeft: "10px",
  },
  inactiveMenuTab: {
    width: "min-content",
    background: "#fff",
    border: "1px solid #c8c8c8",
    borderRadius: 4,
    fontSize: 14,
    fontFamily: `"Lato", sans-serif`,
    color: "#333",
    fontWeight: 500,
    padding: "10px 16px",
    marginLeft: "10px",
  },
  menuText: {
    fontSize: 14,
    fontFamily: `"Lato", sans-serif`,
    whiteSpace: "nowrap" as const,
    overflow: "hidden" as const,
    textOverflow: "ellipsis" as const,
    minWidth: "60px",
    maxWidth: "100px",
    textAlign: "center" as const,
  },
  menuNext: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "60px",
  },
  menuGrid: {
    justifyContent: "flex-end",
  },
  topMargin: {
    marginTop: 20,
  },
  addBtnBox: {
    fontWeight: 700,
    fontSize: "16px",
  },
  addBtn: {
    color: "white",
    marginRight: 5,
    marginLeft: 0,
  },
  section: {
    marginTop: "20px",
    width: "96%",
    padding: "16px",
  },
  prodCard: {
    display: "flex",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
    background: "#fff",
    margin: "12px",
    borderRadius: "5px",
    padding: "16px 10px",
  },
  prodRadio: {
    color: "#666666",
    paddingLeft: "0",
    "&.Mui-checked": {
      color: "#666666",
    },
  },
  titleOne: {
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`,
    color: "#33333a",
  },
  titleTwo: {
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    color: "#697A8D",
  },
  prodErr: {
    color: "red",
    fontSize: "11px",
    marginTop: "6px",
  },
  searchBox: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row" as const,
    marginTop: 20,
  },
  resText: {
    color: "#666666",
    fontWeight: 1000,
    fontSize: 12,
    fontFamily: `"Lato", sans-serif`,
    marginRight: 5,
  },
  paraText: {
    color: "#666666",
    fontSize: 12,
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 500,
  },
  prodBox: {
    overflowY: "auto" as const,
    scrollbarWidth: "thin" as const,
    position: "relative" as const,
    marginTop: "12px",
    maxHeight: "400px",
    borderRadius: "5px",
    marginLeft: "-12px",
    marginBottom: '30px',
    width:'86%'
  },
  headerText: {
    color: "#9a9a9a",
    fontSize: 12,
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 600,
    marginTop: '30px'
  }
};

const StyledCategory = styled(Typography)({
  fontFamily: `"Lato", sans-serif`,
  fontSize: "14px"
})

const CustomizeSearchBox = withStyles(() => ({
  root: {
    "& .MuiInputBase-input": {
      fontSize: 14,
      fontFamily: '"Lato", sans-serif',
      fontWeight: 500
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336"
    },
    "& .MuiInputBase-root": {
      border: "1px solid #b1b3b5"
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline
    },
    "& .MuiInputLabel-root": {
      top: -22,
      left: 0,
      color: "#9a9a9a",
      fontWeight: 600,
      fontSize: 16,
      fontFamily: `"Lato", sans-serif`
    },
    "&:hover .MuiInput-underline:before, &:hover .MuiInput-underline:after": {
      borderBottom: "none" // Remove the underline on hover
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#585858",
      fontFamily: "Lato, sans-serif",
      fontSize: "12px",
      fontWeight: 400
    },
    "& .MuiInput-formControl": {
      margin: 0,
      padding: "7px 0px",
      borderRadius: "5px"
    }
  }
}))(TextField);

export { StoreMenuTab };
export default StoreMenuTab;
// Customizable Area End
