Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypeDelete = "DELETE";
exports.apiMethodTypePatch = "PATCH";
exports.apiMethodTypePUT = "PUT";
exports.getLoggedUserApiEndPoint = `account_block/accounts/logged_user?token=`;
exports.productAPiEndPoint = "catalogue/catalogues";
exports.fulFilledByEzzyText = "Fulfilled by ByEzzy";
exports.manageOrdersText = "Manage Orders";
exports.searchOrderIdText = "Search Order ID";
exports.orderDateLabel = "Order date";
exports.orderDetailsLabel = "Order details";
exports.imageLabel = "Image";
exports.productNameLabel = "Product name";
exports.customerOptionLabel = "Customer option";
exports.orderStatusLabel = "Order Status";
exports.returnReasonLabel = "Return Reason";
exports.reasonDescriptionLabel = "Return Description";
exports.actionLabel = "Action";
exports.pendingOrdersLabel = "Pending Orders";
exports.fulfilledOrdersLabel = "Fulfilled Orders";
exports.cancelledOrdersLabel = "Cancelled Orders";
exports.filtersLabel = "Filters";
exports.sortingLabel = "Sorting";
exports.stockMgmtStorageText = "FBB Stock Management & Storage";
exports.allDealsTabLabel = "All Deals";
exports.myDealsTabLabel = "My Deals";
exports.myStorageLabel = "My Storage";
exports.requestsText = "Requests";
exports.myRequestsLabel = "My Requests";
exports.storageReqText = "Create ASNs, Check the status of your storage and requests.";
exports.createStockReqBtnText = "Create Stock intake request";
exports.fbbStockMgmtText = "FBB Stock Mgmt & Storage";
exports.stockPath = "Create Stock Intake Request";
exports.stockDescText = "Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.";
exports.selectproductLabel = "Select Product";
exports.stockValueLabel = "Stock Value (in AED)";
exports.stockQuantityLabel = "Stock Quantity";
exports.productLengthLabel = "Each Product Length (in cm)";
exports.productWidthLabel = "Each Product Width (in cm)";
exports.productHeightLabel = "Each Product Height (in cm)";
exports.productWeightLabel = "Each Product Weight (in gm)";
exports.submitRequestBtn = "Submit Request";
exports.requestDateLabel = "Request date";
exports.requestDetailsLabel = "Request details";
exports.stockQtyLabel = "Stock Qty";
exports.requestStatusLabel = "Request Status";
exports.printLabelPath = "Print Label";
exports.downloadPdfBtn = "Download PDF";
exports.printAllBtn = "Print All";
exports.fbbFeesLabel = "FBB Fees";
exports.disputesLabel = "Disputes";
exports.searchRequestText = "Search Request";
exports.searchFeesText = "Search Fees";
exports.stockWeightLabel = "Stock Weight:";
exports.stockVolumeLabel = "Stock Volume:";
exports.unitPriceLabel = "Unit Price:";
exports.stockQuantityLabel = "Stock Qty:";
exports.reqIdLabel = "Request ID:";
exports.fullFillmentLabel = "Fullfillment:";
exports.ReturnAndWarrenty = {
  title: "Returns & Warranty Claims",
  subTitle: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  requestExchange: "Return / Exchange",
  requestType: "Request Type",
  returnReason: "Reason for Return/Exchange",
  description: "Describe",
  cancelButton: "Back",
  submitButton: "Submit",
}
exports.allOrdersLabel = "All Orders";
exports.acceptBtn = "Accept";
exports.rejectBtn = "Reject";
exports.warehouseName = "Warehouse Name";
exports.processingDays = "Processing Days";
exports.address = "Address";
exports.contact = "Contact";
exports.home = "Home"
exports.addressOne = "Address 1*";
exports.addressTwo = "Apartment/Villa number or name*";
exports.contactPerson = "Contact Person";
exports.contactNumber = "Contact Number";
exports.wareHouseLabel = "Warehouse";
exports.fulfilledByPartnerLabel = "Fulfilled by Partner";
exports.createWarehouseLabel = "Create Warehouse";
exports.welcomeText = "Welcome!";
exports.createWarehouseDes = "Let's create your warehouse!";
exports.createNewWarehouseBtn = "Create new warehouse";
exports.addNewWarehouseBtn = "Add new warehouse";
exports.description = "Default warehouse for seller";
exports.ratingType = { title: "Customer Happiness Indicator", seller: "Seller Rating", delivery: "Delivery Rating", catalog: "Catalog Rating" };
exports.searchProductLabel = "Search Product";
exports.stockLogsLabel = "Stock Logs";
exports.image = "Image";
exports.pskuId = "PSKU ID";
exports.productStatus = "Product Status";
exports.stockQty = "Stock Qty";
exports.updateLabel = "Update";
exports.deliveryReqLabel = "Delivery requests";
exports.noReqYetText = "No request yet";
exports.createNewRequestBtn = "Create new request";
exports.wareHouseAddressLabel = "Warehouse Address";
exports.orderNumberLabel = "Order no";
exports.reqSubmittedText = "Request is submitted";
exports.modalReqDesc = "Your request is submitted for review";
exports.redirectedText = "You are being redirected";
exports.postProductListApiEndPoint = "bx_block_catalogue/catalogues/list_catalogue_by_category";
exports.getSubCategoryEndPoint = (id) => `bx_block_categories/categories/${id}/sub_categories`;
exports.getBrandListEndPoint = (id) => `bx_block_catalogue/brands/list_brands_from_catalogues?category_id=${id}`;
exports.getMicroCatagoryEndPoint = (id, subId) => `bx_block_categories/categories/${id}/sub_categories/${subId}/mini_categories?`;
exports.getTrendingProductApiEndPoint = "/bx_block_dashboard/trending_products";
exports.cartText = "Cart";
exports.cartDescText = "Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.";
exports.productLabel = "Product";
exports.quantityLabel = "Quantity";
exports.priceLabel = "Price";
exports.shippingAddressLabel = "Shipping Address";
exports.couponText = "Have coupon?";
exports.firstName = "First Name*";
exports.lastName = "Last Name*";
exports.stateLabel = "State";
exports.phoneNumberLabel = "Phone number*";
exports.cityLabel = "City*";
exports.zipCodeLabel = "Zip code";
exports.firstNamePlaceholder = "Enter First Name";
exports.lastNamePlaceholder = "Enter Last Name";
exports.addressPlaceholder = "Enter Address";
exports.phoneNumberPlaceholder = "Enter Phone Number";
exports.statePlaceholder = "Enter State";
exports.cityPlaceholder = "Enter City";
exports.zipCodePlaceholder = "Enter Zip code";
exports.sameAsShippingAddressText = "Same as shipping address";
exports.addBillingAddressText = "Add billing address";
exports.addAddressContinueBtn = "Add Address and Continue";
exports.orderSummaryLabel = "Order Summary";
exports.summaryDesc = "Lorem Ipsum information on";
exports.totalPrice = "Total price:";
exports.tax = "Tax:";
exports.total = "Total:";
exports.paymentNowBtn = "Pay Now";
exports.placeOrderBtn = "Place Order";
exports.thankYouForPurchaseText = "Thank you for your purchase";
exports.purchaseOrderDes = "You will receive an order confirmation email with details of your order."
exports.feedbackText = "Feedback";
exports.shareYourThoughtText = "Share your thoughts with other customers";
exports.leaveSellerfeedBckText = "Leave seller feedback";
exports.readLessText = "Read less";
exports.readMoreText = "Read more";
exports.previousText = "Previous";
exports.nextText = "Next";
exports.categoryText = "Category";
exports.brandText = "Brand";
exports.discountText = "Discount";
exports.ratingText = "Rating";
exports.colorText = "Colors";
exports.viewAllBtn = "View All";
exports.cartEmptyText = "Your Cart is empty";
exports.looksNothingAddedText = "Looks like you have not added anything to your cart.";
exports.goAheadShopText = "Go ahead & shop";
exports.deleteBtnLabel = "Delete";
exports.editBtnLabel = "Edit"
exports.continueShoppingBtn = "Continue Shopping";
exports.sellerWarehouseApiEndPoint = {
  create: "bx_block_catalogue/warehouses/create_seller_warehouse",
  update: 'bx_block_catalogue/warehouses/update_seller_warehouse',
  delete: 'bx_block_catalogue/warehouses/delete_seller_warehouse',
  list: 'bx_block_catalogue/warehouses/seller_warehouse_listing'
}
exports.happinessRatingApiEndPoint=(params)=>`bx_block_catalogue/reviews/seller_happiness_indicator${params}`
exports.sellerAllDeals = {
  list: "bx_block_catalogue/deals",
  listSKU: (keyword)=>`search_catalogues?keyword=${keyword}`,
  createDeals: (deal_id)=>`bx_block_catalogue/deals/${deal_id}/deal_catalogues`,
  updateDeals: (deal_id,catalogueId)=>`bx_block_catalogue/deals/${deal_id}/deal_catalogues/${catalogueId}`,
  listSellerDeals: (deal_id)=>`bx_block_catalogue/deals/${deal_id}/deal_catalogues`,
  deleteSellerDeal: (deal_id,catalogueId)=>`bx_block_catalogue/deals/${deal_id}/deal_catalogues/${catalogueId}`,
}
exports.myDealsApiEndPoint = {
  createDeals: (deal_id)=>`bx_block_catalogue/deals/${deal_id}/deal_catalogues`,
  delete: (deal_id,seller_id)=>`bx_block_catalogue/deals/${deal_id}/deal_catalogues/${seller_id}`,
  edit: (deal_id,sellerDealId)=>`bx_block_catalogue/deals/${deal_id}/deal_catalogues/${sellerDealId}`,
  list: 'bx_block_catalogue/deals'
}
exports.viewMoreBtnText = "View More";
exports.upgradeLabel = "Upgrade";
exports.moreThemesLabel = "More Themes";
exports.supportLabel = "Support";
exports.brandCatalogText = "Brand Catalog";
exports.continueBtnLabel = "Continue";
exports.tabOneFilterOptions = ["ordered", "processing", "shipped"];
exports.tabTwoFilterOptions = ["ordered", "processing", "shipped", "delivered","cancelled","rejected", "refunded"];
exports.searchOrderPlaceholder = "#SKU,#CODE";
exports.rejectedText = "Rejected";
exports.refundText = "Refund";
exports.editStatusOptions = ["Ordered","Cancelled","Rejected", "Refunded", "Delivered"];
exports.buyerLabel = "Buyer";
exports.salesLabel = "Sales";
exports.partnerText = "Partner";
exports.pskuLabel = "PSKU:";
exports.unitPriceInAED = "Unit Price (AED):";
exports.standardText = "Standard";
exports.deliveredText = "Delivered";
exports.shippedText = "Shipped";
exports.processingText = "Processing";
exports.noDataAvailableText = "No data available";
exports.orderedText = "Ordered";
exports.paymentDoneText = "PAYMENT DONE";
exports.cancelledText = "Cancelled";
exports.tab0FilterValue = '&filter_by[]=ordered&filter_by[]=processing&filter_by[]=shipped';
exports.tab1FilterValue = '&filter_by[]=ordered&filter_by[]=processing&filter_by[]=shipped&filter_by[]=cancelled&filter_by[]=delivered&filter_by[]=rejected&filter_by[]=refunded';
exports.tab2FilterValue = `&filter_by[]=cancelled`;
exports.partnerAPIEndpoint = `bx_block_shopping_cart/orders/seller_orders`;
exports.getSearchProductAPIEndPoint = "bx_block_dashboard/global_searches/search";
exports.getCategoryListSeachKeywordAPIEndPoint = "/bx_block_dashboard/global_searches/categories_filter_lists";
exports.getBrandListSearchKeywordEndPoint = "bx_block_dashboard/global_searches/brands_filter_lists"
exports.sortOrderOptions = [{label:"Latest", value: "date_latest"}, {label: "Oldest", value: "date_oldest"}, {label: "Product Name: A-Z",value: "product_name_AZ"}, {label: "Product Name: Z-A", value:"product_name_ZA"}]
exports.responseMsg = "No order present";
exports.refundedText = "Refunded";
exports.orderIdText = "Order ID:";
exports.buyerNameLabel = "Buyer name:";
exports.sellerNameLabel = "Seller name:";
exports.dateLabel = "Date:";
exports.timeLabel = "Time:";
exports.shippingAddressText = "Shipping address:";
exports.shippingDetailsText = "Shipping details:";
exports.totalPriceLabel = "Total price";
exports.AedText = "AED";
exports.totalQuantityLabel = "Total quantity";
exports.addSingleHome = "addSingleHome"; 
exports.sellerHomeText = "SellerHomeDashboard";
exports.createStockReqPath = "create-stock-request";
exports.createWarehousePath = "add-warehouse";
exports.partnerOrderDetailsPath = "partner-order-details";
exports.warehouseListPath = "warehouse-list";
exports.warehousePath = "warehouse";
exports.requestListPath = "requestlist";
exports.createDeliveryReqPath = "create-delivery-request";
exports.orderManagementTab = "Order Management";
exports.stocklogsPath = "stocklogs";
exports.deliveryReqPath = "delivery-request";
exports.customerHappinessIndicatorTab = "Customer Happiness Indi...";
exports.filterBtnText = "Filter";
exports.storageDescription = "Show all the types of storage, your utilization of each type, and the space you have left"
exports.totalStorageUsed = "Total Storage Used";
exports.utilizedSpaceText = "Utilized Space";
exports.extraInfoText = "Extra information";
exports.viewNowBtnText = "View now";
exports.requestIdPlaceholder = "#Request ID";
exports.byEzzyText = "ByEzzy";
exports.draftText = "draft";
exports.requestedText = "requested";
exports.viewText = "View";
exports.fbbFeesAndDisputesText = "FBB Fees & Disputes";
exports.raiseDisputeText = "Raise Dispute";
exports.bbicNumber = "BBIC Number";
exports.bbicPlaceholderText = "Enter BBIC number";
exports.sellerSKULabel = "Seller SKU";
exports.sellerSkuPlaceholder = "Enter Seller SKU id";
exports.typeOfReqLabel = "Type of Request";
exports.selectReqType = "Select Request type";
exports.sellerFeeDispute = "Seller Fee dispute";
exports.storageFeeDispute = "Storage Fee dispute";
exports.fulfillmentFeeDispute = "Fulfilment Fee dispute";
exports.otherText = "Other";
exports.descriptionLabel = "Description";
exports.descriptionPlaceholder = "Describe your issues briefly...";
exports.fromDateLabel = "From Date";
exports.dateFormat = "dd/mm/yyyy";
exports.toDateLabel = "To Date";
exports.orText = "OR";
exports.customRangeText = "Custom Range";
exports.selectSku =  "Select SKU";
exports.lastOneMonth = "Last 1 month";
exports.searchFees = "Search Fees";
exports.enterBibcNumber = "Enter BIBC";
exports.searchText = "Search";
exports.showResultsText = "Showing results for";
exports.includesStorageCostText = "Includes Storage cost & Order Fulfilment cost";
exports.damageAndReturnLabel = "Damage & Returns";
exports.damagesLabel = "Damages";
exports.returnsLabel = "Returns";
exports.damagesRequestText = "Damages Request";
exports.damagesRequestPlaceholder = "#Order ID, Stock intake request id";
exports.disposeAllText = "Dispose All";
exports.returnAllText = "Return All";
exports.noOfUnitsLabel = "No of Units";
exports.reasonOfDamageLabel = "Reason of Damage";
exports.orderId = "Order id:";
exports.returnRequestText = "Returns Request";
exports.stockIntakeDateLabel = "Stock intake date";
exports.stockConditionLabel = "Stock Condition";
exports.returnText = "Return";
exports.stockIntakeId = "Stock Intake Req:";
exports.disposeText = "Dispose";
exports.yourBrowsingHistoryText = "Your Browsing History";
exports.pleaseSelectShippingAddressText = "Please select or add shipping address."
exports.delAddressConfirmationText = "Are you sure you want to delete the address?";
exports.searchProdByTitleEndpoint = `/bx_block_catalogue/catalogues/search_catalogues_by_title_or_brand_name?product_keyword=`;
exports.addPrductToWarehouseEndpoint = "bx_block_catalogue/warehouses/";
exports.recentItemsEndpoint = "/bx_block_salesreporting/product_views/browsing_history"
exports.warehouseProductDeletedText = "Warehouse catalogue deleted successfully";
exports.selectWarehouseErrMsg = "Please select warehouse";
exports.selectWarehouseText = "Select Warehouse";
exports.activeText = "Active";
exports.inactiveText = "Inactive";
exports.deleteWarehouseText = "Are you sure you want to delete this warehouse?";
exports.requestTyperequiredText = "Request type is required";
exports.reasonRequiredText = 'Reason is required';
exports.descriptionRequiredText = 'Description is required';
exports.requestOneText = "Request 1";
exports.requestTwoText = "Request 2";
exports.reasonOneText = "Reason 1";
exports.reasonTwoText = "Reason 2";
exports.describeInDetailsText = "Describe in details.";
exports.reqText = "Request";
exports.exchangeText = "Exchange";
exports.selectTypeText = "Select Type";
exports.selectReasonText = "Select Reason";
exports.createDeliveryReqEndpoint = "bx_block_order_management/delivery_requests";
exports.monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
exports.pendingText = "pending";
exports.acceptedText = "accepted";
exports.myDeliveryReqText = "My delivery requests";
exports.reqCreatedSuccessfullyText = "Delivery Request Created Successfully";
exports.quantityErrText = 'Quantity must be gretter than 0';
exports.stockUpdatedSuccessfullyText = 'Stock quantity updated successfully';
exports.warehouseNameRequiredText = 'Warehouse name is required';
exports.maxWarehouseNameText = 'Warehouse name must contain max 50 characters with no leading or trailing spaces';
exports.minWarehouseNameText = 'Warehouse name must contain min 3 characters with no leading or trailing spaces';
exports.validProcessingDaysText = 'Please enter valid processing days.';
exports.daysLimitText = 'Days must be at most 99';
exports.processingDaysRequiredText = 'Processing days are required';
exports.addressOneRequiredText = "Address 1 is required";
exports.addressTwoRequiredText = "Address 2 is required";
exports.addressMaxLimitText = 'Address must not exceed 100 characters';
exports.contactPersonReqText = 'Please enter contact person name';
exports.contactNameMaxLimitText = 'Contact name must contain max 30 characters with no leading or trailing spaces';
exports.contactNameMinLimitText = 'Contact name must contain min 3 characters with no leading or trailing spaces';
exports.contactNumberFormatText = "Contact number must be in the format 971-xx-xxxxxxx, where 'xx' represents digits.";
exports.enterContactNumberText = 'Please enter contact number';
exports.myCartText = "My Cart";
exports.colorHexValues = ["#7877FF", "#FF7979", "#B2E130", "#FFCB6A", "#646464", "#4CB28D", "#E84FED", "#51B2EF"];
exports.partnerReturnsAPIEndpoint = "bx_block_shopping_cart/order_items";
exports.reasonForReturnLabel = "Reason for return";
exports.returnEditOptions = ["Pickup Initiated", "Received", "QC Processing", "QC Passed", "QC Failed", "Refund"];
exports.returnFilterOptions = ["Return","Pickup Initiated", "Received", "QC Processing", "QC Passed", "QC Failed", "Refund"];
exports.enterShippingDetailsText = "Enter Shipping Details";
exports.enterReturnReasonText = "Reason of Rejection";
exports.orderStatusApiEndpoint = "bx_block_order_management/order_statues";
exports.brandAndDealProductApiEndPoint = "bx_block_catalogue/catalogues/list_catalogue_by_category";
exports.brandProductListEndPoint = 'bx_block_catalogue/catalogues/search_catalogues_by_title_or_brand_name?product_keyword=';
exports.selectBrand = "Select Brand";
exports.barndListApiEndPoint = '/bx_block_catalogue/brands/seller_brand_listing';
exports.searchProductDes = "Search for the product via Product Name";
exports.dateAndTimeLabel = "Date & time";
exports.searchBrandProductText = "Lets search the product";
exports.noProductsFoundText = "No products found";
exports.courierName = "Courier Name";
exports.trackingNumber= "Tracking Number";
exports.courierNamePlaceHolder = "Enter courier name";
exports.trackingNumberPlaceHolder= "Enter tracking number";
exports.outOfStockText = "This item is currently out of stock"
exports.removeOutOfStocks = "Please remove inactive / out of stock items "
exports.noBrowsingText = "No browsing history available"

exports.ProductColorOptions = [
  {title: "Purple", value: "#7877FF"},
  {title: "Light Coral", value: "#FF7979"},
  {title: "Lime Green", value: "#B2E130"},
  {title: "Yellow", value: "#FFCB6A"},
  {title: "Grey", value: "#646464"},
  {title: "Ocean Green", value: "#4CB28D"},
  {title: "Pink", value: "#E84FED"},
  {title: "Sky blue", value: "#51B2EF"},
]
exports.createConsignmentApiEndpoint = "bx_block_order_management/anchanto_wms/create_consignment_order";
exports.createAuthTokenEndpoint = "https://uatapi.anchanto.com/rest/v1/tokens/generate";
exports.authTokenPassword = "ByEzzy1@#$";
exports.getTrendingProductsEndpoint = (id) => `bx_block_dashboard/trending_products/list_trending_products?slider=${id}`
exports.getBrandListTrendingEndPoint = (id) => `bx_block_dashboard/global_searches/brands_filter_lists?trending_slider=${id}`
exports.getCatListTrendingEndPoint = (id) => `bx_block_dashboard/global_searches/categories_filter_lists?trending_slider=${id}`
// Customizable Area End