import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";

// Customizable Area Start
import { InputProps } from "@material-ui/core/Input";
import { createRef } from "react";
import { getStorageData, removeStorageData } from "../../../../framework/src/Utilities";
import saveAs from "file-saver";
import * as XLSX from "xlsx";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  value: string;
  nxtBtnClicked: boolean;
  branding_tradmark_certificate: any;
  errorMsg2: any;
  successMsg2: string;
  authToken: string;
  showVerifyDeleteModal1: boolean;
  isLoading: boolean;
  isUploadSelected: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class SellerBulkProductUploadController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  inputBrandRef: React.RefObject<InputProps & { click: Function }>;
  apiCreateBrandCallId: string = "";
  apidownloadTemplateCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      value: "",
      successMsg2: "",
      authToken: "",
      nxtBtnClicked: false,
      branding_tradmark_certificate: "",
      errorMsg2: [],
      showVerifyDeleteModal1: false,
      isLoading: false,
      isUploadSelected: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.inputBrandRef = createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (apiRequestCallId === this.apiCreateBrandCallId) {
        this.handleCreateBrandResponse(responseJson);
      }
      if (apiRequestCallId === this.apidownloadTemplateCallId) {
        this.handleTemplateDownloadRes(responseJson)
      }
    }
  }
  // Customizable Area End

  // Customizable Area Start

  handleTemplateDownloadRes = async(responseJson:any) => {
    if(this.state.isUploadSelected) {
      this.setState({isUploadSelected: false})
      this.generateCSV(responseJson);
    }
    else this.handleDownloadExcelTemplate(responseJson);
  }

  handleDownloadExcelTemplate = async (data: any) => {
    const worksheet = XLSX.utils.json_to_sheet([data.values]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Product Data");
    const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    saveAs(new Blob([excelBlob], { type: "application/octet-stream" }), "template.xlsx");
  };

  generateCSV = (responseJson:any) => {
    const data = responseJson; 
    const headers = data.headers;  
    const values = data.values;
    const maxArrayLength = Math.max(
      ...headers.map((header:any) => (Array.isArray(values[header]) ? values[header].length : 1))
    );

    const rows = [];
    for (let i = 0; i < maxArrayLength; i++) {
      const row = headers.map((header:any) => {
        const value = values[header];
        if (Array.isArray(value)) {
          return value[i] || ""; 
        }
        return i === 0 ? value || "" : ""; 
      });
      rows.push(row);
    }
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Product Data");
    const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    saveAs(new Blob([excelBlob], { type: "application/octet-stream" }), "uploadguide.xlsx");
  };

  handleCreateBrandResponse = async(responseJson: unknown) => {
    const responseData: any = responseJson;
    this.setState({ errorMsg2: [], successMsg2: "", isLoading: false });
    if (responseData.message === "Bulk upload completed successfully") {

    await removeStorageData("category_id");
    await removeStorageData("sub_category_id");
    await removeStorageData("mini_category_id");
    await removeStorageData("micro_category_id");
      this.setState({ showVerifyDeleteModal1: true, errorMsg2: "", successMsg2: "" });
    } else if (responseData.error === "Please upload a file with proper data") {
      this.setState({ showVerifyDeleteModal1: true, errorMsg2: ["Please upload a file with proper data"] });
    } else if (responseData.message === "Bulk upload completed with errors") {
      this.setState({ showVerifyDeleteModal1: true, errorMsg2: responseData.validation_errors });
    } else {
      const errorMsg = responseData.error || "Unknown error occurred";
      this.setState({ showVerifyDeleteModal1: true, errorMsg2: [errorMsg] });
    }
  };

  handleOpenFileInput = () => {
    this.setState({ errorMsg2: [] });
    if (this.inputBrandRef && this.inputBrandRef.current) {
      this.inputBrandRef.current.click();
    }
  };

  handleUploadBrandFile = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.setState({ errorMsg2: [] });
    if (!file) return;
    if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return this.setState({ errorMsg2: ["Please upload a XLSX document."] });
    }

    this.setState({ branding_tradmark_certificate: file });
  };

  handleFileOnSubmit = () => {
    this.setState({ errorMsg2: [], successMsg2: "", isLoading: true });

    const { branding_tradmark_certificate } = this.state;

    if (!branding_tradmark_certificate) {
      return this.setState({ errorMsg2: ["Please upload XLSX file"], isLoading: false });
    }

    const headers = { token: this.state.authToken };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCreateBrandCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.bulkUploadApi);
    const formData = new FormData();
    formData.append("file", this.state.branding_tradmark_certificate);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCancel() {
    this.setState({ nxtBtnClicked: false, errorMsg2: [], successMsg2: "", branding_tradmark_certificate: "" });
  }

  handleGetToken = async () => {
    const authToken = await getStorageData("authToken");
    this.setState({ authToken });
  };

  async componentDidMount(): Promise<void> {
    this.handleGetToken();
  }

  handleCloseDeleteModal() {
    if (!this.state.errorMsg2) {
      this.props.navigation.navigate(configJSON.sellerHomeText, { path: 'products' });
    }
    this.setState({ showVerifyDeleteModal1: false });
  }

  handleDownloadTemplate = () => {
    this.templateDownLoad()
  }

  handleUploadGuide = () => {
    this.setState({isUploadSelected: true})
    this.templateDownLoad()
  }

  templateDownLoad = async () => {
    const categoryId = await getStorageData("category_id");
    const subCatId = await getStorageData("sub_category_id");
    const miniCatId = await getStorageData("mini_category_id");
    const microCatId = await getStorageData("micro_category_id");
    const brandId = await getStorageData("brand_id");
      const headers = {
        "Content-Type": "application/vnd.ms-excel",
        token: this.state.authToken
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apidownloadTemplateCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.myDealsApiEndPoint.createDeals(categoryId, subCatId, miniCatId, microCatId, brandId));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
