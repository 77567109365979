// Customizable Area Start
import React from "react";
import OrderSummaryController, {
  CartItem,
  IProductData,
  Props,
  configJSON,
  RecentProduct
} from "./OrderSummaryController.web";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import HeaderWeb from "../../../landingpage/src/Header.web";
import {
  arrowDown,
  arrowUp,
  blackArrowDown,
  emptyHeartIcon,
  heartFilledIcon,
  starIcon,
} from "../assets";
import {
  CustomScrollbar,
  CustomizeBrandInput,
  OrangeButton,
} from "../../../dashboard/src/CustomizedElementStyle";
import styled from "@material-ui/styles/styled";
import Slider from "react-slick";
import { finalPriceHeader } from "./commomstyles";
import { ScrollView } from "react-native";
import Footer from "../../../landingpage/src/Footer.web";
import Loader from "../../../../components/src/Loader.web";
import { cross } from "../../../dashboard/src/assets";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { ExpressLogo } from "../../../productquickview/src/assets";
import { calculateDiscountPercentage } from "../BuyerSearchProduct/ProductListController.web";
import TruncatedTextWithTooltipWeb from "../../../../components/src/CommonComponents/TruncatedTextWithTooltip.web";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";
import { getCurrentDate, getFinalPrice } from "../../../../components/src/CommonFunctions";

const CustomProductSlider = styled(Slider)({
  "& .slick-slide > div > div": {
    borderRadius: 10,
    cursor: "pointer"
  },
  "& .slick-slide > div": {
    display: "flex",
    margin: "20px",
    marginBottom: "10px",
    gap: 40,
    cursor: "pointer"
  },
  "& .slick-slide": {
    height: "unset",
  },
  "& .slick-prev": {
    top: "35%",
    cursor: "pointer",
    left: "2px",
    color: '#000',
    backgroundColor: '#fff', 
    borderRadius: '50%', 
    padding:'10px',
    width:'16px', 
    height:'16px',
    zIndex: 1
  },
  "& .slick-next": {
    top: "35%",
    right: "-2px",
    color: '#000',
    backgroundColor: '#fff', 
    borderRadius: '50%', 
    padding:'10px',
    cursor: "pointer",
    zIndex: 1,
    width:'16px', 
    height:'16px'
  },
  "& .slick-prev::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(0, 0, 0)"
  },
  "& .slick-next::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(0, 0, 0)",
  },
});
// Customizable Area End

class OrderSummary extends OrderSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  settings = {
    className: "center",
    infinite: false,
    initialSlide: 0,
    centerMode: false,
    slidesToShow:  4,
    speed: 500,
    slidesToScroll: 1,
    prevArrow: <ArrowBackIos/>,
    nextArrow: <ArrowForwardIos />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ]
  };

  renderOrderItemPrice = (item:IProductData) => {
    const {classes} = this.props;
    const {quantity} = item.attributes
    const {final_price, stroked_price} = item.attributes.item.catalogue.data.attributes
    const isSlashItemGreater = Number(stroked_price) - Number(final_price)
    return(
      <>
      <Box className={classes.priceWrapper}>
      <Typography className={classes.priceTextOne}>
        AED {quantity * Number(final_price)}
      </Typography>
      {isSlashItemGreater > 0 &&
      <Typography className={classes.color} style={{textDecoration: "line-through"}}>
        AED {quantity * Number(stroked_price)}
      </Typography>
      }
      </Box>
      </>
    )
  }

  renderProductData = () => {
    const { classes } = this.props;

    return this.state.productData.map((item: IProductData, index:number) => {
      const { product_image, product_title, brand, stocks, content_status, status } =
        item.attributes.item.catalogue.data.attributes;
      const productId = item.attributes.item.catalogue.data.id;
      const isAvailable = status && stocks && content_status && stocks > 0 && content_status !== "archived"
      const enableForWishList = status && content_status && content_status !== "archived"
      return (
        <Grid
          data-test-id="list"
          container
          className={classes.productDetailsWrapper}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            data-test-id="shopping-cart-item"
            className={classes.flex}
            onClick={() => this.handleQuickProductView(productId)}
          >
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              className={classes.image}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={product_image}
                alt="prod_img"
              />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} className={classes.productInfoContainer}>
              <TruncatedTextWithTooltipWeb text={product_title} maxChar={50} classNames={classes.cardProdName} />
              <Typography className={classes.color}>
                {""}
              </Typography>
              <Typography className={classes.brandName}>
                Brand: {brand.brand_name}
              </Typography>
              {!isAvailable && <Typography className={classes.productName} style={{ color: "grey" }}>
                {configJSON.outOfStockText}
              </Typography>}
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
            md={2}
            lg={2}
            className={classes.quantityField}
          >
            <TextField
              data-test-id="quantity"
              className={classes.quantity}
              disabled={this.state.quantity[index].quantity === stocks}
              variant="outlined"
              inputProps={{ min: 1, max: 99, maxLength: 2 }}
              value={
                this.state.quantity.find(
                  (obj: { id: string }) => obj.id === item.id
                )?.quantity
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleQuantityChange(event.target.value, item.id,
                item.attributes.item.catalogue.data
                  .id, Number(stocks))}
              InputProps={{
                endAdornment: isAvailable && (
                  <InputAdornment
                    position="end"
                    className={classes.arrowContainer}
                  >
                    <img
                      data-test-id="increase"
                      src={this.setProdIncreaseImg(Number(this.state.quantity[index].quantity),Number(stocks))}
                      className={classes.arrowUp}
                      onClick={() =>
                        ((this.state.quantity[index].quantity !== stocks) || (Number(this.state.quantity[index].quantity) < stocks)) && 
                        this.handleIncrease(
                          item.id,
                          item.attributes.item.catalogue.data
                            .id
                        )
                      }
                    />
                    <img
                      data-test-id="decrease"
                      src={blackArrowDown}
                      className={classes.arrowDown}
                      onClick={() =>
                        this.handleDecrease(
                          item.id,
                          item.attributes.item.catalogue.data
                            .id,
                            Number(stocks)
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
            md={2}
            lg={2}
            className={classes.quantityField}
          >
            {this.renderOrderItemPrice(item)}
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            lg={2}
            className={classes.quantityField}
          >
            <img
              data-test-id="favIcon"
              style={{
                cursor: "pointer",
                width: "38px",
                height: "38px",
                opacity: enableForWishList ? 1 : 0.5
              }}
              onClick={enableForWishList ? () =>
                this.handleFavouriteItem(
                  item.attributes.item,
                  item.attributes.item.favourite
                ) : undefined
              }
              src={
                item.attributes.item.favourite
                  ? heartFilledIcon
                  : emptyHeartIcon
              }
              alt="like_icon"
            />
            <Button
              data-test-id="removeItem"
              onClick={() => this.handleRemoveItem(item.id)}
              className={classes.removeBtn}
            >
              Remove
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Grid container>
              <Grid item xs={6} >
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                <Typography style={{fontSize:'12px', color:'rgb(215, 77, 77)', fontFamily:'Lato, sans-serif'}}>{(this.state.quantity[index].quantity === stocks || Number(this.state.quantity[index].quantity) > Number(stocks)) ? `Total ${stocks} of these available` : '' }</Typography>
                </Grid>
            </Grid>
            
          </Grid>
        </Grid>
      );
    })
  }

  renderCartPrice = (item: CartItem) => {
    const slashValue = item.attributes.stroked_price
    const final= item.attributes.final_price
    const {classes} = this.props;
    const quantity = item.quantity
    const isSlashValPresent = Number(slashValue) - Number(final)
    return (
      <>
       <Box className={classes.priceWrapper}>
      <Typography className={classes.priceTextOne}>
        AED {quantity * Number(final)}
      </Typography>
      {isSlashValPresent > 0 &&
      <Typography className={classes.color} style={{textDecoration: "line-through"}}>
        AED {quantity * Number(slashValue)}
      </Typography>
      }
      </Box>
      </>
    )
  }

  renderCartDataProduct = () => {
    const { classes } = this.props
    return this.state.cartData && this.state.cartData.map((item: CartItem) => {
      const { product_image, product_title, brand, stocks, content_status, status } = item.attributes;
      const isAvailableData = status && stocks && content_status && stocks > 0 && content_status !== "archived"
      return (
        <Grid
          container
          className={classes.productDetailsWrapper}
          data-test-id="listOne"
        >
          <Grid
            className={classes.flex}
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            data-test-id="prodCard"
            onClick={isAvailableData ? () => this.handleQuickProductView(item.id) : undefined}
          >
            <Grid
              className={classes.image}
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
            >
              <img
                src={product_image}
                alt="prod_img"
                style={{ width: "100%", height: "100%" }}
              />
            </Grid>
            <Grid className={classes.productInfoContainer} item xs={10} sm={10} md={10} lg={10} >
              <TruncatedTextWithTooltipWeb data-test-id="otherProduct2" text={product_title} maxChar={50} classNames={classes.cardProdName} />
              <Typography className={classes.color}>
                {""}
              </Typography>
              <Typography className={classes.brandName}>
                Brand: {brand.brand_name}
              </Typography>
              {!isAvailableData && <Typography id="cartOutOfStockText1" className={classes.productName} style={{ color: "grey" }}>
                {configJSON.outOfStockText}
              </Typography>}
            </Grid>
          </Grid>
          <Grid
            className={classes.quantityField}
            item
            xs={6}
            sm={2}
            md={2}
            lg={2}
          >
            <TextField
              data-test-id="cartQuantity"
              className={classes.quantity}
              variant="outlined"
              disabled={item.quantity === stocks}
              inputProps={{ min: 1, max: 99, maxLength: 2 }}
              value={
                this.state.quantity.find(
                  (obj: { id: string }) => obj.id === item.id
                )?.quantity
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleProductQuantityChange(event?.target?.value, item.id,
                getFinalPrice(item as any))}
              InputProps={{
                endAdornment: isAvailableData && (
                  <InputAdornment
                    position="end"
                    className={classes.arrowContainer}
                  >
                    <img
                      data-test-id="increaseQty"
                      src={this.setIncreaseImg(item.quantity, Number(stocks))}
                      className={classes.arrowUp}
                      onClick={() => item.quantity !== stocks  &&
                        this.handleIncreaseQuantity(
                          item.id,
                          item.final_price,
                          item.quantity,
                          item.attributes.discount
                        )
                      }
                    />
                    <img
                      data-test-id="decreaseQty"
                      src={blackArrowDown}
                      className={classes.arrowDown}
                      onClick={() =>
                        this.handleDecreaseQuanitity(
                          item.id,
                          item.final_price,
                          item.quantity,
                          item.attributes.discount
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
            md={2}
            lg={2}
            className={classes.quantityField}
          >
            {this.renderCartPrice(item)}
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            lg={2}
            className={classes.quantityField}
          >
            <img
              data-test-id="addTofavIcon"
              style={{
                cursor: "pointer",
                width: "38px",
                height: "38px",
              }}
              onClick={isAvailableData ? () =>
                this.addToFavourite(item.id) : undefined
              }
              src={emptyHeartIcon}
              alt="like_icon"
            />
            <Button
              data-test-id="removeCartItem"
              onClick={() => this.handleRemoveItemFromCart(item.id)}
              className={classes.removeBtn}
            >
              Remove
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            <Grid container>
              <Grid item xs={6} >
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3}>
                <Typography style={{fontSize:'12px', color:'rgb(215, 77, 77)', fontFamily:'Lato, sans-serif'}}>{item.quantity === stocks ? `Total ${stocks} of these available` : '' }</Typography>
                </Grid>
            </Grid>
            
          </Grid>
        </Grid>
      );
    })
  }

  renderCartPlaceDetails = (isCartEmpty: boolean) => {
    const { total_fees, discount, total_tax, final_price } =
      this.state.priceDetails ;
    const { classes }  = this.props;
    return <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={3}
      className={classes.right}
    >
      {!isCartEmpty && (
        <>
          <Divider
            orientation="vertical"
            className={classes.vertical}
          />
          <Box className={classes.rightSide}>
            <Grid container className={classes.priceContainer}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={`${classes.text} ${classes.bottomContainer}`}
              >

                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} >
                    <Typography className={classes.detail}>
                      Total price:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
                    <Typography className={classes.detailOne} style={{ textAlign: 'end' }}>
                      AED {total_fees}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={`${classes.text} ${classes.bottomContainer}`}
              >
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography className={classes.detail}>
                      Discount:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
                    {" "}
                    <Typography className={classes.detailOne} style={{ textAlign: 'end' }}>
                      AED {discount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={`${classes.text} ${classes.bottomContainer}`}
              >
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography className={classes.detail}>
                      Tax:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
                    <Typography className={classes.detailOne} style={{ textAlign: 'end' }}>
                      AED {total_tax || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={classes.horizontal} />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={`${classes.text} ${classes.bottomContainer}`}
              >
                <Grid container>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Typography
                      className={classes.detail}
                      style={{ marginBottom: "10px" }}
                    >
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm={9} md={9} lg={9} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
                    <Typography
                      className={classes.detailHeader}
                      style={{ marginBottom: "10px", textAlign: 'end' }}
                    >
                      AED {final_price}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <OrangeButton
              data-test-id="continue"
              onClick={this.handleContinue}
              className={classes.nextBtn}
              disabled={this.state.disabled}
            >
              PLACE ORDER
            </OrangeButton>
            {this.state.productData.length > 0 && this.state.disabled && <Typography className={classes.brandName}
              style={{
                color: 'red',
                textAlign: 'center',
                marginTop: '4px',
                fontSize: "14px",
                fontWeight: 500
              }}>
              {configJSON.removeOutOfStocks}
            </Typography>}
            <Box
              data-test-id="continueBtn"
              className={classes.continueBtnWrapper}
              onClick={this.handleContinueShoppingBtn}
            >
              <Button className={classes.continueBtn}>
                CONTINUE SHOPPING
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Grid>
  }

  renderCartEmptyConditions = () => {
    const { classes } = this.props;
    const productDataExists = this.state.productData?.length === 0 && this.state.cartData?.length === 0
    const isCartEmpty = productDataExists || this.state.currentOrder === null
    return isCartEmpty ? (
      <Grid
        container
        className={classes.cartEmpty}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: "center" }}
        >
          <Box>
            <img src={cross} alt="addIcon" />
          </Box>
          <Typography data-test-id="cartEmpty" className={classes.heading}>
            {configJSON.cartEmptyText}
          </Typography>
          <Typography className={classes.headingTwo}>
            {configJSON.looksNothingAddedText}
          </Typography>
          <Typography className={classes.headingTwo}>
            {configJSON.goAheadShopText}
          </Typography>
          {!this.state.token &&
          <Box className={classes.headingTwo} style={{display:'inline-block'}}>
          <CustomOrangeButton data-test-id="signIn" onClick={this.handleSignIn}>Sign in to your account</CustomOrangeButton></Box>
            }
        </Grid>
      </Grid>
    )
      :
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.cartText}>
                {configJSON.myCartText}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.cartBoxDes}>
                {""}
              </Typography>
            </Grid>
          </Grid>


          <Grid
            container
            spacing={0}
            className={classes.productWrapper}
          >
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography className={classes.tableHeaderText}>
                {configJSON.productLabel}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Typography className={classes.tableHeaderText}>
                {configJSON.quantityLabel}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Typography className={classes.tableHeaderText}>
                {configJSON.priceLabel}
              </Typography>
            </Grid>
          </Grid>

          <CustomScrollbar
            className={classes.scrollBar}
          >

            {this.renderProductData()}

            {this.renderCartDataProduct()}
          </CustomScrollbar>
        </Grid>

        {this.renderCartPlaceDetails(isCartEmpty)}
      </Grid>

  }

  browsingHistorySlider = () => {
    const {recentProducts} = this.state
    const {classes} = this.props;
    return (
      <CustomProductSlider {...this.settings}>
              {recentProducts.map((item: RecentProduct, index: number) => {
                const calPrice  = calculateDiscountPercentage((item && item.mrp) as number, (item && item.retail_price) as number)
                return <div key={index} data-test-id="recentCard" className={classes.cardContainer} style={{cursor: "pointer"}} onClick={() => this.handleRedirectProduct(`${item.id}`)}>
                  <div className={classes.trendingProductsSlider}>
                    <img
                      src={item.product_image}
                      alt={`${item.id}`}
                      className={classes.dualSliderImg}
                    />
                    <OrangeButton className={classes.newBtn}>NEW</OrangeButton>
                  </div>
                  <Box className={classes.cardDesc}>
                    <TruncatedTextWithTooltipWeb text={item.product_title} maxChar={50} classNames={classes.cardProdName} />
                    <Typography className={classes.cardProdPrice}>
                      EAD <span className={classes.cardPrice}>{item.retail_price}</span>
                    </Typography>
                    {calPrice > 0 ? <Box style={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.priceValue}>
                        {calPrice}
                      </Typography>
                      <Typography className={classes.discount}>
                      &nbsp; {calculateDiscountPercentage(item?.mrp as number,item?.retail_price as number)}% OFF
                      </Typography>
                    </Box>: <Box style={{ display: "flex", alignItems: "center", lineHeight:1.5 }}>&nbsp;</Box>}
                    <Box className={classes.imgBox}>
                      <img className={classes.express} src={ExpressLogo} alt="express_img" />
                      <Box style={{ display: "flex" }}>
                        <Typography className={classes.ratingBox}>
                          {item.average_rating}{" "}
                          <span className={classes.star}>
                            <img src={starIcon} alt="start" />
                          </span>
                        </Typography>
                        <Typography className={classes.orangeText}>
                          {item.total_reviews}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              })} 
            </CustomProductSlider> 
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { recentProducts,loading } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <ScrollView>
        <HeaderWeb navigation={this.props.navigation} id={this.props.id} cartId={this.state.cartId} itemCount={this.state.itemCount} setOrderId={this.setOrderId}/>
        <Container
          className={classes.container}
        >
          <Box className={classes.background}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classes.wrap}>
              <Box className={classes.linkHeader}>
                <Typography className={classes.path} onClick={this.handleContinueShoppingBtn}>
                  {configJSON.home}
                  </Typography>
                  </Box> 
                  <Typography className={classes.span}>
                  / {configJSON.myCartText}
                  </Typography>
              </Grid>
            </Grid>
            <Box className={classes.cartBox}>
              {this.state.loading ? 
              <Loader loading={this.state.loading}/>
              :
             <>{this.renderCartEmptyConditions()}</>
              }
            </Box>

            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.trendingProd}>
                  {configJSON.yourBrowsingHistoryText}
                </Typography>
              </Grid>
            </Grid>
            {recentProducts.length > 0 ? this.browsingHistorySlider() : <Typography className={`${classes.trendingProd} ${classes.noBrowsingText}`}>
            {configJSON.noBrowsingText}
          </Typography>}
          </Box>
        </Container>
        <Footer navigation={this.props.navigation} id={this.props.id} categories={[]} token={""} />
        </ScrollView>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
  path: {
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
    cursor: 'pointer'
  },
  background: {
    backgroundColor: "#e5e5e5",
    padding: "10px 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px"
    }
  },
  span: {
    color: "#FF6008",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
    lineHeight: '16.8px'
  },
  cartText: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "32px",
    fontWeight: 700 as const,
    color: "#030303",
    [theme.breakpoints.down("md")]: {
      fontSize: "28px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px"
    }
  },
  cartBox: {
    backgroundColor: "#fff",
    padding: "20px",
    margin: "20px 20px",
    borderRadius: "6px",
  },
  cartBoxDes: {
    paddingTop: "10px",
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
  },
  productWrapper: {
    margin: "30px 0px 10px",
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  },
  productDetailsWrapper: {
    margin: "0px 0px 20px",
    minWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      display: 'flex',
      alignItems:'center',
      justifyContent:'center',
      margin:'30px 10px'
    }
  },
  tableHeaderText: {
    color: "#969696",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "11px",
    fontWeight: 400 as const,
    textTransform: "uppercase" as const,
  },
  flex: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    boxSizing: "content-box" as const,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      marginBottom: '12px'
    }
  },
  productName: {
    color: "#232529",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "15px",
    fontWeight: 600 as const,
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden' as const,
    wordBreak: "break-all" as const,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    },
  },
  color: {
    color: "#969696",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "13px",
    fontWeight: 400 as const,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  brandName: {
    color: "#33333a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "13px",
    fontWeight: 600 as const,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  quantity: {
    width: "80px",
    height: '38px',
    "& .MuiOutlinedInput-input": {
      padding: "12px 12px",
      fontSize:'15px',
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
    color:'#545454',
    },
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      fontSize:'13px'
    }
  },
  quantityField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      marginBottom: '12px'
    },
  },
  priceWrapper: {
    display: "flex",
    flexDirection: "column" as const,
  },
  priceTextOne: {
    color: "#1c1c1c",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "15px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    }
  },
  removeBtn: {
    marginLeft: "10px",
    borderRadius: "5px",
    border: "1px solid #e4e4e4",
    background: "#ffffff",
    padding: "6px 18px",
    textTransform: "capitalize" as const,
    color: "#1c1c1c",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: "15px",
    fontWeight: 700 as const,
    lineHeight: "22px",
  },
  rightSide: {
    display: "flex",
    flexDirection: "column" as const,
    padding: "55px 25px 30px 60px",
    [theme.breakpoints.down("md")]: {
      width: "30%",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "flex-end",
      padding: "40px 20px",
    },
  },
  coupon: {
    width: "100%",
    ".MuiInput" : {
      borderRadius:'6px !important',
    },
    "&::placeholder": {
      fontSize: "15px",
      fontFamily: `"Lato", sans-serif`,
    },
  },
  apply: {
    backgroundColor: "#232529",
    color: "white",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    padding: "8px 10px",
    textTransform: "capitalize" as const,
    fontFamily: `"Lato", sans-serif`,
    margin: "-5px 0",
    ".MuiButton-root:hover": {
      color: "#232529",
      backgroundColor: "#fff",
      border: '1px solid #232529'
    },
    "&:hover" : {
      color: "#232529",
      backgroundColor: "#efefef",
    }
  },
  text: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column" as const,
    justifyContent: "space-between",
  },
  detail: {
    color: "#545454",
    fontSize: "13px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
  },
  nextBtn: {
    paddingTop: "10px",
    paddingBottom: "10px",
    marginTop: "20px",
    '&.Mui-disabled': {
      color: "white",
      backgroundColor: "grey !important",
    },
    fontSize: "12px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 700 as const,
  },
  continueBtn: {
    width: "100%",
    color: "#33333a",
    fontSize: "12px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
    borderRadius: "5px",
    border: "1px solid #33333a",
    letterSpacing: "2px",
    padding: "10px",
    ".MuiButton-root:hover": {
      backgroundColor: "#33333a !important",
      color: "#fff !important",
    },
  },
  continueBtnWrapper: {
    display: "flex",
    alignItems: "center" as const,
    marginTop: "15px",
    justifyContent: "center",
    cursor: "pointer",
  },
  bottomWrapper: {
    backgroundColor: "#fff",
    padding: "20px",
    margin: "20px 20px",
    borderRadius: "6px",
  },
  trendingProd: {
    color: "#33333a",
    fontFamily: `"Lato-Heavy", sans-serif`,
    fontSize: "32px",
    fontWeight: 400 as const,
    margin: "20px 0 30px 20px",
    '@media (max-width: 600px)': {
      fontSize: "22px",
      textAlign: 'center' as const,
      margin: '0 auto'
    }
  },
  cardContainer: {
    width: "100%",
    border: "1px solid rgb(229, 229, 229)",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
    '&:hover': {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
  },
  dualSliderImg: {
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    objectFit:'cover' as const ,
  },
  trendingProductsSlider: {
    position: "relative" as const,
    height: "280px",
    padding: "0px",
    borderRadius: "10px",
  },
  newBtn: {
    width: "20%",
    textAlign: "center" as const,
    marginLeft: "78%",
    background: "#fb620f",
    color: "white",
    borderRadius: "3px",
    fontSize: "9px",
    padding: "1.5px",
    position: "absolute" as const ,
    top: "5px",
  },
  cardDesc: {
    margin: "16px 0",
    textAlign: "left" as const,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "left",
  },
  cardProdName: {
    color: "#33333a",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "16px",
    fontWeight: 600 as const,
    cursor:'pointer !important'
  },
  cardProdPrice: {
    margin: "10px 0px 2px",
    color: "#ff6008",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
  },
  cardPrice: {
    fontSize: "22px",
    fontWeight: 600 as const,
  },
  priceValue: {
    color: "#33333a",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
    textDecoration: "line-through" as const,
  },
  discount: {
    fontWeight: 700 as const,
    textDecoration: "none" as const,
    marginLeft: "10px",
    fontFamily: `"Lato",sans-serif`
  },
  ratingBox: {
    padding: "2px 6px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#565656",
    color: "#ffffff",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
  },
  star: {
    marginLeft: "5px",
  },
  imgBox: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  orangeText: {
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    color: "#ff6008",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
  },
  vertical: {
    position: "absolute" as const,
    marginLeft: "30px",
    marginTop: "50px",
    height: "80%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  priceContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  bottomContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  horizontal: {
    margin: "10px 0px",
    width: "100%",
    border: "0px solid #e4e4e4",
  },
  heading: {
    color: "#030303",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "28px",
    fontWeight: 700 as const,
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    }
  },
  headingTwo: {
    paddingTop: "10px",
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    }
  },
  arrowUp: {
    cursor: "pointer",
    marginBottom: "5px",
  },
  arrowDown: {
    cursor: "pointer",
  },
  arrowContainer: {
    height: "20px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
  },
  right: {
    position: "relative" as const,
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-end",
      display: "flex",
      margin: "40px 0",
    },
  },
  detailHeader: finalPriceHeader,
  detailOne: {
    marginBottom: "10px",
    color: "#545454",
    fontSize: "13px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
  },
  cartEmpty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  container: { 
    maxWidth: "1700px", 
    padding: "0", 
    margin: "0 auto" 
  },
  linkHeader: {
    textDecoration:'none',
    display:'flex', 
    alignItems:'center',
  },
  wrap:{
    display:'flex', 
    alignItems:'center',
    gap:'3px',
    marginLeft:'20px'
  },
  image: {
    marginRight: "20px" ,
    maxWidth:'64px',
    minWidth:'64px',
    height:'67px',
    borderRadius: '5px',
    border: '1px solid #979797',
    padding: '6px',
    [theme.breakpoints.down("xs")]: {
      width:'64px',
    height:'54px',
    }
  },
  scrollBar: {
    width: "100%",
    height: "280px",
    overflowY: "auto" as const,
    overflowX: "auto" as const,
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      overflow: 'hidden',
      height: 'auto'
    }
  },
  couponText: {
    color: "#545454",
    fontSize: "15px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400 as const,
    marginBottom: '10px'
  },
  productInfoContainer: {
    maxWidth:'70%',
    [theme.breakpoints.down("xs")]: {
      width:'100%',
      marginRight:'20px'
    }
  },
  express: {
    width:'72px',
    height:'26px'
  },
  noBrowsingText : { 
    display: "flex",
    alignItems: "center",
    fontSize: "calc(1em + 0.5vw)",
    justifyContent: "center",
    textAlign: "center" as const,
    height: "320px",
    '@media (max-width: 600px)': {
      margin:'0 auto',
      fontSize: '16px'
    }
  }

});

export { OrderSummary };
export default withStyles(styles)(OrderSummary);
// Customizable Area End
